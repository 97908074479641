<template>
  <div>
    <Title :pages="[{ icon: 'money-check', page: page_title.toUpperCase() }]"></Title>

    <v-container fluid>
      <ModalCharged
        :formBtn="formBtn"
        :dialog="dialog"
        :parent="parent"
        :charge="mainItem"
        @closeDialog="updateDialog"
        @loadList="loadList(service)"
        @updateItem="
          (newItem) => {
            mainItem = newItem;
          }
        "
      />

      <ModalAssignVoucher
        :formBtn="formBtn"
        :dialog="dialog_assign_voucher"
        :charge="mainItem"
        @closeDialog="closeDialogAssignVoucher"
        @loadList="loadList(service)"
        @updateItem="
          (newItem) => {
            mainItem = newItem;
          }
        "
      />

      <v-btn color="primary" class="mb-5" dark @click="dialog = true">Crear Cobro</v-btn>

      <v-card>
        <div class="row mb-5 mt-0">
          <div class="col-md-12" v-if="list_access">
            <div class="col-md-12" style="background:white; elevation-0">
              <div class="row pt-5">
                <div class="col-md-3 pt-0">
                  <v-menu
                    ref="desde_selected"
                    v-model="desde_selected_open"
                    :close-on-content-click="false"
                    :close-on-click="false"
                    :return-value.sync="desde_selected"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                    clearable
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="desde_selected" label="Desde" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" class="pt-0 mt-0"></v-text-field>
                    </template>
                    <v-date-picker v-model="desde_selected" type="date" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="desde_selected_open = false"> Cancel </v-btn>
                      <v-btn text color="primary" @click="$refs.desde_selected.save(desde_selected)"> OK </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>

                <div class="col-md-3 pt-0">
                  <v-menu
                    ref="hasta_selected"
                    v-model="hasta_selected_open"
                    :close-on-content-click="false"
                    :close-on-click="false"
                    :return-value.sync="hasta_selected"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                    clearable
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="hasta_selected" label="Hasta" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" class="pt-0 mt-0"></v-text-field>
                    </template>
                    <v-date-picker v-model="hasta_selected" type="date" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="hasta_selected = false"> Cancel </v-btn>
                      <v-btn text color="primary" @click="$refs.hasta_selected.save(hasta_selected)"> OK </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>

                <!--<div class="col-md-3 pt-0">
                  <v-combobox
                    v-model="monetary_account_selected"
                    autocomplete="off"
                    data-vv-as="monetary_account_selected"
                    name="monetary_account_selected"
                    item-text="name"
                    item-value="id"
                    :items="money_accounts"
                    label="Cuenta Monetaria"
                    class="pt-0 mt-0"
                    clearable
                  ></v-combobox>
                </div>-->

                <div class="col-md-3 pt-0">
                  <v-combobox
                    v-model="client_selected"
                    autocomplete="off"
                    data-vv-as="client_selected"
                    name="client_selected"
                    item-text="name"
                    item-value="id"
                    :items="clients"
                    label="Cliente"
                    class="pt-0 mt-0"
                    clearable
                  ></v-combobox>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3">
                  <v-text-field clearable v-model="charge_number_selected" autocomplete="off" class="pt-0 mt-0" label="Nº Cobro"></v-text-field>
                </div>
                <div class="col-md-3">
                  <v-text-field clearable v-model="voucher_number_selected" autocomplete="off" class="pt-0 mt-0" label="Nº Comprobante"></v-text-field>
                </div>
                <div class="col-md-3">
                  <v-checkbox dense label="Cobranzas a cuenta" v-model="cobranzas_a_cuenta_selected"></v-checkbox>
                </div>

                <div class="col-md-3" style="display: flex; justify-content: end">
                  <v-btn color="blue-grey" class="mr-2" dark :loading="dialog_export" @click="exportFile()"> Exportar <font-awesome-icon class="ml-2" icon="download" /></v-btn>

                  <v-btn color="info" class="" @click="filter">Buscar</v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>

      <v-card v-if="list_access">
        <v-card-title>
          <span class="text-uppercase">{{ page_title }}</span>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="search" label="Buscar" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="list"
          :search="search"
          :loading="loading"
          loading-text="Cargando..."
          :options="{
            descending: true,
            itemsPerPage: 15,
          }"
          @pagination="updatePagination"
          :footer-props="{
            itemsPerPageOptions: [5, 10, 15, 50, -1],
          }"
        >
          <template v-slot:item="props">
            <tr>
              <td>{{ props.item.nro_recibo }}</td>
              <td>{{ props.item.cbte_fch }}</td>
              <td>{{ props.item.client.name }}</td>

              <td class="text-right">{{ formatUtils.formatPrice(props.item.total_ars) }}</td>
              <td class="text-right">{{ formatUtils.formatPrice(props.item.total_usd) }}</td>
              <td class="text-right px-0">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="blue" class="mr-2" @click="viewItem(props.item)"> remove_red_eye </v-icon>
                  </template>
                  <span>Ver</span>
                </v-tooltip>

                <v-tooltip bottom v-if="props.item.cobranza_a_cuenta > 0">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="blue" class="mr-2" @click="assignVoucher(props.item)"> edit </v-icon>
                  </template>
                  <span>Asignar factura</span>
                </v-tooltip>

                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon small color="purple" class="mr-2" v-bind="attrs" v-on="on"> print </v-icon>
                  </template>

                  <v-list>
                    <v-list-item dense @click="printItem(props.item)">
                      <v-list-item-title>Descargar comprobante</v-list-item-title>
                    </v-list-item>

                    <v-list-item dense @click="printItem(props.item, true)">
                      <v-list-item-title>Ver comprobante</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-if="delete_access" v-on="on" small color="red" class="mr-2" @click="deleteItem(props.item)"> delete </v-icon>
                  </template>
                  <span>Eliminar</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              {{ "Busqueda sin resultados - " + search }}
            </v-alert>
          </template>
        </v-data-table>
      </v-card>

      <DialogDelete :dialog_delete="dialog_delete" @updateDialogDelete="updateDialogDelete" @deleteItemConfirm="deleteItemConfirm" />
    </v-container>
  </div>
</template>

<script>
import Constant from "@/constants/sections";
import { generalService } from "@/libs/ws/generalService";
import { chargeService } from "@/libs/ws/chargeService";
import { formatUtils } from "@/libs/utils/formatUtils";
import GenericMixin from "@/mixins/GenericMixin.vue";
import Title from "@/components/Title.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import DialogDelete from "@/components/DialogDelete.vue";
import ModalCharged from "@/views/Charges/ModalCharged.vue";
import ModalAssignVoucher from "@/views/Charges/ModalAssignVoucher.vue";
import ExportMixin from "@/mixins/ExportMixin.vue";
export default {
  components: { Title, DialogDelete, ModalCharged, ModalAssignVoucher },
  mixins: [GenericMixin, RolMixin, ExportMixin],
  data() {
    return {
      formatUtils: formatUtils,
      parent: this,
      service: chargeService,
      page_title: "Cobros",
      modal_title: "Cobro",
      mainItem: {
        id: 0,
        client: null,
        voucher: null,
        dolar_cotiz: null,
        amount: 0,
        tributes: [],
        charges_payment_methods: [],
        vouchers: [],
        cbte_fch: new Date(
          new Date().toLocaleDateString("en-US", {
            timeZone: "America/Buenos_Aires",
          })
        )
          .toISOString()
          .slice(0, 10),
      },
      dialog_delete: false,
      dialog_open_afip: false,
      dialog_cbtes_asoc: false,
      spent_selected: null,
      loading_dialog: false,
      title_dialog: "",
      message_dialog: "",
      item_dialog: null,
      defaultItem: null,
      headers: [
        { text: "Numero", align: "left", sortable: true, value: "nro_recibo" },
        { text: "Fecha", align: "left", sortable: true, value: "creation_date" },
        { text: "Cliente", align: "left", sortable: true, value: "client.name" },

        { text: "Total ARS", align: "right", sortable: true, value: "voucher.imp_total" },
        { text: "Total USD", align: "right", sortable: true },
        { text: "Accion", value: "", align: "center" },
      ],
      list: [],
      clients: [],
      money_accounts: [],
      desde_selected_open: false,
      hasta_selected_open: false,
      client_selected: null,
      cobranzas_a_cuenta_selected: false,
      monetary_account_selected: null,
      charge_number_selected: null,
      voucher_number_selected: null,
      desde_selected: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().slice(0, 10),
      hasta_selected: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().slice(0, 10),
      dialog_assign_voucher: false,
    };
  },
  mounted() {
    this.loadComboData();
    this.defaultItem = JSON.parse(JSON.stringify(this.mainItem));
    this.loadList(this.service, this.getFilterParams());
  },
  methods: {
    assignVoucher(item) {
      this.mainItem = JSON.parse(JSON.stringify(item));
      this.dialog_assign_voucher = true;
    },
    closeDialogAssignVoucher() {
      this.mainItem = JSON.parse(JSON.stringify(this.defaultItem));
      this.dialog_assign_voucher = false;
    },
    closeDialog() {
      this.item_dialog = null;
      this.title_dialog = "";
      this.message_dialog = "";
      this.loading_dialog = false;
      this.dialog_open_afip = false;
    },
    getFilterParams() {
      const param = {
        from: this.desde_selected,
        to: this.hasta_selected,
        client: this.client_selected != null ? this.client_selected.id : null,
        monetary_account: this.monetary_account_selected != null ? this.monetary_account_selected.id : null,
        cobranzas_a_cuenta: this.cobranzas_a_cuenta_selected,
        voucher_number: this.voucher_number_selected,
        charge_number: this.charge_number_selected,
      };
      return param;
    },
    filter() {
      this.loadList(this.service, this.getFilterParams());
    },
    printItem(item, open = false) {
      this.loading = true;
      this.service
        .print(item.id)
        .then((response) => {
          if (!generalService.downloadPdf(response, "recibo_" + item.nro_recibo + ".pdf", open)) {
            this.$toast.error(response.msg, "Error");
          }
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);
          this.$toast.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadComboData() {
      generalService.setSection(Constant.SECTION_CLIENTS);
      generalService
        .filter()
        .then((response) => {
          this.clients = response.result;
        })
        .catch((e) => {
          console.log("suppliers.filter error");
          console.log(e);
        });

      generalService.setSection(Constant.SECTION_MONEY_ACCOUNTS);
      generalService
        .filter()
        .then((response) => {
          this.money_accounts = response.result;
        })
        .catch((e) => {
          console.log("money_accounts.filter error");
          console.log(e);
        });
    },
    deleteItemConfirm() {
      this.loading = true;
      chargeService
        .remove({ id: this.deleteId })
        .then((response) => {
          if (response.status === true) {
            this.$toast.success(response.msg, "OK");
            this.loadList(this.service, this.getFilterParams());
          } else {
            if (Object.prototype.hasOwnProperty.call(response, "message")) {
              this.$toast.error(response.message, "Error");
            } else {
              this.$toast.error(response.msg, "Error");
            }
          }
        })
        .catch((e) => {
          console.log("spentService.filter error");
          console.log(e);
          this.$toast.error("Error exception", "Error");
        })
        .finally(() => {
          this.loading = false;
        });
      this.dialog_delete = false;
    },
    cancelModal() {
      this.dialog = false;
    },
    exportFile() {
      var param = this.getFilterParams();
      param.export = true;
      this.exportExcel(Constant.SECTION_CHARGES + "/list", param, "Cobros.xlsx");
    },
  },
};
</script>
