<template>
  <div>
    <Title
      :pages="[
        { icon: 'rectangle-list', page: page_title.toUpperCase() },
        { icon: 'circle', page: getName() },
      ]"
    ></Title>

    <v-container fluid>
      <v-btn color="primary" v-if="update_access" class="mb-5" @click="dialog_bulk_update = true">Aumento masivo de precios</v-btn>

      <v-card v-if="list_access">
        <v-card-title>
          <span class="text-uppercase">{{ getName() }}</span>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="search" label="Buscar" single-line hide-details></v-text-field>
        </v-card-title>

        <v-data-table
          :headers="headers"
          :items="list"
          :search="search"
          :loading="loading"
          loading-text="Cargando..."
          :options="{
            descending: true,
            itemsPerPage: 15,
          }"
          @pagination="updatePagination"
          :footer-props="{
            itemsPerPageOptions: [5, 10, 15, 50, -1],
          }"
        >
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.label }}</td>
              <td>{{ item.category.name }}</td>

              <td class="text-right">{{ formatUtils.formatPrice(item.price) }}</td>
              <td class="text-right">{{ (item.discount_per != null)?item.discount_per+"%":"-" }}</td>
              <td class="text-center px-0">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-if="update_access" v-on="on" small color="blue" class="mr-2" @click="editItem(item)"> edit </v-icon>
                  </template>
                  <span>Editar</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              {{ "Busqueda sin resultados - " + search }}
            </v-alert>
          </template>
        </v-data-table>
      </v-card>

      <ModalBulkUpdate :show="dialog_bulk_update" @close="dialog_bulk_update = false" @refresh="loadList(service)" :seleted="lista_de_precios" />

      <ModalProductPrice
        v-if="update_access"
        :modal_title="modal_title"
        :formBtn="formBtn"
        :dialog="dialog"
        :service="service"
        @updateDialog="updateDialog"
        @loadList="loadList(service)"
        :item="mainItem"
        @updateItem="
          (newItem) => {
            mainItem = newItem;
          }
        "
      />
    </v-container>
  </div>
</template>

<script>
import Constant from "@/constants/sections";
import { priceListService } from "@/libs/ws/priceListService";
import { formatUtils } from "@/libs/utils/formatUtils";
import GenericMixin from "@/mixins/GenericMixin.vue";
import ModalBulkUpdate from "./ModalBulkUpdate.vue";
import Title from "@/components/Title.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import ModalProductPrice from "./ModalProductPrice.vue";

export default {
  components: { Title, ModalBulkUpdate, ModalProductPrice },
  mixins: [GenericMixin, RolMixin],
  data() {
    return {
      formatUtils: formatUtils,
      service: priceListService,
      page_title: "Listas de precio",
      modal_title: "Lista de precio",
      lista_de_precios: [],
      mainItem: {
        id: "",
        name: "",
        product_presentation_id: "",
        price_list_id: "",
        price: 0,
        enabled: true,
      },
      defaultItem: null,
      headers: [
        {
          text: "Nombre",
          align: "left",
          sortable: true,
          value: "label",
        },
        {
          text: "Rubro",
          align: "left",
          sortable: true,
          value: "category.name",
        },
        {
          text: "Precio unitario s/IVA",
          align: "right",
          sortable: true,
          value: "price",
        },
        {
          text: "% Bonificacion",
          align: "right",
          sortable: true,
          value: "discount_per",
        },
        { text: "Accion", value: "", align: "center" },
      ],
      list: [],
      dialog_bulk_update: false,
      price_list_name: "",
    };
  },
  mounted() {
    this.defaultItem = JSON.parse(JSON.stringify(this.mainItem));
    this.loadList(this.service);
  },
  methods: {
    getName() {
      return this.price_list_name;
    },
    setVariables() {
      if (this.list.length > 0) {
        this.price_list_name = this.list[0].price_list_name;
      }
      var item_arr = [
        {
          id: this.$route.params.price_list_id,
          name: this.price_list_name,
        },
      ];

      this.lista_de_precios = Object.assign([], this.lista_de_precios, item_arr);
    },
    loadList(service, param = null) {
      this.loading = true;

      if (param == null) {
        param = {
          prices_list_id: this.$route.params.price_list_id,
        };
      }

      this.open_filter = false;
      service
        .getPriceList(param)
        .then((response) => {
          this.list = response.result;

          this.setVariables();
          this.loading = false;

          if (typeof this.selected !== "undefined") {
            this.selected = [];
          }
        })
        .catch((e) => {
          console.log("service.filter error");
          console.log(e);
          this.loading = false;
        });
    },
  },
};

// TODO agregar un boton de limpiar busqueda.
</script>
