<template>
  <v-card elevation="1" class="mb-4">
    <v-card-text>
      <h6 class="text-h6 black--text">Retenciones</h6>
      <p class="text-body-2 mb-0">Ingrese las retenciones sufridas</p>
      <v-row class="mt-0">
        <v-col md="12" v-if="!isViewModal">
          <v-btn small color="blue-grey" class="text-white" @click="addTributeDetail()">
            <v-icon big color="green"> add </v-icon>
            <span>Agregar item</span>
          </v-btn>
        </v-col>
        <v-col md="12">
          <v-data-table class="pb-4" :headers="header_tribute" :items="item.tributes" style="border: solid 1px #1c1c1c" :hide-default-footer="true" :items-per-page="100">
            <template v-slot:item="props">
              <tr>
                <td>
                  <v-combobox
                    v-model="props.item.tribute"
                    :disabled="isViewModal"
                    data-vv-as="tribute"
                    name="tribute"
                    item-text="Desc"
                    item-value="Id"
                    :items="tributes"
                    label="Tipo*"
                    autocomplete="off"
                    :loading="loading"
                    v-validate="'required'"
                  ></v-combobox>
                  <span class="red--text">{{ errors.first("tribute") }}</span>
                </td>
                <td style="min-width: 35%">
                  <v-text-field autocomplete="off" :disabled="isViewModal" name="description" placeholder="Descripción" dense type="text" v-model="props.item.description" class="mt-3" />
                  <span class="red--text">{{ errors.first("description") }}</span>
                </td>

                <td>
                  <v-text-field autocomplete="off" :disabled="isViewModal" name="nro_cert" placeholder="Nº Certificado*" dense v-model="props.item.nro_cert" class="mt-3" />
                  <span class="red--text">{{ errors.first("nro_cert") }}</span>
                </td>
                <td>
                  <v-text-field autocomplete="off" :disabled="isViewModal" name="amount" placeholder="Importe*" dense v-validate="'decimal'" type="number" v-model="props.item.amount" class="mt-3" />
                  <span class="red--text">{{ errors.first("amount") }}</span>
                </td>
                <td class="text-center">
                  <v-tooltip bottom v-if="!isViewModal">
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" small color="red" @click="removeDetail(props.item, 'tribute')"> indeterminate_check_box </v-icon>
                    </template>
                    <span>Remover</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
        <v-col md="12">
          <v-chip class="float-right" outlined color="primary">Total retenciones: {{ formatUtils.formatPrice(total_retenciones) }} </v-chip>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { emmiterService } from "@/libs/ws/emmiterService";
import { formatUtils } from "@/libs/utils/formatUtils";
import retentions from "@/constants/retentions";

export default {
  created() {
    this.$validator = this.validator;
  },
  components: {},
  props: { item: Object, isViewModal: Boolean, validator: Object },
  data: () => ({
    header_tribute: [
      { text: "Tributo", value: "product", sortable: false },
      { text: "Descripción", value: "description", sortable: false },
      { text: "Nº Certificado", value: "nro_cert", sortable: false, class: "bg-grey" },
      { text: "Importe", value: "amount", sortable: false },
      { text: "", value: "", sortable: false, class: "bg-grey" },
    ],
    tributes: [],
    loading: false,
    voucher_seleted: null,
    total_retenciones: 0,
    formatUtils: formatUtils,
  }),
  watch: {
    item: {
      handler: function (newValue) {
        this.getTotal();
      },
      deep: true,
    },
  },
  mounted() {
    this.getTributos();
  },
  methods: {
    getTotal() {
      let total = 0;

      this.item.tributes.forEach((tribute) => {
        const amount = tribute.amount != undefined ? tribute.amount : 0;
        total += parseFloat(amount);
      });

      this.total_retenciones = total;
    },
    addTributeDetail() {
      this.item.tributes.push({
        description: "",
        nro_cert: null,
        amount: null,
        tribute: null,
      });
    },
    removeDetail(item, colection_name) {
      if (colection_name == "tribute") {
        const index_item = this.item.tributes.indexOf(item);
        if (index_item > -1) this.item.tributes.splice(index_item, 1);
      }
    },
    getTributos() {      
      this.tributes = retentions.retentions;
      /*
      this.loading = true;
      emmiterService
        .tributos({})
        .then((response) => {
          if (response.status) {
            this.tributes = response.result;
            this.loading = false;
          }
        })
        .catch((e) => {
          console.log("payment_methods.filter error");
          console.log(e);
          this.loading = false;
        });
        */
    },
  },
};
</script>
