<template>
  <v-layout>
    <v-dialog v-bind:value="show_dialog" @click:outside="$emit('updateDialog', false)" max-width="1000px">
      <v-card style="background-color: #ecf0f5">
        <v-form v-on:submit.prevent="addItems">
          <v-card-title>
            <span class="headline">Comprobantes</span>
          </v-card-title>

          <v-card-text>
            <div class="row mb-2 mt-0">
              <div class="col-md-12">
                <div class="col-md-12" style="background:white; elevation-0">
                  <div class="row pt-5">
                    <div class="col pt-0">
                      <v-menu
                        ref="desde_selected"
                        v-model="desde_selected_open"
                        :close-on-content-click="false"
                        :close-on-click="false"
                        :return-value.sync="desde_selected"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                        clearable
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field dense v-model="desde_selected" label="Desde" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" class="pt-0 mt-0"></v-text-field>
                        </template>
                        <v-date-picker v-model="desde_selected" type="date" no-title scrollable>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="desde_selected_open = false"> Cancel </v-btn>
                          <v-btn text color="primary" @click="$refs.desde_selected.save(desde_selected)"> OK </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </div>

                    <div class="col pt-0">
                      <v-menu
                        ref="hasta_selected"
                        v-model="hasta_selected_open"
                        :close-on-content-click="false"
                        :close-on-click="false"
                        :return-value.sync="hasta_selected"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                        clearable
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field dense v-model="hasta_selected" label="Hasta" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" class="pt-0 mt-0"></v-text-field>
                        </template>
                        <v-date-picker v-model="hasta_selected" type="date" no-title scrollable>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="hasta_selected = false"> Cancel </v-btn>
                          <v-btn text color="primary" @click="$refs.hasta_selected.save(hasta_selected)"> OK </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </div>

                    <div class="col pt-0">
                      <v-text-field dense clearable v-model="voucher_number_selected" autocomplete="off" class="pt-0 mt-0" label="Nº Comprobante"></v-text-field>
                    </div>

                    <div class="col pt-0">
                      <v-combobox
                        v-model="cbte_tipo_selected"
                        autocomplete="off"
                        data-vv-as="cbte_tipo_selected"
                        name="cbte_tipo_selected"
                        item-text="Desc"
                        item-value="Id"
                        :items="cbte_tipos"
                        label="Tipo de Comprobante"
                        class="pt-0 mt-0"
                        clearable
                        dense
                      ></v-combobox>
                    </div>
                  </div>

                  <div class="row pt-5">
                    <div class="col-md-3 pt-0">
                      <v-combobox
                        v-model="state_selected"
                        autocomplete="off"
                        data-vv-as="state_selected"
                        name="state_selected"
                        item-text="name"
                        item-value="id"
                        :items="states"
                        label="Estado"
                        class="pt-0 mt-0"
                        clearable
                        dense
                      ></v-combobox>
                    </div>
                    <div class="col-md-3 pt-0">
                      <v-combobox
                        @change="getDeliveryPoints"
                        dense
                        v-model="client_selected"
                        autocomplete="off"
                        item-text="name"
                        item-value="id"
                        :items="clients"
                        label="Cliente"
                        class="pt-0 mt-0"
                        clearable
                      ></v-combobox>
                    </div>
                    <div class="col-md-3 pt-0">
                      <v-combobox
                        :loading="loading_delivery_points"
                        dense
                        v-model="delivery_point_selected"
                        autocomplete="off"
                        item-text="name"
                        item-value="id"
                        :items="delivery_points"
                        label="Punto de entrega"
                        class="pt-0 mt-0"
                        clearable
                      ></v-combobox>
                    </div>
                    <div class="col-md-3 pt-0 justify-content-end d-flex">
                      <v-btn color="info" class="" @click="filter">Buscar</v-btn>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <v-card>
              <v-card-title style="background: white">
                <v-spacer></v-spacer>
                <v-text-field dense v-model="search" append-icon="search" label="Buscar" single-line hide-details></v-text-field>
              </v-card-title>
              <v-data-table
                v-model="selected"
                :headers="headers"
                :items="list"
                :search="search"
                :loading="loading"
                loading-text="Cargando..."
                :options="{
                  descending: true,
                  itemsPerPage: 15,
                }"
                @pagination="updatePagination"
                :footer-props="{
                  itemsPerPageOptions: [5, 10, 15, 50, -1],
                }"
              >
                <template v-slot:item="{ item, isSelected, select }">
                  <tr>
                    <td>
                      <v-checkbox :input-value="isSelected" style="margin: 0px; padding: 0px" hide-details @change="select($event)" />
                    </td>
                    <td>{{ item.cbte_fch }}</td>
                    <td>{{ item.cbte_tipo_name }}</td>
                    <td>{{ item.voucher_number != null ? item.pto_vta.toString().padStart(4, "0") + "-" + item.voucher_number.toString().padStart(8, "0") : "-" }}</td>
                    <td>{{ item.emitter.name }}</td>
                    <td>{{ item.receiver.name }}</td>
                    <td>{{ item.client_shipping_address != null ? item.client_shipping_address.name : item.shipping_address }}</td>
                    <td class="text-right">{{ formatUtils.formatPrice(item.total_ars) }}</td>
                    <td>{{ item.state.name }}</td>
                  </tr>
                </template>
                <template v-slot:no-results>
                  <v-alert :value="true" color="error" icon="warning">
                    {{ "Busqueda sin resultados - " + search }}
                  </v-alert>
                </template>
              </v-data-table>
            </v-card>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('updateDialog', false)">Cerrar</v-btn>
            <v-btn color="success" text type="submit" :disabled="selected.length == 0">Agregar</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import ModalGenericMixin from "@/mixins/ModalGenericMixin.vue";
import GenericMixin from "@/mixins/GenericMixin.vue";
import Constant from "@/constants/sections";
import { generalService } from "@/libs/ws/generalService";
import { clientsService } from "@/libs/ws/clientsService";
import { vouchersService } from "@/libs/ws/vouchersService";
import { billingGeneralService } from "@/libs/ws/billingGeneralService";
import { formatUtils } from "@/libs/utils/formatUtils";
export default {
  mixins: [GenericMixin, ModalGenericMixin],
  props: {
    show_dialog: Boolean,
    carrier: Object,
    addSelected: Function,
  },
  data() {
    return {
      selected: [],
      clients: [],
      cbte_tipos: [],
      states: [],
      desde_selected: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().slice(0, 10),
      hasta_selected: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().slice(0, 10),
      voucher_number_selected: null,
      cbte_tipo_selected: null,
      state_selected: null,
      client_selected: null,
      desde_selected_open: false,
      hasta_selected_open: false,
      delivery_point_selected: null,
      loading_delivery_points: false,
      formatUtils: formatUtils,
      headers: [
        { text: "", align: "center", sortable: false, value: "" },
        { text: "Fecha", align: "left", sortable: true, value: "cbte_fch" },
        { text: "Comprobante", align: "left", sortable: true, value: "cbte_tipo_name" },
        { text: "Nº Comprobante", align: "left", sortable: true, value: "voucher_number" },
        { text: "Emisor", align: "left", sortable: true, value: "emitter.name" },
        { text: "Receptor", align: "left", sortable: true, value: "receiver.name" },
        { text: "Pto de Entrega", align: "left", sortable: true, value: "shipping_address" },
        { text: "Total ARS", align: "center", sortable: true, value: "imp_total" },
        { text: "Estado", align: "left", sortable: true, value: "state" },
      ],
      delivery_points: [],
    };
  },
  watch: {
    show_dialog() {
      this.selected = [];
      this.errors.clear();
      this.filter();
    },
  },
  mounted() {
    this.loadComboData();
  },
  methods: {
    getDeliveryPoints() {
      if (this.client_selected != null) {
        this.loading_delivery_points = true;
        generalService
          .query({}, Constant.SECTION_CLIENTS + "/shipping_addresess/" + this.client_selected.id)
          .then((response) => {
            this.delivery_points = response.result;
          })
          .catch((error) => {
            this.errors.set("client_selected", error);
          })
          .finally(() => {
            this.loading_delivery_points = false;
          });
      }
    },
    addItems() {
      this.$emit("addSelected", this.selected);
    },
    filter() {
      this.loading = true;
      let filter = {
        from: this.desde_selected,
        to: this.hasta_selected,
        client: this.client_selected != null ? this.client_selected.id : null,
        state: this.state_selected != null ? this.state_selected.id : null,
        voucher_number: this.voucher_number_selected,
        cbte_tipo: this.cbte_tipo_selected != null ? this.cbte_tipo_selected.Id : null,
        client_shipping_address: this.delivery_point_selected != null ? this.delivery_point_selected.id : null,
        shipping_is_null: true,
        shipping_carrier: this.carrier != null ? this.carrier.id : null,
      };
      vouchersService
        .filter(filter)
        .then((response) => {
          this.list = response.result;
        })
        .finally(() => {
          this.selected = [];
          this.loading = false;
        });
    },
    loadComboData() {
      clientsService.filter().then((response) => {
        this.clients = response.result;
      });

      billingGeneralService.cbteTiposInvoice(null, "all").then((response) => {
        if (response.status) {
          this.cbte_tipos = response.result;
        }
      });
      billingGeneralService.list_states().then((response) => {
        this.states = response.result;
      });
    },
  },
};
</script>
