<template>
  <v-card elevation="1" class="mb-4">
    <v-card-text>
      <h6 class="text-h6 black--text">Datos generales</h6>
      <p class="text-body-2">Ingrese los datos basicos del remito</p>
      <v-row class="mt-0">
        <v-col md="4" class="mt-2">
          <v-combobox
            :loading="loading_emitter"
            v-model="item.emitter"
            data-vv-as="emisor"
            name="emitter"
            item-text="name"
            item-value="id"
            :items="emitters"
            label="Emisor*"
            autocomplete="off"
            v-validate="'required'"
            :disabled="isViewModal || loading_num_remito || item.id > 0"
          ></v-combobox>
          <span class="red--text">{{ errors.first("emitter") }}</span>
        </v-col>

        <v-col md="4" class="mt-2">
          <v-combobox
            :loading="loading_receiver"
            v-model="item.receiver"
            data-vv-as="receptor"
            name="receiver"
            :item-text="receiver_name"
            item-value="id"
            :items="receivers"
            label="Receptor*"
            autocomplete="off"
            v-validate="'required'"
            :disabled="isViewModal"
          ></v-combobox>
          <span class="red--text">{{ errors.first("receiver") }}</span>
        </v-col>
        <v-col md="4" class="mt-2">
          <DatePickerEsp
            :initToday="true"
            label="Fecha"
            :date="item.cbte_fch"
            :isRequired="true"
            name="cbte_fch"
            @setDate="
              (date) => {
                item.cbte_fch = date;
              }
            "
            :isViewModal="isViewModal"
          />
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col md="4" class="mt-2">
          <v-combobox
            :disabled="isViewModal || item.emitter == null || loading_num_remito || item.id > 0"
            v-model="item.pto_vta"
            data-vv-as="punto de venta"
            name="pto_vta"
            :items="ptosvtas"
            label="Punto de venta*"
            autocomplete="off"
            v-validate="'required'"
            v-on:change="PtoVtaChangeCombo($event)"
          ></v-combobox>
          <span class="red--text">{{ errors.first("pto_vta") }}</span>
        </v-col>
        <v-col md="4" class="mt-2">
          <v-text-field
            autocomplete="off"
            data-vv-as="numero de remito"
            label="Numero remito*"
            name="num_remito"
            v-validate="'integer|required'"
            v-model="item.num_remito"
            :disabled="item.num_remito == null"
            :loading="loading_num_remito"
          >
          </v-text-field>
          <span class="red--text">{{ errors.first("num_remito") }}</span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import Constant from "@/constants/sections";
import { emmiterService } from "@/libs/ws/emmiterService";
import { receiversService } from "@/libs/ws/receiversService";
import { remitoService } from "@/libs/ws/remitoService";

import DatePickerEsp from "@/components/DatePickerEsp.vue";
export default {
  components: { DatePickerEsp },
  props: { item: Object, isViewModal: Boolean, validator: Object },
  created() {
    this.$validator = this.validator;
  },
  data: () => ({
    emitters: [],
    receivers: [],
    ptosvtas: [],
    loading_emitter: false,
    loading_receiver: false,
    loading_num_remito: false,
    pto_venta_selected: null,
    emitter_seleted: null,
  }),
  watch: {
    item: {
      handler: function (newValue) {
        if ((this.pto_venta_selected == null && newValue.pto_vta != null) || (newValue.pto_vta != null && this.pto_venta_selected.id != newValue.pto_vta.id)) {
          this.pto_venta_selected = newValue.pto_vta;
          this.getNextNumRemito();
        }

        if ((this.emitter_seleted == null && newValue.emitter != null) || (newValue.emitter != null && this.emitter_seleted.id != newValue.emitter.id)) {
          this.emitter_seleted = newValue.emitter;
          this.emitterChangeCombo();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getCombobox();
  },
  methods: {
    receiver_name: (item) => (item == null ? "" : item.name + " (" + item.client.name + ")"),
    getCombobox() {
      this.loading_emitter = true;
      emmiterService
        .filter({ enabled: true })
        .then((response) => {
          this.emitters = response.result;
          if (response.result.length == 1) {
            this.item.emitter = response.result[0];
            //this.emitterChangeCombo();
          }
        })
        .finally(() => {
          this.loading_emitter = false;
        });

      this.loading_receiver = true;
      receiversService
        .filter({})
        .then((response) => {
          this.receivers = response.result;
        })
        .finally(() => {
          this.loading_receiver = false;
        });
    },
    emitterChangeCombo(event) {
      emmiterService.ptosvtas_remitos(this.item.emitter.id).then((response) => {
        if (response.status) {
          this.ptosvtas = response.result;
          this.item.pto_vta = response.result[0];
          this.getNextNumRemito();
        }
      });
    },
    PtoVtaChangeCombo() {
      this.getNextNumRemito();
    },
    getNextNumRemito() {
      if (this.item.emitter != null && this.item.emitter.id > 0 && this.item.pto_vta > 0 && !this.isViewModal && this.item.id == 0) {
        if (this.loading_num_remito) return;

        this.loading_num_remito = true;
        remitoService
          .get_next_number(this.item.emitter.id, this.item.pto_vta)
          .then((response) => {
            if (response.status) {
              this.item.num_remito = response.result;
            }
          })
          .finally(() => {
            this.loading_num_remito = false;
          });
      }
    },
  },
};
</script>
