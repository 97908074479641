import { wsService } from "./wsService";
import sections from "@/constants/sections";

const section = sections.SECTION_STOCKS;

function filter(param = { enable: true }) {
  return wsService.make_post_request(section + "/list", param);
}

function movement(param = {}) {
  return wsService.make_post_request(section + "/movement", param);
}

export const stockService = {
  section,
  filter,
  movement,
};
