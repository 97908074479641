<template>
  <v-card elevation="1" class="mb-4" v-if="isProductCompany()">
    <v-card-text>
      <h6 class="text-h6 black--text">Stock</h6>
      <p class="text-body-2">Indique el deposito del cual se descontara el stock</p>

      <v-row>
        <v-col md="3">
          <v-combobox
            v-model="invoice.warehouse"
            data-vv-as="Deposito"
            name="warehouse"
            item-text="name"
            item-value="id"
            :items="warehouses"
            label="Deposito*"
            :v-validate="isRequired()"
            autocomplete="off"
            :disabled="isViewModal || invoice.id > 0"
          ></v-combobox>
          <span class="red--text">{{ errors.first("warehouse") }}</span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import Constant from "@/constants/sections";
import SettingsMixin from "@/mixins/SettingsMixin.vue";
import { generalService } from "@/libs/ws/generalService";
export default {
  created() {
    this.$validator = this.validator;
  },
  components: {},
  mixins: [SettingsMixin],
  props: {
    open: Boolean,
    invoice: Object,
    isViewModal: Boolean,
    validator: Object,
  },
  data: () => ({
    warehouses: [],
  }),
  watch: {
    open: {
      handler: function (val) {
        if (val) this.loadComboData();
      },
      deep: true,
    },
  },
  mounted() {
    this.loadComboData();
  },
  methods: {
    isRequired() {
      return this.invoice.stock_spent ? "required" : "";
    },
    loadComboData() {
      generalService.setSection(Constant.SECTION_WAREHOUSES);
      generalService
        .filter({ enabled: true })
        .then((response) => {
          this.warehouses = response.result;
          //Auto select default
          this.warehouses.forEach((element) => {
            if (element.default_warehouse) {
              this.invoice.warehouse = element;
            }
          });
          this.loading = false;
        })
        .catch((e) => {
          console.log("categories.filter error");
          console.log(e);
          this.loading = false;
        });
    },
  },
};
</script>
