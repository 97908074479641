import { wsService } from "./wsService";

var section = "";

function filter(param = {}, path = section) {  
  return wsService.make_post_request(path + "/list", param);
}

function get(id, path = section) {  
  return wsService.make_get_request(path + "/get/" + id);
}

function setSection(s) {  
  section = s;  
}

export const balancesService = { 
  section,
  setSection, 
  filter,
  get,
};