<template>
  <div>
    <v-subheader class="title-setting">Facturación</v-subheader>

    <v-form v-on:submit.prevent="save">
      <div class="col-md-12">
        <div class="col-md-12">
          <p class="title_bi">Nuevo Comprobante</p>
          <v-checkbox label="Precargar el comprobante anterior al seleccionar un receptor" v-model="mainItem.preload_last_voucher"></v-checkbox>
        </div>

        <div class="col-md-12 mt-4 text-right">
          <v-btn color="green darken-1" text :loading="loading" type="submit"> Guardar </v-btn>
        </div>
      </div>
    </v-form>
  </div>
</template>

<script>
export default {
  props: {
    mainItem: Object,
    createItem: Function,
    loading: Boolean,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    save(e) {
      this.$emit("createItem", e);
    },
  },
};
</script>
