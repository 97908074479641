<template>
  <v-col md="6">
    <v-card elevation="1" class="mb-4">
      <v-card-text :style="'min-height:' + minHeight">
        <h6 class="text-h6 black--text">Información extra</h6>
        <p class="text-body-2">Informacion utilizada para las metricas generadas por el sistema</p>
        <v-row class="mt-0" v-if="show_cost_center">
          <v-col md="6">            
            <v-combobox
              dense
              v-model="item.sub_cost_center"
              data-vv-as="sub_cost_center"
              name="sub_cost_center"
              item-text="label"
              item-value="id"
              :items="sub_costs_centers"
              label="Subcentro de Costos"              
              autocomplete="off"
              :disabled="isViewModal"
              filled
              clearable
            ></v-combobox>
            <span class="red--text">{{ errors.first("sub_cost_center") }}</span>
          </v-col>
          <v-col md="6" v-if="showMotivo()">
            <v-combobox
              dense
              v-model="item.motivo"
              data-vv-as="Motivo"
              autocomplete="off"
              name="motivo"
              :v-validate="requiredReceivers()"  
              :items="motivos"
              label="Motivo"
              clearable
              :disabled="isViewModal"
              filled
            ></v-combobox>
            <span class="red--text">{{ errors.first("motivo") }}</span>                            
          </v-col>  
        </v-row>        
        <v-row class="mt-0">
          <v-col md="6">
            <v-text-field
              dense
              autocomplete="off"
              data-vv-as="cotizacion dolar"
              label="Cotización del Dolar"
              name="dolar_cotiz"
              placeholder="Cotización del Dolar"
              v-validate="'required|decimal'"
              type="number"
              v-model="item.dolar_cotiz"
              class="mt-3"
              :disabled="isViewModal"
            />
            <span class="red--text">{{ errors.first("dolar_cotiz") }}</span>
          </v-col>                        
        </v-row>
      </v-card-text>
    </v-card>
  </v-col>
</template>
<script>
import Constant from "@/constants/sections";
import { currencyService } from "@/libs/ws/currencyService";
import { generalService } from "@/libs/ws/generalService";
export default {
  components: {},
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    item: Object,
    isViewModal: {
      type: Boolean,
      default: false,
    },
    minHeight: {
      type: String,
      default: "238px",
    },
    show_cost_center: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    motivos: [      
      "Devoluciones",
      "DiferenciasPrecio",
      "DiferenciasCantidad",
      "Ajustes",
      "Anulaciones",
      "AcuerdosComerciales",
      "OtrasDiferencias",
    ],
    sub_costs_centers: [],
  }),
  watch: {
    dialog(val) {
      if (val) {
        this.loadComboData();
      }
    },
  },
  mounted() {
    this.loadComboData(); //el primer edit de dialog no va al watch
  },
  methods: {
    loadComboData() {
      generalService.setSection(Constant.SECTION_SUB_COSTS_CENTERS);
      generalService
        .filter({ only_childs: true })
        .then((response) => {
          this.sub_costs_centers = response.result;
        })
        .catch((e) => {
          console.log(e);
          this.$toast.error("Ocurrio un error al obtener los SUB centros de costos.");
        });

      currencyService
        .getUSDPrice()
        .then((response) => {
          this.item.dolar_cotiz = response.result.price;
        })
        .catch((e) => {
          console.log(e);
          this.$toast.error("Ocurrio un error al obtener la cotizacion del dolar.");
        });
    },
    
    showMotivo(){
      return this.item.cbte_tipo  != null && [2,3,7,8,12,13,52,53,202,203,207,208,212,213].includes(this.item.cbte_tipo.Id);
    },
    requiredReceivers(){      
      if([1].includes(this.item.receiver.id)){//DIA
        return 'required';
      }
      return '';
    }
  },
};
</script>
