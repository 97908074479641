import axios from "axios";
import { wsService } from "./wsService";
import Constant from "@/constants/sections";

const section = Constant.SECTION_CURRENCY;


function getUSDPrice() {
  return wsService.make_post_request(section + "/price", { currency: 'USD', keyword: 'bolsa' });
}

export const currencyService = {
  section,
  getUSDPrice,
};
