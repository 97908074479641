<template>
  <div>
    <Title :pages="[{ icon: 'circle', page: page_title.toUpperCase() }]"></Title>

    <v-container fluid>
      <v-btn color="primary" class="mb-5" dark @click="updateDialog(true)">Crear {{ modal_title }}</v-btn>

      <v-btn class="ml-3 mb-5 text-white" color="blue-grey" @click="dialog_bulk = true">Importar Movimientos</v-btn>

      <Modal
        v-if="create_access"
        :modal_title="modal_title"
        :formBtn="formBtn"
        :dialog="dialog"
        :parent="parent"
        :service="service"
        @updateDialog="updateDialog"
        @loadList="loadList(service)"
        :item="mainItem"
        @updateItem="
          (newItem) => {
            mainItem = newItem;
          }
        "
      />

      <ModalBulk
        v-if="create_access"
        modal_title="Importar Movimientos Bancarios"
        :formBtn="formBtn"
        :service="service"
        :dialog="dialog_bulk"
        :mainItem="mainItem"
        @open="dialog_bulk = true"
        @close="dialog_bulk = false"
        @filter="loadList(service)"
      />

      <v-card>
        <div class="row mb-2 mt-0">
          <div class="col-md-12" v-if="list_access">
            <div class="col-md-12" style="background:white; elevation-0">
              <div class="row pt-5">
                <div class="col-md-3 pt-0">
                  <v-menu
                    ref="desde_selected"
                    v-model="desde_selected_open"
                    :close-on-content-click="false"
                    :close-on-click="false"
                    :return-value.sync="desde_selected"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                    clearable
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :disabled="reference_selected != null"
                        v-model="desde_selected"
                        label="Desde"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        class="pt-0 mt-0"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="desde_selected" type="date" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="desde_selected_open = false"> Cancel </v-btn>
                      <v-btn text color="primary" @click="$refs.desde_selected.save(desde_selected)"> OK </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>

                <div class="col-md-3 pt-0">
                  <v-menu
                    ref="hasta_selected"
                    v-model="hasta_selected_open"
                    :close-on-content-click="false"
                    :close-on-click="false"
                    :return-value.sync="hasta_selected"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                    clearable
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :disabled="reference_selected != null"
                        v-model="hasta_selected"
                        label="Hasta"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        class="pt-0 mt-0"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="hasta_selected" type="date" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="hasta_selected = false"> Cancel </v-btn>
                      <v-btn text color="primary" @click="$refs.hasta_selected.save(hasta_selected)"> OK </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>

                <div class="col-md-3 pt-0">
                  <v-combobox class="pt-0 mt-0" v-model="money_account_selected" item-text="name" item-value="id" :items="money_accounts" label="Cuenta Monetaria" clearable></v-combobox>
                </div>
                <div class="col-md-3 pt-0">
                  <v-combobox class="pt-0 mt-0" v-model="action_selected" item-text="name" item-value="id" :items="actions" label="Accion" clearable></v-combobox>
                </div>
                <div class="col-md-3 pt-0">
                  <v-text-field clearable v-model="reference_selected" autocomplete="off" class="pt-0 mt-0" label="Referencia"></v-text-field>
                </div>

                <div class="col-md-3 pt-0">
                  <v-combobox
                    v-model="sub_cost_center_selected"
                    autocomplete="off"
                    data-vv-as="sub_cost_center_selected"
                    name="sub_cost_center_selected"
                    item-text="label"
                    item-value="id"
                    :items="sub_costs_centers"
                    label="Subcentro de Costos"
                    class="pt-0 mt-0"
                    clearable
                  ></v-combobox>
                </div> 

                <div class="col-md-6 pt-0" style="display: flex; justify-content: end">
                  <v-btn color="blue-grey" class="mr-2" dark :loading="dialog_export" @click="exportFile()"> Exportar <font-awesome-icon class="ml-2" icon="download" /></v-btn>

                  <v-btn color="info" class="" @click="filter">Buscar</v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>

      <v-card v-if="list_access">
        <v-card-title>
          <span class="text-uppercase">{{ page_title }}</span>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="search" label="Buscar" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="list"
          :search="search"
          :loading="loading"
          loading-text="Cargando..."
          :options="{
            descending: true,
            itemsPerPage: 15,
          }"
          @pagination="updatePagination"
          :footer-props="{
            itemsPerPageOptions: [5, 10, 15, 50, -1],
          }"
        >
          <template v-slot:item="props">
            <tr>
              <td>{{ props.item.date }}</td>
              <td>{{ props.item.money_account.name }}</td>
              <td>{{ props.item.action_type_name }}</td>
              <td>{{ (props.item.sub_cost_center != null)?props.item.sub_cost_center.name:"" }}</td>
              <td>{{ props.item.concept }}</td>
              <td>{{ props.item.reference }}</td>
              <td>{{ formatUtils.formatPrice(props.item.debe_amount) }}</td>
              <td>{{ formatUtils.formatPrice(props.item.haber_amount) }}</td>
              <td class="justify-content-center px-0">
                <v-tooltip bottom v-if="props.item.action_type == 'M'">
                  <template v-slot:activator="{ on }">
                    <v-icon v-if="update_access" v-on="on" small color="blue" class="mr-2" @click="editItem(props.item)"> edit </v-icon>
                  </template>
                  <span>Editar</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="blue" class="mr-2" @click="viewItem(props.item)"> remove_red_eye </v-icon>
                  </template>
                  <span>Ver</span>
                </v-tooltip>
                <v-tooltip bottom v-if="props.item.action_type == 'M'">
                  <template v-slot:activator="{ on }">
                    <v-icon v-if="delete_access" v-on="on" small color="red" class="mr-2" @click="deleteItem(props.item)"> delete </v-icon>
                  </template>
                  <span>Eliminar</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              {{ "Busqueda sin resultados - " + search }}
            </v-alert>
          </template>
        </v-data-table>
      </v-card>

      <DialogDelete :dialog_delete="dialog_delete" @updateDialogDelete="updateDialogDelete" @deleteItemConfirm="deleteItemConfirm" />
    </v-container>
  </div>
</template>

<script>
import Constant from "@/constants/sections";
import { bankMovementService } from "@/libs/ws/bankMovementService";
import { generalService } from "@/libs/ws/generalService";
import GenericMixin from "@/mixins/GenericMixin.vue";
import Title from "@/components/Title.vue";
import DialogDelete from "@/components/DialogDelete.vue";
import Modal from "./Modal.vue";
import ModalBulk from "./ModalBulk.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import ExportMixin from "@/mixins/ExportMixin.vue";
import { formatUtils } from "@/libs/utils/formatUtils";

export default {
  components: { Title, Modal, DialogDelete, ModalBulk },
  mixins: [GenericMixin, RolMixin, ExportMixin],
  data() {
    return {
      formatUtils: formatUtils,
      parent: this,
      service: bankMovementService,
      dialog_bulk: false,
      page_title: "Movimientos de cuenta",
      modal_title: "Movimiento de cuenta",
      mainItem: {
        id: "",
        date: new Date(
          new Date().toLocaleDateString("en-US", {
            timeZone: "America/Buenos_Aires",
          })
        )
          .toISOString()
          .slice(0, 10),
        money_account: null,
        amount: null,
        movement_type: null,
        action_type: "M",
        concept: null,
        reference: null,
        charge_payment_method: null,
        spent_payment_method_id: null,
        sub_cost_center: null,        
      },
      defaultItem: null,
      headers: [
        {
          text: "Fecha",
          align: "left",
          sortable: true,
          value: "date",
        },
        {
          text: "Cuenta Monetaria",
          align: "left",
          sortable: true,
          value: "money_account.name",
        },
        {
          text: "Accion",
          align: "left",
          sortable: true,
          value: "action_type_name",
        },
        {
          text: "Subcentro de Costo",
          align: "left",
          sortable: true,
          value: "sub_cost_center.name",
        },
        {
          text: "Concepto",
          align: "left",
          sortable: true,
          value: "concept",
        },
        {
          text: "Referencia",
          align: "left",
          sortable: true,
          value: "reference",
        },
        {
          text: "Debe",
          align: "left",
          sortable: true,
          value: "debe_amount",
        },
        {
          text: "Haber",
          align: "left",
          sortable: true,
          value: "haber_amount",
        },
        { text: "Accion", value: "", align: "center" },
      ],
      list: [],
      dialog_delete: false,
      money_accounts: [],
      sub_costs_centers: [],
      actions: [
        { id: "M", name: "Movimiento Bancario" },
        { id: "C", name: "Cobranza" },
        { id: "P", name: "Pago" },
      ],

      desde_selected_open: false,
      hasta_selected_open: false,
      desde_selected: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().slice(0, 10),
      hasta_selected: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().slice(0, 10),
      money_account_selected: null,
      cost_center_selected: null,
      sub_cost_center_selected: null,
      action_selected: null,
      reference_selected: null,
    };
  },
  mounted() {
    this.loadComboData();
    this.defaultItem = JSON.parse(JSON.stringify(this.mainItem));
    this.loadList(this.service);
  },
  methods: {
    deleteItemConfirm() {
      this.delete(this.service);
      this.dialog_delete = false;
      this.loadList(this.service);
    },
    getFilterParams() {
      const param = {
        date_from: this.desde_selected,
        date_to: this.hasta_selected,
        action_type: this.action_selected ? this.action_selected.id : null,
        money_account: this.money_account_selected ? this.money_account_selected.id : null,
        reference: this.reference_selected,
        sub_cost_center: this.sub_cost_center_selected ? this.sub_cost_center_selected.id : null,
      };
      return param;
    },
    filter() {
      this.loadList(this.service);
    },
    exportFile() {
      var param = this.getFilterParams();
      param.export = true;
      this.exportExcel(Constant.SECTION_BANKS_MOVEMENTS + "/list", param, "Movimientos-Bancarios.xlsx");
    },
    loadComboData() {
      generalService.setSection(Constant.SECTION_MONEY_ACCOUNTS);
      generalService
        .filter()
        .then((response) => {
          this.money_accounts = response.result;
        })
        .catch((e) => {
          console.log("money_accounts.filter error");
          console.log(e);
        });

      generalService.setSection(Constant.SECTION_SUB_COSTS_CENTERS);
      generalService
        .filter()
        .then((response) => {
          this.sub_costs_centers = response.result;
        })
        .catch((e) => {
          console.log("costs_centers.filter error");
          console.log(e);
        });
    },
  },
};
</script>
