<template>
  <div>
    <Title :pages="[{ icon: 'rectangle-list', page: page_title.toUpperCase() }]"></Title>

    <v-container fluid>
      <Modal
        v-if="create_access"
        :button_name="$t('Crear') + ' ' + modal_title"
        :modal_title="modal_title"
        :formBtn="formBtn"
        :dialog="dialog"
        :service="service"
        @updateDialog="updateDialog"
        @loadList="loadList(service)"
        :item="mainItem"
        @updateItem="
          (newItem) => {
            mainItem = newItem;
          }
        "
      />

      <v-card v-if="list_access">
        <v-card-title>
          <span class="text-uppercase">{{ page_title }}</span>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="search" label="Buscar" single-line hide-details></v-text-field>
        </v-card-title>
        <div class="mb-4" v-show="selected.length != 0 && update_access">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                style="border: 1px solid; border-radius: 100px; padding: 3px"
                color="blue-grey"
                :class="isActiveBlinking ? 'ml-3 button_blinking' : 'ml-3'"
                @click="toggleClass"
                v-bind="attrs"
                v-on="on"
              >
                more_vert
              </v-icon>
            </template>
            <v-list>
              <v-list-item dense @click="bulkUpdate()">
                <v-list-item-title> Aumento masivo de precios</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <v-data-table
          :headers="headers"
          :items="list"
          :search="search"
          :loading="loading"
          v-model="selected"
          loading-text="Cargando..."
          :options="{
            descending: true,
            itemsPerPage: 15,
          }"
          @pagination="updatePagination"
          :footer-props="{
            itemsPerPageOptions: [5, 10, 15, 50, -1],
          }"
        >
          <template v-slot:item="{ item, isSelected, select }">
            <tr>
              <td>
                <v-checkbox :input-value="isSelected" style="margin: 0px; padding: 0px" hide-details @change="select($event)" />
              </td>
              <td>{{ item.name }}</td>
              <td v-if="item.enabled">Si</td>
              <td v-else>No</td>
              <td class="text-center px-0">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-if="create_access" v-on="on" small color="blue-grey" class="mr-2" @click="cloneItem(item)"> content_copy </v-icon>
                  </template>
                  <span>Clonar</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-if="update_access" v-on="on" small color="blue" class="mr-2" @click="editItem(item)"> edit </v-icon>
                  </template>
                  <span>Editar</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="blue" class="mr-2" @click="goToDetail(item)"> remove_red_eye </v-icon>
                  </template>
                  <span>Ver productos</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-if="delete_access" v-on="on" small color="red" class="mr-2" @click="deleteItem(item)"> delete </v-icon>
                  </template>
                  <span>Eliminar</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              {{ "Busqueda sin resultados - " + search }}
            </v-alert>
          </template>
        </v-data-table>
      </v-card>

      <DialogDelete :dialog_delete="dialog_delete" @updateDialogDelete="updateDialogDelete" @deleteItemConfirm="deleteItemConfirm" />

      <ModalBulkUpdate :show="dialog_bulk_update" @close="dialog_bulk_update = false" @refresh="loadList(service)" :seleted="selected" />

      <DialogYesNo
        :open="dialog_clone"
        title="Confirmar"
        message="¿Estas seguro que deseas clonar la lista de precios?"
        @cancel="dialog_clone = false"
        @accept="confirmClone"
        :loading="loading_clone"
      />
    </v-container>
  </div>
</template>

<script>
import Constant from "@/constants/sections";
import { priceListService } from "@/libs/ws/priceListService";
import { formatUtils } from "@/libs/utils/formatUtils";
import GenericMixin from "@/mixins/GenericMixin.vue";
import Title from "@/components/Title.vue";
import DialogDelete from "@/components/DialogDelete.vue";
import DialogYesNo from "@/components/DialogYesNo.vue";
import Modal from "./Modal.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import ModalBulkUpdate from "./ModalBulkUpdate.vue";
export default {
  components: { Title, Modal, DialogDelete, ModalBulkUpdate, DialogYesNo },
  mixins: [GenericMixin, RolMixin],
  data() {
    return {
      formatUtils: formatUtils,
      parent: this,
      isActiveBlinking: true,
      dialog_clone: false,
      loading_clone: false,
      item_clone: null,
      service: priceListService,
      page_title: "Listas de precio",
      modal_title: "Lista de precio",
      mainItem: {
        id: "",
        name: "",
        percentage: 0,
        enabled: true,
      },
      defaultItem: null,
      headers: [
        { text: "", align: "center", sortable: false, value: "" },
        {
          text: "Nombre",
          align: "left",
          sortable: true,
          value: "name",
        },
        {
          text: "Habilitado",
          align: "left",
          sortable: true,
          value: "enabled",
        },
        { text: "Accion", value: "", align: "center" },
      ],
      list: [],
      dialog_delete: false,
      dialog_bulk_update: false,
    };
  },
  mounted() {
    this.defaultItem = JSON.parse(JSON.stringify(this.mainItem));
    this.loadList(this.service);
  },
  methods: {
    goToDetail(item) {
      this.$router.push(Constant.SECTION_PRICE_LIST + "/" + item.id);
    },
    toggleClass() {
      this.isActiveBlinking = false;
    },
    bulkUpdate() {
      this.dialog_bulk_update = true;
    },
    deleteItemConfirm() {
      this.delete(this.service);
      this.dialog_delete = false;
      this.loadList(this.service);
    },
    cloneItem(item) {
      this.item_clone = item;
      this.dialog_clone = true;
    },
    confirmClone() {
      this.loading_clone = true;
      var param = {
        id: this.item_clone.id,
        percentage: 0,
      };

      priceListService
        .clone(param)
        .then((response) => {
          if (response.status === true) {
            this.$toast.success(response.msg, "OK");
            this.dialog_clone = false;
            this.loadList(this.service);
          } else {
            if (Object.prototype.hasOwnProperty.call(response, "message")) {
              this.$toast.error(response.message, "Error");
            } else {
              this.$toast.error(response.msg, "Error");
            }
          }
        })
        .catch((e) => {
          console.log("checkService.changeState error");
          console.log(e);
        })
        .finally(() => {
          this.loading_clone = false;
        });
    },
  },
};

// TODO agregar un boton de limpiar busqueda.
</script>
