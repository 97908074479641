<template>
  <div>
    <v-subheader class="title-setting">Mi Empresa</v-subheader>
    <v-form v-on:submit.prevent="save">
      <div class="col-md-12">
        <div class="col-md-6">
          <v-text-field filled data-vv-as="Razon Social" label="Razon Social" name="business_name" v-model="mainItem.business_name" v-validate="'required'"></v-text-field>
          <span class="red--text">{{ errors.first("business_name") }}</span>
        </div>


        <div class="col-md-12">
          <p class="mb-0"><b>Seleccione el tipo de venta que efectua</b></p>
          <p class="caption">Segun el tipo de venta seleccionado, podrá visualizar una interfaz adaptada a sus necesidades</p>
          <v-radio-group v-model="mainItem.business_type">
            <v-radio label="Venta de servicios" value="0"></v-radio>
            <v-radio label="Venta de productos" value="1"></v-radio>
          </v-radio-group>
        </div>

        <!--
        <div class="col-md-12">
          <p class="mb-0"><b>Seleccione el tipo ingreso de Stock</b></p>
          <p class="caption">Segun el tipo de ingreso, podrá confirmar la entrada de stock al sistema</p>
          <v-radio-group v-model="mainItem.business_type">
            <v-radio label="Confirmar stock solo a partir de comprobante de compra" value="S"></v-radio>
            <v-radio label="Confirmar stock solo a partir de recepción" value="R"></v-radio>            
          </v-radio-group>
        </div>
        -->

        <div class="col-md-6">
          <img v-if="mainItem.logo_url != null && mainItem.logo_url.length > 0" :src="mainItem.logo_url" style="object-fit: cover; max-width: 200px; max-height: 100px" />
          <v-avatar size="130px" v-if="mainItem.logo_url == null || mainItem.logo_url == ''">
            <img :src="require('@/assets/images/no-photo.png')" style="object-fit: cover" />
          </v-avatar>
        </div>

        <div class="col-md-12">
          <p class="caption">Tamaño recomendado 200x100px</p>
          <v-btn @click="uploadImage()" color="primary" small> Adjuntar logo para factura</v-btn>
          <v-file-input v-show="false" label="File input" name="image_upload" ref="image_ref" v-model="mainItem.image_upload" @change="setImage()"></v-file-input>
        </div>

        <div class="col-md-12 mt-4 text-right">
          <v-btn color="green darken-1" text :loading="loading" type="submit"> Guardar </v-btn>
        </div>
      </div>
    </v-form>
  </div>
</template>

<script>
export default {
  props: {
    mainItem: Object,
    createItem: Function,
    loading: Boolean,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    uploadImage() {
      this.$refs.image_ref.$refs.input.click();
    },
    setImage() {
      //console.log(this.mainItem);
      this.mainItem.logo_url = URL.createObjectURL(this.mainItem.image_upload);
    },
    save(e) {
      this.$emit("createItem", e);
    },
  },
};
</script>
