<template>
  <v-card elevation="1" class="mb-4">
    <v-card-text>
      <h6 class="text-h6 black--text">Productos o servicios facturados</h6>
      <p v-if="!isViewModal" class="text-body-2">Ingrese los productos o servicios que va incluir en la factura</p>

      <v-row>
        <v-col md="12" v-if="!isViewModal">
          <v-btn :disabled="isDisabledAdd()" small color="blue-grey" class="pl-4 text-white" @click="addCustomDetail()">
            <v-icon big color="green"> add </v-icon>
            <span>Agregar item</span>
          </v-btn>
        </v-col>

        <v-col md="12" class="px-4 pb-4">
          <v-data-table :disabled="invoice.id > 0" :headers="header_custome" :items="invoice.items_custom" style="border: solid 1px #1c1c1c" :hide-default-footer="true" :items-per-page="100">
            <template v-slot:item="props">
              <tr>
                <td>
                  <v-combobox
                    v-model="props.item.product_presentation"
                    data-vv-as="producto"
                    :name="'product_presentation_' + props.index"
                    :item-text="label"
                    item-value="id"
                    :items="presentations"
                    label="Producto*"
                    autocomplete="off"
                    clearable
                    v-validate="'required'"
                    v-on:change="productChangeCombo($event, props.index)"
                    :disabled="isViewModal || invoice.id > 0"
                  ></v-combobox>
                  <span class="red--text">{{ errors.first("product_presentation_" + props.index) }}</span>
                </td>
                <td style="width: 30%">
                  <v-text-field
                    :disabled="isViewModal || invoice.id > 0"
                    autocomplete="off"
                    :name="'descripcion_' + props.index"
                    placeholder="Descripción*"
                    dense
                    type="text"
                    v-model="props.item.descripcion"
                    class="mt-3"
                  />
                  <span class="red--text">{{ errors.first("descripcion_" + props.index) }}</span>
                </td>

                <td style="width: 100px">
                  <v-text-field
                    data-vv-as="Cantidad"
                    :disabled="isViewModal || invoice.id > 0"
                    autocomplete="off"
                    :name="'quantity_' + props.index"
                    placeholder="Cantidad*"
                    dense
                    min="0"
                    v-validate="'required|decimal|min:1'"
                    type="number"
                    v-model="props.item.quantity"
                    class="mt-3"
                  />
                  <span class="red--text">{{ errors.first("quantity_" + props.index) }}</span>
                </td>
                <td>
                  <v-text-field
                    data-vv-as="Precio"
                    :disabled="isViewModal || invoice.id > 0"
                    autocomplete="off"
                    :name="'price_' + props.index"
                    placeholder="Precio*"
                    dense
                    v-validate="'required|decimal'"
                    v-model="props.item.price"
                    min="0"
                    class="mt-3"
                  />
                  <span class="red--text">{{ errors.first("price_" + props.index) }}</span>
                </td>
                <td style="width: 100px">
                  <v-text-field
                    data-vv-as="Bonificación"
                    :disabled="isViewModal || invoice.id > 0"
                    autocomplete="off"
                    :name="'discount_per_' + props.index"
                    placeholder="Bonificación"
                    dense
                    min="0"
                    v-validate="'required|decimal|between:0,100'"
                    type="number"
                    v-model="props.item.discount_per"
                    class="mt-3"
                    @change="updateDiscountImp(props.item)"
                  />
                  <span class="red--text">{{ errors.first("discount_per_" + props.index) }}</span>
                </td>
                <td style="width: 100px">
                  <div v-if="invoice.emitter != null && invoice.emitter.iva_condition != undefined && invoice.emitter.iva_condition.id != 2">
                    <v-combobox
                      v-model="props.item.iva"
                      data-vv-as="iva"
                      :name="'iva_' + props.index"
                      item-text="Desc"
                      item-value="Id"
                      :items="ivas"
                      label="IVA*"
                      autocomplete="off"
                      :loading="loading_combos['ivas']"
                      v-validate="'required'"
                      :disabled="isViewModal || invoice.id > 0"
                    ></v-combobox>
                    <span class="red--text">{{ errors.first("iva_" + props.index) }}</span>
                  </div>
                  <div v-else>
                    <v-text-field name="iva" v-model="props.item.iva" :value="null" v-show="false" />
                    N/A
                  </div>
                </td>

                <td class="text-right">{{ showTotalCustomeItem(props.item) }}</td>
                <td class="text-center">
                  <v-tooltip bottom v-if="!isViewModal">
                    <template v-slot:activator="{ on }">
                      <v-icon :disabled="invoice.id > 0" v-on="on" small color="red" @click="removeDetail(props.item, 'custom')"> indeterminate_check_box </v-icon>
                    </template>
                    <span>Remover</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import Constant from "@/constants/sections";
import { formatUtils } from "@/libs/utils/formatUtils";
import { generalService } from "@/libs/ws/generalService";
import { vouchersService } from "@/libs/ws/vouchersService";
import { emmiterService } from "@/libs/ws/emmiterService";
import SettingsMixin from "@/mixins/SettingsMixin.vue";
export default {
  created() {
    this.$validator = this.validator;
  },
  components: {},
  props: {
    open: Boolean,
    invoice: Object,
    loading_combos: Object,
    loadItemsFromInvoiceCancel: Function,
    validator: Object,
    isViewModal: {
      type: Boolean,
      default: false,
    },
    items: {
      //Se usa para generar voucher a partir de remitos
      default: null,
      type: Array,
    },
  },
  mixins: [SettingsMixin],
  data: () => ({
    formatUtils: formatUtils,
    presentations: [],
    ivas: [],
    emitter_selected: null,
    receiver_seleted: null,
    header_custome: [
      { text: "Producto", value: "product_presentation", sortable: false },
      { text: "Descripción", value: "descripcion", sortable: false },
      { text: "Cantidad", value: "quantity", sortable: false, class: "bg-grey" },
      { text: "Precio", value: "price", sortable: false },
      { text: "Bonif. %", value: "discount_per", sortable: false },
      { text: "IVA", value: "iva", sortable: false, class: "bg-grey" },
      { text: "Total", value: "total", sortable: false },
      { text: "", value: "", sortable: false, class: "bg-grey" },
    ],
  }),
  watch: {
    open: function (val) {
      if (val) {
        this.errors.clear();
        this.$validator.reset();
        this.loadProductos();
        this.loadItemsRemito();
      }
    },
    items: function (val) {
      if (val != null && val.length > 0 && this.invoice.items_custom.length == 0) {
        this.invoice.items_custom.concat(val);
      }
    },
    invoice: {
      handler: function (newValue) {
        if ((this.emitter_selected == null && newValue.emitter != null) || (newValue.emitter != null && this.emitter_selected.id != newValue.emitter.id)) {
          this.emitter_selected = newValue.emitter;
          this.emitterChangeEvent();
        }
        if ((this.receiver_seleted == null && newValue.receiver != null) || (newValue.receiver != null && this.receiver_seleted.id != newValue.receiver.id)) {
          this.receiver_seleted = newValue.receiver;
          this.loadProductos();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.loadItemsRemito();
  },
  methods: {
    loadItemsRemito() {
      if (this.items != null && this.items.length > 0 && this.invoice.items_custom.length == 0) {
        //completo el iva del producto que viene de remitos y su precio
        this.items.forEach((item) => {
          item.iva = item.product_presentation.default_iva_id;
          item.price = 0;
        });
        this.invoice.items_custom = JSON.parse(JSON.stringify(this.items));
      }
    },
    label(item) {
      if (this.isProductCompany()) return item.label;
      return item.name;
    },
    emitterChangeEvent() {
      if (this.invoice.emitter != null && this.invoice.emitter.id) {
        this.loading_combos["ivas"] = true;
        emmiterService
          .ivas(this.invoice.emitter.id)
          .then((response) => {
            if (response.status) {
              this.ivas = response.result;
              //this.$emit("loadItemsFromInvoiceCancel");
            }
          })
          .finally(() => {
            this.loading_combos["ivas"] = false;
          });
      }
    },
    loadProductos() {
      if (this.receiver_seleted == null) return;

      generalService.setSection(Constant.SECTION_PRODUCTS);
      generalService
        .query({ client_id: this.receiver_seleted.client.id, sellable: true }, Constant.SECTION_PRODUCTS + "/presentations")
        .then((response) => {
          this.presentations = response.result;
        })
        .catch((e) => {
          console.log("presentations.filter error");
        });
    },
    isDisabledAdd() {
      return this.invoice.emitter == null || this.invoice.id > 0;
    },
    addCustomDetail() {
      this.invoice.items_custom.push({
        descripcion: "",
        quantity: 1,
        price: 0,
        product_presentation: null,
        iva: null,
        discount_imp: 0,
        discount_per: 0,
      });
    },
    productChangeCombo(e, index) {
      if (e == null) return;

      if (this.invoice.items_custom[index].descripcion == "" && this.label(e) != null) {
        this.invoice.items_custom[index].descripcion = this.label(e);
      }
      //seteo el precio del producto
      this.invoice.items_custom[index].price = parseFloat(e.price);
      this.invoice.items_custom[index].discount_per = parseFloat(e.discount_per);

      if (this.invoice.emitter != null && this.invoice.emitter.iva_condition != undefined && this.invoice.emitter.iva_condition.id != 2 && e.default_iva_id != null) {
        this.invoice.items_custom[index].iva = e.default_iva_id;
      }
    },
    showTotalCustomeItem(item) {
      let totals = this.calculateCustomeItemTotals(item);
      return formatUtils.formatPrice(totals.total);
    },
    calculateCustomeItemTotals(item) {
      if (item.price == null || item.quantity == null)
        return {
          imp_neto: 0,
          imp_iva: 0,
        };
      this.updateDiscountImp(item);
      let imp_neto = formatUtils.formatPriceNumber(item.quantity * item.price - item.discount_imp);
      const iva_id = item.iva != null ? item.iva.Id : null;
      let imp_iva =
        vouchersService.NoIVAList().includes(iva_id) || iva_id == null ? 0.0 : (formatUtils.formatPriceNumber(imp_neto) * formatUtils.formatPriceNumber(item.iva.Desc.replace("%", ""))) / 100;
      return {
        imp_neto: imp_neto,
        imp_iva: formatUtils.formatPriceNumber(imp_iva),
        total: formatUtils.formatPriceNumber(imp_neto) + formatUtils.formatPriceNumber(imp_iva),
      };
    },
    removeDetail(item, colection_name) {
      if (colection_name == "custom") {
        const index_item = this.invoice.items_custom.indexOf(item);
        if (index_item > -1) this.invoice.items_custom.splice(index_item, 1);
      }
    },
    updateDiscountImp(item) {
      if (item.price != null && item.quantity != null && item.discount_per != null) item.discount_imp = (item.price * item.quantity * item.discount_per) / 100;
    },
  },
};
</script>
