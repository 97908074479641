<template>
  <v-layout>
    <v-dialog v-bind:value="dialog" persistent max-width="900px">
      <v-card class="background-voucher" style="background: rgb(221, 225, 230)">
        <v-form v-on:submit.prevent="editItem">
          <v-card-title class="mb-4">
            <span class="headline">Pago a cuenta</span>
          </v-card-title>
          <v-card-text>
            <div class="row">
              <div class="col-md-12">
                <!--Comprobante a Cobrar-->
                <ComprobanteAPagar :isAssignVoucher="true" :item="item" :open="dialog" :validator="$validator" />                                
              </div>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="close()">Cerrar</v-btn>
            <v-btn color="success" v-if="!isViewModal()" text type="submit" :loading="loading">Editar</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { formatUtils } from "@/libs/utils/formatUtils";
import { paymentSpentService } from "@/libs/ws/paymentSpentService";
import ModalGenericMixin from "@/mixins/ModalGenericMixin.vue";
import ComprobanteAPagar from "@/views/Spents/PaymentComponent/ComprobanteAPagar.vue";

export default {
  mixins: [ModalGenericMixin],
  components: { ComprobanteAPagar },
  props: {
    openFromSpent: { type: Boolean, default: false },
    spentPayment: { type: Object, default: null },
    dialog: Boolean,
    button_name: String,
    formBtn: String,
    closeDialog: Function,
    loadList: Function,
  },
  data() {
    return {
      show: false,
      formatUtils: formatUtils,
      total: 0,
      defaultItem: null,
      item: {
        id: 0,
        supplier: null,
        spents: [],
        cheque: {
          emitter_name: null,
          bank: null,
          emitter: null,
          money_account: null,
          number: null,
          is_own: true,
        },
        money_account: null,
        payment_method: null,
        dolar_cotiz: null,
        fch_payment_efective: null,
        amount: 0,
        tributes: [],
        cbte_fch: new Date(
          new Date().toLocaleDateString("en-US", {
            timeZone: "America/Buenos_Aires",
          })
        )
          .toISOString()
          .slice(0, 10),
      },
    };
  },
  created: function () {
    window.addEventListener("keydown", (e) => {
      if (e.key == "Escape") {
        this.close();
      }
    });
  },
  watch: {
    dialog(val) {
      this.$validator.reset();
      this.errors.clear();
      this.init();
    },
    item: {
      handler: function (newValue) {
        this.getTotal();
      },
      deep: true,
    },
  },
  mounted() {
    this.defaultItem = JSON.parse(JSON.stringify(this.item));
  },
  methods: {
    close() {
      this.item = JSON.parse(JSON.stringify(this.defaultItem));
      this.$emit("closeDialog");
    },
    init() {
      if (this.spentPayment != null) {
        this.spentPayment.spent = null;
        this.item = this.spentPayment;
      }
    },
    getTotal() {
      let total = 0;
      if (this.item.amount != null) {
        total = parseFloat(this.item.amount);
      }

      this.item.tributes.forEach((tribute) => {
        const amount = tribute.amount != undefined ? tribute.amount : 0;
        total += parseFloat(amount);
      });
      this.total = total;
    },
    customValidations() {
      var total_spents = 0.0;
      this.item.spents.forEach((spent) => {
        total_spents += parseFloat(spent.imp_total);
      });
      if (total_spents > this.total) {
        this.$toast.error("El total de los comprobantes a pagar debe ser menor o igual al total del pago", "Error");
        return false;
      }
      return true;
    },
    provide() {
      return {
        $validator: this.$validator,
      };
    },
    editItem(e) {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          e.preventDefault();

          if (!this.customValidations()) {
            return;
          }

          this.loading = true;

          const data = {
            id: this.item.id,
            spents_arr: this.item.spents,
            supplier: this.item.supplier.id,
            payment_method: this.item.payment_method,
            cbte_fch: this.item.cbte_fch,
            dolar_cotiz: this.item.dolar_cotiz,
            amount: this.item.amount,
          };

          paymentSpentService
            .update(data)
            .then((response) => {
              if (response.status) {
                this.$toast.success("El pago se actualizo correctamente", "OK");
                this.close();
                this.$emit("loadList");
              } else {
                if (Object.prototype.hasOwnProperty.call(response, "message")) {
                  this.$toast.error(response.message, "Error");
                } else {
                  this.$toast.error(response.msg, "Error");
                }
              }
            })
            .catch((response) => {
              this.$toast.error(response.msg, "Error");
            })
            .finally(() => (this.loading = false));
        }
      });
    },
    isViewModal() {
      return this.formBtn == "";
    },
  },
};
</script>
