<template>
  <v-layout>
    <v-dialog v-bind:value="dialog" @click:outside="close" max-width="600px">
      <v-card class="background-voucher">
        <v-form v-on:submit.prevent="updateItem">
          <v-card-title class="mb-4">
            <span class="headline" v-if="remito != null && remito.voucher != null">Reasignar Factura</span>
            <span class="headline" v-if="remito != null && remito.voucher == null">Asignar Factura</span>
          </v-card-title>
          <v-card-text>
            <div class="row">
              <div class="col-md-12">
                <v-col md="8" class="mt-2">
                  <h6 v-if="remito != null">Remito {{ remito.remito_label }}</h6>
                </v-col>
                <v-col md="8" class="mt-2">
                  <v-combobox
                    :loading="loading_vouchers"
                    v-model="voucher"
                    name="factura"
                    item-text="voucher_number_label"
                    item-value="id"
                    :items="vouchers"
                    label="Factura"
                    autocomplete="off"
                  ></v-combobox>
                  <span class="red--text">{{ errors.first("emitter") }}</span>
                </v-col>
              </div>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="close()">Cerrar</v-btn>
            <v-btn color="success" text type="submit" :loading="loading">Editar</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { formatUtils } from "@/libs/utils/formatUtils";
import { remitoService } from "@/libs/ws/remitoService";

import ModalGenericMixin from "@/mixins/ModalGenericMixin.vue";

export default {
  mixins: [ModalGenericMixin],
  components: {},
  props: {
    remito: { type: Object, default: null },
    dialog: Boolean,
    closeDialog: Function,
  },
  data() {
    return {
      voucher: null,
      vouchers: [],
      loading_vouchers: false,
    };
  },
  created: function () {
    window.addEventListener("keydown", (e) => {
      if (e.key == "Escape") {
        this.close();
      }
    });
  },
  watch: {
    dialog(val) {
      this.$validator.reset();
      this.errors.clear();

      if (val) {
        this.voucher = null;
        this.getVouchers();
      }
    },
  },
  mounted() {},
  methods: {
    close() {
      this.$emit("closeDialog");
    },
    getVouchers() {
      this.loading_vouchers = true;
      let data = {
        receiver: this.remito.receiver.id,
      };

      remitoService
        .getVouchers(data)
        .then((response) => {
          if (response.status) {
            this.vouchers = response.result;
            this.vouchers.push(this.remito.voucher);
          } else {
            this.$toast.error(response.msg, "Error");
          }
        })
        .catch((response) => {
          this.$toast.error(response.msg, "Error");
        })
        .finally(() => (this.loading_vouchers = false));
    },
    updateItem(e) {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          e.preventDefault();

          this.loading = true;

          let data = {
            remito_id: this.remito.id,
            voucher_id: this.voucher != null ? this.voucher.id : null,
          };

          remitoService
            .setVoucher(data)
            .then((response) => {
              if (response.status) {
                this.$toast.success("El remito se modifico correctamente", "OK");
                this.close();
                this.$emit("reload");
              } else {
                if (Object.prototype.hasOwnProperty.call(response, "message")) {
                  this.$toast.error(response.message, "Error");
                } else {
                  this.$toast.error(response.msg, "Error");
                }
              }
            })
            .catch((response) => {
              this.$toast.error(response.msg, "Error");
            })
            .finally(() => (this.loading = false));
        }
      });
    },
  },
};
</script>
