<template>
  <div>
    <v-dialog v-model="open" max-width="800">
      <v-card>
        <v-card-title>
          <span class="text-uppercase">{{ title }}</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="cancel">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div class="mb-5">
            <v-text-field v-model="search" append-icon="search" label="Buscar" single-line hide-details></v-text-field>
          </div>
          <v-data-table
            :headers="headers"
            :items="items"
            :search="search"
            :options="{
              descending: true,
              itemsPerPage: 15,
            }"
            @pagination="updatePagination"
            :footer-props="{
              itemsPerPageOptions: [5, 10, 15, 50, -1],
            }"
          >
            <template v-slot:item="props">
              <tr>
                <td v-for="(column, index) in headers" :key="index">{{ showField(column,props.item) }}</td>
              </tr>
            </template>
            <template v-slot:no-results>
              <v-alert :value="true" color="error" icon="warning">
                {{ "Sin resultados - " + search }}
              </v-alert>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>

import { formatUtils } from "@/libs/utils/formatUtils";

export default {
  props: {
    title: String,    
    open: Boolean,
    cancel: Function,    
    items: Array, 
    headers: Array,   
  },
  data: () => ({
    search: "",
    formatUtils: formatUtils,
    rowsPerPageItems: [10, 20, 30, 40],
    pagination: {
      page: 1,
      itemsPerPage: 5,
    },
  }),
  methods: {
    updatePagination(pagination) {
      this.pagination = pagination;
    },
    showField(column,item){      
      if(item == null || column == null)
        return "";
      
      if(column.value != null){
        if(column.format == "price")
          return formatUtils.formatPrice(item[column.value]);
        return item[column.value];
      }

      if(column.operation != undefined && column.operation != null){
        switch(column.operation){
          case '-': {
            let result = 0;
            for(let i = 0; i < column.fields.length; i++){
              result -= item[column.fields[i]];
            }
            if(column.format == "price")
              return formatUtils.formatPrice(result);
            return result;
          }
        }
      }

      return "";
    }
  },
};
</script>
