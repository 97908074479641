var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Title',{attrs:{"pages":[{ icon: 'inbox', page: _vm.page_title.toUpperCase() }]}}),_c('v-container',{attrs:{"fluid":""}},[(_vm.create_access)?_c('Modal',{attrs:{"button_name":_vm.$t('Crear') + ' ' + _vm.modal_title,"modal_title":_vm.modal_title,"formBtn":_vm.formBtn,"dialog":_vm.dialog,"parent":_vm.parent,"service":_vm.service,"item":_vm.mainItem},on:{"updateDialog":_vm.updateDialog,"loadList":function($event){return _vm.loadList(_vm.service)},"updateItem":(newItem) => {
          _vm.mainItem = newItem;
        }}}):_vm._e(),_c('v-card',[_c('div',{staticClass:"row mb-5 mt-0"},[(_vm.list_access)?_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"col-md-12",staticStyle:{"background":"white"}},[_c('div',{staticClass:"row pt-5"},[_c('div',{staticClass:"col-md-3 pt-0"},[_c('v-combobox',{staticClass:"pt-0 mt-0",attrs:{"autocomplete":"off","data-vv-as":"client_selected","name":"client_selected","item-text":"business_name","item-value":"id","items":_vm.clients,"label":"Cliente","clearable":""},model:{value:(_vm.client_selected),callback:function ($$v) {_vm.client_selected=$$v},expression:"client_selected"}})],1),_c('div',{staticClass:"col-md-6 pt-0"}),_c('div',{staticClass:"col-md-3 pt-0",staticStyle:{"display":"flex","justify-content":"end"}},[_c('v-btn',{attrs:{"color":"info"},on:{"click":_vm.filter}},[_vm._v("Buscar")])],1)])])]):_vm._e()])]),(_vm.list_access)?_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-uppercase"},[_vm._v(_vm._s(_vm.page_title))]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"search","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.list,"search":_vm.search,"loading":_vm.loading,"loading-text":"Cargando...","options":{
          descending: true,
          itemsPerPage: 15,
        },"footer-props":{
          itemsPerPageOptions: [5, 10, 15, 50, -1],
        }},on:{"pagination":_vm.updatePagination},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',[_c('td',[_vm._v(_vm._s(props.item.client.name))]),_c('td',[_vm._v(_vm._s(props.item.name))]),_c('td',[_vm._v(_vm._s(props.item.doc_type.name))]),_c('td',[_vm._v(_vm._s(props.item.doc_nr))]),_c('td',[_vm._v(_vm._s(props.item.iva_condition.name))]),_c('td',{staticClass:"justify-content-center px-0"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [(_vm.update_access)?_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":"","color":"blue"},on:{"click":function($event){return _vm.editItem(props.item)}}},on),[_vm._v(" edit ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({staticClass:"mr-1",attrs:{"small":"","color":"blue"},on:{"click":function($event){return _vm.viewItem(props.item)}}},on),[_vm._v(" remove_red_eye ")])]}}],null,true)},[_c('span',[_vm._v("Ver")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [(_vm.delete_access)?_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":"","color":"red"},on:{"click":function($event){return _vm.deleteItem(props.item)}}},on),[_vm._v(" delete ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])],1)])]}},{key:"no-results",fn:function(){return [_c('v-alert',{attrs:{"value":true,"color":"error","icon":"warning"}},[_vm._v(" "+_vm._s("Busqueda sin resultados - " + _vm.search)+" ")])]},proxy:true}],null,false,2923407716)})],1):_vm._e(),_c('DialogDelete',{attrs:{"dialog_delete":_vm.dialog_delete},on:{"updateDialogDelete":_vm.updateDialogDelete,"deleteItemConfirm":_vm.deleteItemConfirm}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }