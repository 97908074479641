<template>
  <div>
    <v-dialog v-model="open" max-width="900">
      <v-card>
        <v-card-title class="headline">{{ getTitle() }}</v-card-title>

        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="list"
            :options="{
              descending: true,
              itemsPerPage: 15,
            }"
            :footer-props="{
              itemsPerPageOptions: [5, 10, 15, 50, -1],
            }"
          >
            <template v-slot:item="props">
              <tr>
                <td>{{ getAsoc(props.item).creation_date }}</td>
                <td>{{ getAsoc(props.item).cbte_tipo_name }}</td>
                <td>{{ getAsoc(props.item).voucher_number != null ? getAsoc(props.item).voucher_number.toString().padStart(8, "0") : "-" }}</td>
                <td>{{ getEmitter(props.item) }}</td>
                <td>{{ getAsoc(props.item).receiver.name }}</td>
                <td>{{ getAsoc(props.item).mon_name }}</td>
                <td>{{ formatUtils.formatPrice(getAsoc(props.item).imp_total) }}</td>
              </tr>
            </template>
            <template v-slot:no-results>
              <v-alert :value="true" color="error" icon="warning">
                {{ "Busqueda sin resultados - " + search }}
              </v-alert>
            </template>
          </v-data-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="close()"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { formatUtils } from "@/libs/utils/formatUtils";

export default {
  components: {},
  props: {
    open: Boolean,
    cancel: Function,
    item: Object,
  },
  data: () => ({
    formatUtils: formatUtils,
    list: [],
    headers: [
      { text: "Fecha", align: "left", sortable: true, value: "creation_date" },
      { text: "Comprobante", align: "left", sortable: true, value: "cbte_tipo_name" },
      { text: "Nº Comprobante", align: "left", sortable: true, value: "voucher_number" },
      { text: "Emisor", align: "left", sortable: true, value: "emitter.name" },
      { text: "Receptor", align: "left", sortable: true, value: "receiver.name" },
      { text: "Moneda", align: "left", sortable: true, value: "mon_name" },
      { text: "Total", align: "left", sortable: true, value: "imp_total" },
    ],
  }),
  watch: {
    open: function (val) {
      if (val) {
        if (this.item.cbtes_asoc.length > 0) this.list = this.item.cbtes_asoc;
      }
    },
  },
  mounted() {},
  methods: {
    close() {
      this.$emit("cancel");
    },
    getAsoc(item) {
      if (item.voucher_asoc != undefined) return item.voucher_asoc;
      return item.spent_asoc;
    },
    getEmitter(item) {
      if (item.voucher_asoc != undefined) return item.voucher_asoc.emitter.name;
      return item.spent_asoc.supplier.name;
    },
    getTitle() {
      if (this.item != null) {
        const voucher = this.item.voucher_number != null ? this.item.voucher_number.toString().padStart(8, "0") : "-";
        return "Comprobantes asociados de " + this.item.voucher_number_label; //this.item.cbte_tipo_name + " - Nº " + this.item.pto_vta.toString().padStart(4,"0") + "-" + voucher;
      }
      return "";
    },
  },
};
</script>
