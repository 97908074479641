import { wsService } from "./wsService";
import sections from "@/constants/sections";

const section = sections.SECTION_SETTINGS;

function filter(param = { enable: true }) {
  return wsService.make_post_request(section + "/list", param);
}

function update(param = {}) {
  return wsService.make_post_request(section + "/update", param);
}

function testEmail(param = {}) {
  return wsService.make_post_request(section + "/test-email", param);
}

export const settingsService = {
  section,
  filter,
  update,
  testEmail
};
