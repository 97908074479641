<template>
  <div>
    <Title :pages="[{ icon: 'file-invoice', page: page_title.toUpperCase() }]"></Title>

    <v-container fluid>
      <v-card v-if="list_access || 1 == 1">
        <div class="row mb-2 mt-0">
          <div class="col-md-12">
            <div class="col-md-12" style="background:white; elevation-0">
              <div class="row pt-5">
                <div class="col-md-2 pt-0">
                  <v-menu
                    ref="desde_selected"
                    v-model="desde_selected_open"
                    :close-on-content-click="false"
                    :close-on-click="false"
                    :return-value.sync="desde_selected"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                    clearable
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="desde_selected" label="Desde" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" class="pt-0 mt-0"></v-text-field>
                    </template>
                    <v-date-picker v-model="desde_selected" type="date" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="desde_selected_open = false"> Cancel </v-btn>
                      <v-btn text color="primary" @click="$refs.desde_selected.save(desde_selected)"> OK </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>

                <div class="col-md-2 pt-0">
                  <v-menu
                    ref="hasta_selected"
                    v-model="hasta_selected_open"
                    :close-on-content-click="false"
                    :close-on-click="false"
                    :return-value.sync="hasta_selected"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                    clearable
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="hasta_selected" label="Hasta" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" class="pt-0 mt-0"></v-text-field>
                    </template>
                    <v-date-picker v-model="hasta_selected" type="date" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="hasta_selected = false"> Cancel </v-btn>
                      <v-btn text color="primary" @click="$refs.hasta_selected.save(hasta_selected)"> OK </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>

                <div class="col-md-3 pt-0">
                  <v-combobox v-model="type_selected" autocomplete="off" item-text="name" item-value="id" :items="types" label="Tipo" class="pt-0 mt-0" clearable></v-combobox>
                </div>

                <div class="col-md-3 pt-0">
                  <v-combobox v-model="emitter_selected" autocomplete="off" item-text="name" item-value="id" :items="emitters" label="Emisor/Receptor" class="pt-0 mt-0" clearable></v-combobox>
                </div>

                <div class="col-md-2 pt-0" style="display: flex; justify-content: end">
                  <v-btn color="info" class="" @click="filter">Buscar</v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>
      <v-card v-if="list_access || 1 == 1">
        <v-card-title>
          <div class="col-md-8 pb-0 pl-0">
            <v-btn color="blue-grey" class="mr-2" small dark :loading="dialog_export" @click="exportFile()"> Exportar <font-awesome-icon class="ml-2" icon="download" /></v-btn>

            <v-chip outlined color="primary"
              >Total Retenciones:&nbsp;<span>{{ formatUtils.formatPrice(total) }}</span></v-chip
            >
          </div>
          <div class="col-md-4 pb-0">
            <v-text-field v-model="search" append-icon="search" label="Buscar" single-line hide-details></v-text-field>
          </div>
        </v-card-title>

        <v-data-table
          :headers="headers"
          :items="list"
          :search="search"
          :loading="loading"
          loading-text="Cargando..."
          :options="{
            descending: true,
            itemsPerPage: 15,
          }"
          @pagination="updatePagination"
          :footer-props="{
            itemsPerPageOptions: [5, 10, 15, 50, -1],
          }"
        >
          <template v-slot:item="props">
            <tr>
              <td>{{ props.item.cbte_fch }}</td>
              <td>{{ props.item.nro_recibo }}</td>
              <td>{{ props.item.business_name }}</td>
              <td>{{ props.item.cuit }}</td>
              <td>{{ props.item.nro_cert }}</td>
              <td>{{ props.item.tributo.Desc }}</td>
              <td>{{ props.item.description }}</td>
              <td class="text-right">{{ formatUtils.formatPrice(props.item.amount) }}</td>
            </tr>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              {{ "Busqueda sin resultados - " + search }}
            </v-alert>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import Constant from "@/constants/sections";
import { reportService } from "@/libs/ws/reportService";
import { emmiterService } from "@/libs/ws/emmiterService";

import { formatUtils } from "@/libs/utils/formatUtils";
import GenericMixin from "@/mixins/GenericMixin.vue";
import Title from "@/components/Title.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import ExportMixin from "@/mixins/ExportMixin.vue";
import SettingsMixin from "@/mixins/SettingsMixin.vue";
export default {
  components: { Title },
  mixins: [GenericMixin, RolMixin, ExportMixin, SettingsMixin],
  data() {
    return {
      Constant: Constant,
      formatUtils: formatUtils,
      service: reportService,
      page_title: "Retenciones",
      modal_title: "Retenciones",
      headers: [
        {
          text: "Fecha",
          align: "left",
          sortable: true,
          value: "cbte_fch",
        },
        {
          text: "Recibo Nº",
          align: "left",
          sortable: true,
          value: "nro_recibo",
        },
        {
          text: "Razon Social",
          align: "left",
          sortable: true,
          value: "business_name",
        },
        {
          text: "CUIT",
          align: "left",
          sortable: true,
          value: "cuit",
        },
        { text: "Nº Certificado", value: "nro_cert" },
        { text: "Tributo", value: "tributo.Desc" },
        { text: "Descripcion", value: "description" },
        { text: "Total", value: "amount", align: "right" },
      ],
      list: [],
      desde_selected: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().slice(0, 10),
      hasta_selected: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().slice(0, 10),
      desde_selected_open: false,
      hasta_selected_open: false,
      type_selected: null,
      emitter_selected: null,
      emitters: [],
      types: [
        { id: 0, name: "Sufridas" },
        { id: 1, name: "Emitidas" },
      ],
      total: 0,
    };
  },
  mounted() {
    this.loadEmitters();
  },
  methods: {
    getFilterParams() {
      const param = {
        from: this.desde_selected,
        to: this.hasta_selected,
        type: this.type_selected != null ? this.type_selected.id : null,
        emitter: this.emitter_selected != null ? this.emitter_selected.id : null,
      };
      return param;
    },
    filter() {
      this.loadList(this.service, this.getFilterParams());
    },
    loadEmitters() {
      emmiterService.filter({ enabled: true }).then((response) => {
        this.emitters = response.result;
      });
    },
    getTotal() {
      this.total = 0;
      if (this.list.length > 0) {
        this.list.forEach((element) => {
          this.total = (parseFloat(this.total) + parseFloat(element.amount)).toFixed(2);
        });
      }
    },
    loadList(service, param = null) {
      if (param == null) {
        param = this.getFilterParams();
      }

      if (this.type_selected == null || !Object.prototype.hasOwnProperty.call(this.type_selected, "id")) {
        this.$toast.error("Debe seleccionar un tipo de retencion para realizar la busqueda.", "Error");
        return;
      }
      this.loading = true;

      this.open_filter = false;
      service
        .getRetentions(param)
        .then((response) => {
          this.list = response.result;
          this.loading = false;

          if (typeof this.selected !== "undefined") {
            this.selected = [];
          }
          this.getTotal();
        })
        .catch((e) => {
          console.log("service.filter error");
          console.log(e);
          this.total = 0;
          this.loading = false;
        });
    },
    exportFile() {
      var param = this.getFilterParams();
      param["export"] = true;
      this.exportExcel(Constant.SECTION_REPORT_RETENTIONS, param, "Retenciones.xlsx");
    },
  },
};
</script>
