import { wsService } from "./wsService";
import sections from "@/constants/sections";

const section = sections.SECTION_RECEIVERS;

function filter(param = { enable: true }) {
  return wsService.make_post_request(section + "/list", param);
}

function create(param = {}) {
  return wsService.make_post_request(section + "/create", param);
}

function update(param = {}) {
  return wsService.make_post_request(section + "/update", param);
}

function remove(param = {}) {
  return wsService.make_delete_request(section + "/delete", param);
}

function iibb_codes(param = {}) {
  return wsService.make_get_request(section + "/iibb_codes", param);
}

function query_padron(param = {}) {
  return wsService.make_post_request(section + "/query-padron", param);
}

export const receiversService = {
  section,
  filter,
  remove,
  create,
  update,
  iibb_codes,
  query_padron,
};
