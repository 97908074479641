import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { BNav } from "bootstrap-vue";

import {
  faBuilding,
  faDollar,
  faMoneyCheck,
  faTachometerAlt,
  faUserSecret,
  faDesktop,
  faRoad,
  faTruck,
  faUsers,
  faCog,
  faMapMarker,
  faChartBar,
  faRoute,
  faMapPin,
  faMapMarkedAlt,
  faTruckLoading,
  faDolly,
  faTruckMoving,
  faTag,
  faShieldAlt,
  faClipboardList,
  faFilter,
  faChevronDown,
  faChevronUp,
  faBan,
  faUserCog,
  faDownload,
  faReceipt,
  faCrosshairs,
  faFileExcel,
  faFileInvoice,
  faDollarSign,
  faPlaneDeparture,
  faFile,
  faUserTie,
  faList,
  faListCheck,
  faArrowsRotate,
  faPerson,
  faSignature,
  faUsersBetweenLines,
  faFileInvoiceDollar,
  faXmark,
  faPlus,
  faMagnifyingGlassMinus,
  faMagnifyingGlassPlus,
  faMaximize,
  faMinimize,
  faScroll,
  faFloppyDisk,
  faBolt,
  faInbox,
  faCircle,
  faCaretDown,
  faExchangeAlt,
  faTimes,
  faCashRegister,
  faUser,
  faCogs,
  faLandmark,
  faCheck,
  faRectangleList,
  faClock,
  faArchive,
  faMoneyBill,
  faShippingFast,
  faBoxesStacked,
  faWarehouse,
  faEnvelope,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faSearch,
  faWarehouse,
  faBoxesStacked,
  faShippingFast,
  faMoneyBill,
  faCheck,
  faFileInvoice,
  faLandmark,
  faUser,
  faCogs,
  faCashRegister,
  faTimes,
  faExchangeAlt,
  faCaretDown,
  faCircle,
  faMoneyCheck,
  faDollar,
  faTachometerAlt,
  faFileInvoiceDollar,
  faUsersBetweenLines,
  faSignature,
  faPerson,
  faListCheck,
  faArrowsRotate,
  faList,
  faUserTie,
  faFile,
  faPlaneDeparture,
  faDollarSign,
  faTruckMoving,
  faDownload,
  faTag,
  faShieldAlt,
  faClipboardList,
  faUserSecret,
  faDesktop,
  faRoad,
  faTruck,
  faUsers,
  faCog,
  faMapMarker,
  faChartBar,
  faRoute,
  faMapPin,
  faMapMarkedAlt,
  faTruckLoading,
  faDolly,
  faFilter,
  faChevronDown,
  faChevronUp,
  faBan,
  faUserCog,
  faReceipt,
  faCrosshairs,
  faFileExcel,
  faXmark,
  faPlus,
  faMagnifyingGlassMinus,
  faMagnifyingGlassPlus,
  faMaximize,
  faMinimize,
  faScroll,
  faFloppyDisk,
  faBolt,
  faInbox,
  faRectangleList,
  faClock,
  faArchive,
  faBuilding,
  faEnvelope
);

export default FontAwesomeIcon;
