import { wsService } from "./wsService";
import sections from "@/constants/sections";

const section = sections.SECTION_SEND_EMAIL;

function create(params) {
  return wsService.make_post_request(section + "/create", params);
}

export const sendEmailService = {
  create,
};
