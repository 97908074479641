var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Title',{attrs:{"pages":[
      { icon: 'rectangle-list', page: _vm.page_title.toUpperCase() },
      { icon: 'circle', page: _vm.getName() },
    ]}}),_c('v-container',{attrs:{"fluid":""}},[(_vm.update_access)?_c('v-btn',{staticClass:"mb-5",attrs:{"color":"primary"},on:{"click":function($event){_vm.dialog_bulk_update = true}}},[_vm._v("Aumento masivo de precios")]):_vm._e(),(_vm.list_access)?_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-uppercase"},[_vm._v(_vm._s(_vm.getName()))]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"search","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.list,"search":_vm.search,"loading":_vm.loading,"loading-text":"Cargando...","options":{
          descending: true,
          itemsPerPage: 15,
        },"footer-props":{
          itemsPerPageOptions: [5, 10, 15, 50, -1],
        }},on:{"pagination":_vm.updatePagination},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('tr',[_c('td',[_vm._v(_vm._s(item.label))]),_c('td',[_vm._v(_vm._s(item.category.name))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.formatUtils.formatPrice(item.price)))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s((item.discount_per != null)?item.discount_per+"%":"-"))]),_c('td',{staticClass:"text-center px-0"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [(_vm.update_access)?_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":"","color":"blue"},on:{"click":function($event){return _vm.editItem(item)}}},on),[_vm._v(" edit ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Editar")])])],1)])]}},{key:"no-results",fn:function(){return [_c('v-alert',{attrs:{"value":true,"color":"error","icon":"warning"}},[_vm._v(" "+_vm._s("Busqueda sin resultados - " + _vm.search)+" ")])]},proxy:true}],null,false,1367007852)})],1):_vm._e(),_c('ModalBulkUpdate',{attrs:{"show":_vm.dialog_bulk_update,"seleted":_vm.lista_de_precios},on:{"close":function($event){_vm.dialog_bulk_update = false},"refresh":function($event){return _vm.loadList(_vm.service)}}}),(_vm.update_access)?_c('ModalProductPrice',{attrs:{"modal_title":_vm.modal_title,"formBtn":_vm.formBtn,"dialog":_vm.dialog,"service":_vm.service,"item":_vm.mainItem},on:{"updateDialog":_vm.updateDialog,"loadList":function($event){return _vm.loadList(_vm.service)},"updateItem":(newItem) => {
          _vm.mainItem = newItem;
        }}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }