import { wsService } from "./wsService";

var section = "";

function query(param = {}, path = section) {
  return wsService.make_post_request(path, param);
}

function filter(param = {}, path = section) {
  return wsService.make_post_request(path + "/list", param);
}

function get(id, path = section) {
  return wsService.make_get_request(path + "/get/" + id);
}

function create(param = {}, path = section) {
  return wsService.make_post_request(path + "/create", param);
}

function update(param = {}, path = section) {
  return wsService.make_put_request(path + "/update", param);
}

function update_wfile(param = {}, path = section) {
  return wsService.make_post_request(path + "/update", param);
}

function remove(param = {}, path = section) {
  return wsService.make_delete_request(path + "/delete", param);
}

function setSection(s) {
  section = s;
}

function downloadPdf(response, filename, open = false) {
  if (Object.prototype.toString.call(response) != "[object ArrayBuffer]" && !response.status) {
    return false;
  }

  let blob = new Blob([response], { type: "application/pdf" });
  if (open) {
    let blobURL = URL.createObjectURL(blob);
    window.open(blobURL);
  } else {
    let link = document.createElement("a");
    link.download = filename;
    link.href = window.URL.createObjectURL(blob);
    link.target = "_blank";
    link.click();
  }

  return true;
}

function export_filter(params) {
  return wsService.make_post_request(section + "/export", params, {
    responseType: "arraybuffer",
    headers: {
      Accept: "application/vnd.ms-excel",
    },
  });
}

export const generalService = {
  section,
  setSection,
  query,
  filter,
  get,
  remove,
  create,
  update,
  update_wfile,
  export_filter,
  downloadPdf,
};
