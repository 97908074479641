<template>
  <v-card elevation="1" class="mb-4">
    <ModalCheque
      :showCreateButton="false"
      modal_title="Crear Cheque"
      formBtn="Crear"
      :dialog="dialogCheque"
      :service="generalService"
      @loadList="loadChecks()"
      :item="null"
      @callbackItem="
        (newItem) => {
          itemPayment.cheque = newItem;
          itemPayment.fch_payment_efective = newItem.payment_date;
          itemPayment.amount = newItem.amount;
        }
      "
      @updateDialog="
        (value) => {
          dialogCheque = value;
        }
      "
    />

    <v-card-text>
      <h6 class="text-h6 black--text">Forma de pago</h6>
      <p class="text-body-2">Ingrese la forma de pago</p>
      <v-row class="mt-0">
        <div class="col-md-3">
          <v-combobox
            v-model="itemPayment.payment_method"
            data-vv-as="Forma de pago"
            name="payment_method"
            item-text="name"
            item-value="id"
            :items="payment_methods"
            label="Forma de pago*"
            autocomplete="off"
            v-validate="'required'"
            :disabled="isViewModal"
          ></v-combobox>
          <span class="red--text">{{ errors.first("payment_method") }}</span>
        </div>
        <div class="col-md-6" v-if="itemPayment.payment_method != null && itemPayment.payment_method.id == paymentMethod.cheque">
          <div class="row">
            <div class="col" style="min-width: 200px; max-width: 200px">
              <v-checkbox label="¿Es cheque propio?" :disabled="isViewModal" v-model="check_is_own"></v-checkbox>
            </div>
            <div class="col">
              <v-combobox
                :disabled="isViewModal"
                v-model="itemPayment.cheque"
                data-vv-as="Cheque"
                class="float-left"
                name="check"
                item-text="name"
                item-value="id"
                :items="checks_filter"
                :loading="loading_checks"
                label="Cheques en cartera"
                autocomplete="off"
                v-validate="'required'"
                @change="selectedCheque()"
              ></v-combobox>

              <v-tooltip bottom v-if="!isViewModal">
                <template v-slot:activator="{ on }">
                  <v-btn class="mt-2 ml-3" v-on="on" outlined small fab dark color="blue-grey" @click="openModalCheque()">
                    <v-icon dark> mdi-plus </v-icon>
                  </v-btn>
                </template>
                <span>Nuevo Cheque</span>
              </v-tooltip>
            </div>
          </div>
          <span class="red--text">{{ errors.first("check") }}</span>
        </div>

        <div :class="itemPayment.payment_method.deferred && itemPayment.payment_method.id != paymentMethod.cheque ? 'col-md-3' : 'col'" v-if="itemPayment.payment_method != null">
          <v-text-field
            autocomplete="off"
            name="importe"
            label="Importe*"
            :disabled="isViewModal || (itemPayment.payment_method != null && itemPayment.payment_method.id == paymentMethod.cheque)"
            placeholder=""
            v-validate="'decimal'"
            type="number"
            v-model="itemPayment.amount"
          />
          <span class="red--text">{{ errors.first("importe") }}</span>
        </div>

        <div class="col-md-3" v-if="itemPayment.payment_method != null && itemPayment.payment_method.id != paymentMethod.cheque">
          <v-combobox
            :disabled="isViewModal"
            v-model="itemPayment.money_account"
            data-vv-as="Cuenta monetaria"
            name="money_account"
            item-text="name"
            item-value="id"
            :items="money_accounts"
            label="Cuenta monetaria*"
            autocomplete="off"
            v-validate="'required'"
          ></v-combobox>
          <span class="red--text">{{ errors.first("money_account") }}</span>
        </div>

        <div class="col-md-3" v-if="itemPayment.payment_method != null && itemPayment.payment_method.deferred && itemPayment.payment_method.id != paymentMethod.cheque">
          <DatePickerEsp
            :initToday="true"
            label="Fecha de pago efectivo"
            :date="itemPayment.fch_payment_efective"
            :isRequired="true"
            name="fch_payment_efective"
            :isViewModal="isViewModal"
            @setDate="
              (date) => {
                itemPayment.fch_payment_efective = date;
              }
            "
          />
        </div>
        <div :class="itemPayment.payment_method.deferred && itemPayment.payment_method.id != paymentMethod.cheque ? 'col-md-3' : 'col'" v-if="itemPayment.payment_method != null">
          <v-text-field autocomplete="off" name="reference" label="Referencia" :disabled="isViewModal" placeholder="" v-model="itemPayment.reference" />
        </div>
      </v-row>

      <v-row class="mt-0">
        <v-col md="12" v-if="!isViewModal">
          <v-btn small color="blue-grey" class="text-white" @click="addTributeDetail()">
            <v-icon big color="green"> add </v-icon>
            <span>Agregar item</span>
          </v-btn>
        </v-col>
        <v-col md="12">
          <v-data-table class="pb-4" :headers="header_tribute" :items="item.spent_payment_methods" style="border: solid 1px #1c1c1c" :hide-default-footer="true" :items-per-page="100">
            <template v-slot:item="props">
              <tr>
                <td>
                  {{ props.item.payment_method.name }}
                </td>
                <td>
                  {{ props.item.money_account != null ? props.item.money_account.name : "-" }}
                </td>
                <td>
                  {{ formatUtils.formatPrice(props.item.amount) }}
                </td>
                <td>
                  {{ props.item.fch_payment_efective == null ? "N/A" : props.item.fch_payment_efective + " (" + dateDiff(item.cbte_fch, props.item.fch_payment_efective) + ")" }}
                </td>
                <td v-if="props.item.cheque != null && props.item.cheque.is_own && props.item.payment_method.id == paymentMethod.cheque">
                  <p class="mb-1"><b class="caption">Emisor:</b> {{ props.item.cheque.emitter.name }}</p>
                  <p class="mb-1"><b class="caption">Nro Cheque:</b> {{ props.item.cheque.number }}</p>
                  <p class="mb-1"><b class="caption" v-if="props.item.reference != null">Ref:</b> {{ props.item.reference }}</p>
                </td>
                <td v-if="props.item.cheque != null && !props.item.cheque.is_own && props.item.payment_method.id == paymentMethod.cheque">
                  <p class="mb-1"><b class="caption">Emisor:</b> {{ props.item.cheque.emitter_name }}</p>
                  <p class="mb-1"><b class="caption">Banco:</b> {{ props.item.cheque.bank.name }}</p>
                  <p class="mb-1"><b class="caption">Nro Cheque:</b> {{ props.item.cheque.number }}</p>
                  <p class="mb-1"><b class="caption" v-if="props.item.reference != null">Ref:</b> {{ props.item.reference }}</p>
                </td>
                <td v-if="props.item.payment_method.id != paymentMethod.cheque">
                  {{ props.item.reference }}
                </td>

                <td class="text-center">
                  <v-tooltip bottom v-if="!isViewModal">
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" small color="red" @click="removeDetail(props.item, 'spent_payment_methods')"> indeterminate_check_box </v-icon>
                    </template>
                    <span>Remover</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
        <v-col md="12">
          <div v-for="(item, index) in total_formas_pago" :key="index">
            <v-chip v-if="item.amount > 0" class="float-right ml-2" outlined color="primary">Total {{ item.name }}: {{ formatUtils.formatPrice(item.amount) }} </v-chip>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { formatUtils } from "@/libs/utils/formatUtils";
import Constant from "@/constants/sections";
import { generalService } from "@/libs/ws/generalService";
import DatePickerEsp from "@/components/DatePickerEsp.vue";
import paymentMethod from "@/constants/paymentMethod";
import { emmiterService } from "@/libs/ws/emmiterService";
import ChequesState from "@/constants/chequesStates";
import ModalCheque from "@/views/Cheques/Modal.vue";

export default {
  components: { DatePickerEsp, ModalCheque },
  props: { item: Object, isViewModal: Boolean, validator: Object },
  created() {
    //this.$validator = this.validator;
  },
  data: () => ({
    generalService: generalService,
    dialogCheque: false,
    header_tribute: [
      { text: "Forma de Pago" },
      { text: "Cuenta Monetaria" },
      { text: "Importe" },
      { text: "Fecha de pago efectiva" },
      { text: "Información adicional" },
      { text: "", value: "", sortable: false, class: "bg-grey" },
    ],
    formatUtils: formatUtils,
    payment_methods: [],
    money_accounts: [],
    emitters: [],
    check_is_own: false,
    checks: [],
    loading_checks: false,
    paymentMethod: paymentMethod,
    spent_selected: null,
    defaultItemPayment: null,
    total_formas_pago: [],
    itemPayment: {
      id: 0,
      money_account: null,
      payment_method: null,
      fch_payment_efective: null,
      amount: 0,
      cheque: null,
      reference: null,
    },
  }),
  watch: {
    check_is_own: function (newValue) {
      this.itemPayment.cheque = null;
      this.itemPayment.amount = 0;
      this.loadChecks();
    },
    item: {
      handler: function (newValue) {
        if (this.total_formas_pago.length > 0) {
          this.getTotal();
        }
        if ((this.spent_selected == null && newValue.spent != null) || (newValue.spent != null && this.spent_selected.id != newValue.spent.id)) {
          this.spent_selected = newValue.spent;
        }
      },
      deep: true,
    },
    itemPayment: {
      handler: function (newValue) {
        if (newValue.payment_method != null && newValue.payment_method.id != paymentMethod.cheque) {
          this.loadChecks();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.loadComboData();
    this.loadMonetaryAccounts();
    this.loadChecks();
    this.defaultItemPayment = JSON.parse(JSON.stringify(this.itemPayment));
  },
  computed: {
    checks_filter: {
      //en la lista de checks muestro solo los cheques que no estan en el item.spent_payment_methods
      get() {
        var checks_filter = [];
        if (this.checks != null && this.item != null && this.item.spent_payment_methods != null) {
          checks_filter = this.checks.filter((check) => {
            var index = this.item.spent_payment_methods.findIndex((item) => item.cheque != null && item.cheque.id == check.id);
            return index == -1;
          });
        }
        return checks_filter;
      },
    },
  },
  methods: {
    getTotal() {
      this.total_formas_pago.forEach((item) => {
        item.amount = 0;
      });

      this.item.spent_payment_methods.forEach((c_payment_methos) => {
        const amount = c_payment_methos.amount != undefined ? c_payment_methos.amount : 0;
        var index = this.total_formas_pago.findIndex((item) => item.id == c_payment_methos.payment_method.id);
        if (index !== -1) {
          this.total_formas_pago[index].amount += parseFloat(amount);
        }
      });
    },
    addTributeDetail() {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.item.spent_payment_methods.push(this.itemPayment);
          this.itemPayment = JSON.parse(JSON.stringify(this.defaultItemPayment));
          this.$validator.reset();
        }
      });
    },
    removeDetail(item, colection_name) {
      if (colection_name == "spent_payment_methods") {
        const index_item = this.item.spent_payment_methods.indexOf(item);
        if (index_item > -1) this.item.spent_payment_methods.splice(index_item, 1);
      }
    },
    changeIsOwnCheque(e) {
      if (!e) {
        this.itemPayment.cheque.emitter = null;
      } else {
        this.itemPayment.cheque.emitter_name = null;
        this.itemPayment.cheque.bank = null;
      }
    },
    loadChecks() {
      this.loading_checks = true;
      var param = { is_own: this.check_is_own, state: ChequesState.STATE_EN_CARTERA };
      generalService.setSection(Constant.SECTION_CHECKS);
      generalService
        .filter(param)
        .then((response) => {
          this.checks = response.result;
          this.loading_checks = false;
        })
        .catch((e) => {
          console.log("checks.filter error");
          console.log(e);
          this.loading_checks = false;
        });
    },
    loadMonetaryAccounts() {
      var param = {};
      generalService.setSection(Constant.SECTION_MONEY_ACCOUNTS);
      generalService
        .filter(param)
        .then((response) => {
          this.money_accounts = response.result;
          this.loading = false;
        })
        .catch((e) => {
          console.log("money_accounts.filter error");
          console.log(e);
          this.loading = false;
        });
    },
    selectedCheque() {
      this.itemPayment.amount = this.itemPayment.cheque.amount;
      this.itemPayment.fch_payment_efective = this.itemPayment.cheque.payment_date;

      if (this.itemPayment.cheque.is_own && this.itemPayment.cheque.money_account != null) {
        this.itemPayment.money_account = this.itemPayment.cheque.money_account;
      }
    },
    dateDiff(date1, date2) {
      if (date1 == null || date2 == null) return "";
      // Crear objetos Date a partir de las fechas proporcionadas
      var fechaInicio = new Date(date1);
      var fechaFin = new Date(date2);

      // Calcular la diferencia en milisegundos
      var diferenciaMilisegundos = fechaFin - fechaInicio;

      // Convertir la diferencia de milisegundos a días
      var milisegundosPorDia = 1000 * 60 * 60 * 24;
      var diffDays = diferenciaMilisegundos / milisegundosPorDia;

      if (diffDays > 1) {
        return diffDays + " días";
      } else {
        return diffDays + " día";
      }
    },
    loadComboData() {
      generalService.setSection(Constant.SECTION_PAYMENT_METHODS);
      generalService
        .filter()
        .then((response) => {
          this.payment_methods = response.result;
          this.total_formas_pago = [];
          response.result.forEach((element) => {
            this.total_formas_pago.push({ id: element.id, name: element.name, amount: 0 });
          });
          this.getTotal();
          this.loading = false;
        })
        .catch((e) => {
          console.log("payment_methods.filter error");
          console.log(e);
          this.loading = false;
        });

      emmiterService.filter({ enabled: true }).then((response) => {
        this.emitters = response.result;
      });
    },
    openModalCheque() {
      this.dialogCheque = true;
    },
  },
};
</script>
