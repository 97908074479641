<script>
// definir un objeto mixin
export default {
  data() {
    return {
      loading: false,
    };
  },
  created: function () {},
  computed: {},
  mounted() {},
  methods: {
    parseErrorResultValidation(result) {
      if (!result.status) {
        var mensaje = "";
        for (const field in result.result) {
          if (Object.prototype.hasOwnProperty.call(result.result, field)) {
            // Mostrar el campo y los errores de validación asociados
            mensaje += `Errores de validación para el campo "${field}":\n`;
            /*
            const validationErrors = result[field];
            validationErrors.forEach((error) => {
              console.log(`- ${error}`);
            });
            */
          }
        }

        return mensaje;
      } else {
        return result.msg;
      }
    },
  },
};
</script>
