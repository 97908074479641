<template>
  <div>
    <h6 class="text-h6 black--text">Presentaciones</h6>
    <p class="text-body-2">Ingrese las presentaciones de los productos</p>

    <v-row>
      <v-col md="12" v-if="!isViewModal">
        <v-btn small color="blue-grey" class="pl-4 text-white" @click="newPresentation">
          <v-icon big color="green"> add </v-icon>
          <span>Agregar presentacion</span>
        </v-btn>
      </v-col>

      <v-col md="12" class="px-4 pb-4">
        <v-data-table :headers="header_custome" :items="product.presentations" style="border: solid 1px #1c1c1c" :hide-default-footer="true" :items-per-page="100">
          <template v-slot:item="props">
            <tr>
              <td>
                {{ props.item.name }}
              </td>
              <td>
                {{ props.item.barcode }}
              </td>
              <td>
                {{ props.item.presentation_type != null ? props.item.presentation_type.name : "" }}
              </td>
              <td>
                {{ props.item.composite_presentation != null ? props.item.composite_presentation.name : "" }}
              </td>
              <td>
                {{ props.item.amount_composite_presentation }}
              </td>
              <td>
                {{ props.item.storage_unit != null ? props.item.storage_unit.name : "" }}
              </td>

              <td>
                {{ props.item.weight != null ? props.item.weight + (props.item.weight_unit != null ? props.item.weight_unit.name : "") : "" }}
              </td>

              <td>
                {{
                  props.item.heigth != null && props.item.heigth != ""
                    ? props.item.heigth + "x" + props.item.width + "x" + props.item.length + (props.item.measure_unit != null ? props.item.measure_unit.name : "")
                    : ""
                }}
              </td>
              <td><span v-if="props.item.cost > 0">$</span>{{ props.item.cost }}</td>
              <td><span v-if="props.item.base_price > 0">$</span>{{ props.item.base_price }}</td>

              <td>
                <div class="d-flex justify-content-center align-items-center">
                <v-checkbox label="" :disabled="true" v-model="props.item.sellable"></v-checkbox>
                </div>
              </td>

              <td>
                <div class="d-flex justify-content-center align-items-center">
                <v-checkbox label="" :disabled="true" v-model="props.item.buyable"></v-checkbox>
                </div>
              </td>

              <td class="text-center" style="width: 80px">
                <v-tooltip bottom v-if="!isViewModal">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="blue" class="mr-2" @click="editPresentation(props.item)"> edit </v-icon>
                  </template>
                  <span>Editar</span>
                </v-tooltip>
                <v-tooltip bottom v-if="!isViewModal">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="red" @click="removePresentation(props.item, 'custom')"> indeterminate_check_box </v-icon>
                  </template>
                  <span>Remover</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!-- START FORM DIALOG -->
    <ModalPresentacion :dialog="dialog_presentacion" :itemEdit="itemEdit" :product="product" @updateDialog="dialog_presentacion = false" @addPresentation="addPresentation" />
  </div>
</template>

<script>
import Vue from "vue";
import ModalPresentacion from "./ModalPresentacion.vue";
export default {
  created() {
    this.$validator = this.validator;
  },
  components: { ModalPresentacion },
  props: {
    open: Boolean,
    product: Object,
    validator: Object,
    isViewModal: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    header_custome: [
      { text: "Nombre", value: "name", sortable: true },
      { text: "Codigo de barras", value: "barcode", sortable: false },
      { text: "Tipo", value: "price", sortable: false },
      { text: "Composicion", value: "product", sortable: false },
      { text: "Cant. de unidades", value: "quantity", sortable: false },
      { text: "U. almacenamiento", value: "storage_unit", sortable: false },
      { text: "Peso", value: "weight", sortable: false },
      { text: "Medidas", value: "", sortable: false },
      { text: "Costo interno s/IVA", value: "cost", sortable: false },
      { text: "Precio unitario s/IVA", value: "base_price", sortable: false },
      { text: "Visible en modulo ventas", value: "", sortable: false, align: "center" },
      { text: "Visible en modulo compras", value: "", sortable: false, align: "center" },
      { text: "Accion", value: "", sortable: false },
    ],
    ivas: [],
    dialog_presentacion: false,
    itemEdit: null,
    itemEditIndex: null,
  }),
  watch: {
    open: function (val) {
      if (val) {
        this.errors.clear();
        this.$validator.reset();
      }
    },
    product: {
      handler: function (newValue) {},
      deep: true,
    },
  },
  mounted() {},
  methods: {
    newPresentation() {
      this.itemEdit = null;
      this.itemEditIndex = null;
      this.dialog_presentacion = true;
    },
    editPresentation(item) {
      this.itemEditIndex = this.product.presentations.indexOf(item);
      this.itemEdit = Object.assign({}, item);
      this.dialog_presentacion = true;
    },
    addPresentation(item) {
      if (this.itemEditIndex == null) {
        this.product.presentations.push(item);
      } else {
        //para que detecte el cambio
        Vue.set(this.product.presentations, this.itemEditIndex, item);
      }
      this.dialog_presentacion = false;
    },
    removePresentation(item, colection_name) {
      if (colection_name == "custom") {
        const index_item = this.product.presentations.indexOf(item);
        if (index_item > -1) this.product.presentations.splice(index_item, 1);
      }
    },
  },
};
</script>
