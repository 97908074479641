<template>
  <div>
    <Title :pages="[{ icon: 'circle', page: page_title.toUpperCase() }]"></Title>

    <v-container fluid>
      <ModalProduct
        v-if="create_access && isProductCompany()"
        :button_name="$t('Crear') + ' ' + modal_title"
        :modal_title="modal_title"
        :formBtn="formBtn"
        :dialog="dialog"
        :parent="parent"
        :service="service"
        @updateDialog="updateDialog"
        @loadList="loadList(service)"
        :item="mainItem"
        @updateItem="
          (newItem) => {
            mainItem = newItem;
          }
        "
      />

      <ModalService
        v-if="create_access && !isProductCompany()"
        :button_name="$t('Crear') + ' ' + modal_title"
        :modal_title="modal_title"
        :formBtn="formBtn"
        :dialog="dialog"
        :parent="parent"
        :service="service"
        @updateDialog="updateDialog"
        @loadList="loadList(service)"
        :item="mainItem"
        @updateItem="
          (newItem) => {
            mainItem = newItem;
          }
        "
      />

      <v-card>
        <div class="row mb-5 mt-0">
          <div class="col-md-12" v-if="list_access">
            <div class="col-md-12" style="background:white; elevation-0">
              <div class="row pt-4">
                <div class="col-md-4 pt-4 d-flex align-items-center">
                  <v-radio-group v-model="selectedStatus" row class="mt-0 mb-0">
                    <v-radio label="Habilitado" :value="true"></v-radio>
                    <v-radio label="No habilitado" :value="false"></v-radio>
                    <v-radio label="Todos" value="all"></v-radio>
                  </v-radio-group>
                </div>
                <div class="col-md-3 pt-0">
                  <v-combobox
                    v-model="selectedCategory"
                    data-vv-as="Rubro"
                    name="category"
                    item-text="name"
                    item-value="id"
                    :items="categories"
                    label="Rubro"
                    autocomplete="off"
                    @change="loadSubCategories()"
                  ></v-combobox>
                </div>
                <div class="col-md-3 pt-0">
                  <v-combobox
                    v-model="selectedSubCategory"
                    data-vv-as="Subrubro"
                    name="subcategory"
                    item-text="name"
                    item-value="id"
                    :items="subcategories"
                    label="Subrubro"
                    autocomplete="off"
                  ></v-combobox>
                </div>
                <div class="col-md-2 pt-4" style="display: flex; justify-content: end">
                  <v-btn color="info" class="" @click="filter">Buscar</v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>

      <v-card v-if="list_access">
        <v-card-title>
          <span class="text-uppercase">{{ page_title }}</span>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="search" label="Buscar" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="list"
          :search="search"
          :loading="loading"
          loading-text="Cargando..."
          :options="{
            descending: true,
            itemsPerPage: 15,
          }"
          @pagination="updatePagination"
          :footer-props="{
            itemsPerPageOptions: [5, 10, 15, 50, -1],
          }"
        >
          <template v-slot:item="props">
            <tr>
              <td>{{ props.item.name }}</td>
              <td>{{ props.item.sku }}</td>
              <td>{{ props.item.category.name }}</td>
              <td>{{ props.item.subcategory != null ? props.item.subcategory.name : "" }}</td>
              <td class="text-center">{{ props.item.enabled ? "SI" : "NO" }}</td>
              <td class="justify-content-center d-flex">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-if="update_access" v-on="on" small color="blue" class="mr-2" @click="editItem(props.item)"> edit </v-icon>
                  </template>
                  <span>Editar</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="blue" class="mr-2" @click="viewItem(props.item)"> remove_red_eye </v-icon>
                  </template>
                  <span>Ver</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-if="delete_access" v-on="on" small color="red" class="mr-2" @click="deleteItem(props.item)"> delete </v-icon>
                  </template>
                  <span>Eliminar</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              {{ "Busqueda sin resultados - " + search }}
            </v-alert>
          </template>
        </v-data-table>
      </v-card>

      <DialogDelete :dialog_delete="dialog_delete" @updateDialogDelete="updateDialogDelete" @deleteItemConfirm="deleteItemConfirm" />
    </v-container>
  </div>
</template>

<script>
import Constant from "@/constants/sections";
import ConstantSettings from "@/constants/settings";
import { generalService } from "@/libs/ws/generalService";
import { formatUtils } from "@/libs/utils/formatUtils";
import GenericMixin from "@/mixins/GenericMixin.vue";
import Title from "@/components/Title.vue";
import DialogDelete from "@/components/DialogDelete.vue";
import ModalProduct from "./ModalProduct.vue";
import ModalService from "./ModalService.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import SettingsMixin from "@/mixins/SettingsMixin.vue";
import ConstantSections from "@/constants/sections";

export default {
  components: { Title, ModalService, ModalProduct, DialogDelete },
  mixins: [GenericMixin, RolMixin, SettingsMixin],
  data() {
    return {
      formatUtils: formatUtils,
      parent: this,
      service: generalService,
      ConstantSettings: ConstantSettings,
      page_title: "Productos",
      modal_title: "Producto",
      selectedStatus: true,
      selectedCategory: null,
      selectedSubCategory: null,
      categories: [],
      subcategories: [],

      mainItem: {
        id: "",
        name: "",
        description: "",
        category: null,
        presentations: [],
        enabled: true,
      },
      defaultItem: null,
      headers: [
        {
          text: "Nombre",
          align: "left",
          sortable: true,
          value: "name",
        },
        {
          text: "Codigo (SKU)",
          align: "left",
          sortable: true,
          value: "sku",
        },
        {
          text: "Rubro",
          align: "left",
          sortable: true,
          value: "category.name",
        },
        {
          text: "Subrubro",
          align: "left",
          sortable: true,
          value: "subcategory.name",
        },
        {
          text: "Habilitado",
          align: "center",
          sortable: true,
          value: "enabled",
        },
        { text: "Accion", value: "", align: "center" },
      ],
      list: [],
      dialog_delete: false,
    };
  },
  mounted() {
    this.defaultItem = JSON.parse(JSON.stringify(this.mainItem));
    this.service.setSection(Constant.SECTION_PRODUCTS);
    this.loadList(this.service);
    this.loadCategories();
  },
  methods: {
    deleteItemConfirm() {
      this.delete(this.service);
      this.dialog_delete = false;
      this.loadList(this.service);
    },
    loadSubCategories(v) {
      generalService.filter({ category: this.selectedCategory.id }, "subcategories").then((response) => {
        this.subcategories = response.result;
      });
    },
    loadCategories() {
      generalService.filter({}, ConstantSections.SECTION_CATEGORIES).then((response) => {
        this.categories = response.result;
      });
    },
    filter() {
      this.loadList(this.service, this.getFilterParams());
    },
    getFilterParams() {
      const param = {
        enabled: this.selectedStatus,
        category: this.selectedCategory != null ? this.selectedCategory.id : null,
        subcategory: this.selectedSubCategory != null ? this.selectedSubCategory.id : null,
      };
      return param;
    },
  },
};

// TODO agregar un boton de limpiar busqueda.
</script>
