<template>
  <div>
    <Title :pages="[{ icon: 'tachometer-alt', page: page_title.toUpperCase() }]"></Title>

    <v-container fluid>
      <div class="content-main">
        <v-tabs v-model="tab">
          <v-tab v-for="item in items" :key="item.key">
            {{ item.tab }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item key="0">
            <EstadoEconomico></EstadoEconomico>
          </v-tab-item>
          <v-tab-item key="1">
            <EstadoFinanciero></EstadoFinanciero>
          </v-tab-item>
          <v-tab-item key="2">
            <EstadoImpositivo></EstadoImpositivo>
          </v-tab-item>
          <v-tab-item key="3">
            <Historial></Historial>
          </v-tab-item>          
        </v-tabs-items>
      </div>
    </v-container>
  </div>
</template>

<script>
import EstadoEconomico from "./EstadoEconomico.vue";
import EstadoFinanciero from "./EstadoFinanciero.vue";
import Historial from "./Historial.vue";
import EstadoImpositivo from "./EstadoImpositivo";
import Title from "@/components/Title.vue";
export default {
  mixins: [],
  components: { Title, EstadoFinanciero, EstadoEconomico, Historial, EstadoImpositivo },
  data() {
    return {
      page_title: "Metricas",
      mainItem: {
        id: 0,
        name: "",
      },
      defaultItem: {
        id: 0,
        name: "",
      },
      tab: 0,
      list: [],
      items: [
        { tab: "Estado Economico", key: 0 },
        { tab: "Estado Financiero", key: 1 },
        { tab: "Estado Impositivo", key: 2 },        
        { tab: "Historial", key: 3 },
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>
