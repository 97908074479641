<template>
  <v-layout>
    <v-dialog v-bind:value="dialog" @click:outside="close" max-width="750px">
      <v-card>
        <v-form v-on:submit.prevent="createItem">
          <v-card-title>
            <span class="headline">{{ modal_title }}</span>
          </v-card-title>

          <v-card-text>
            <!-- START FORM DIALOG -->
            <div class="row">
              <div class="col-md-12">
                <v-text-field
                    :disabled="isViewModal()"
                    autocomplete="off"
                    name="initial_balance"
                    placeholder="Saldo inicial*"
                    dense
                    v-validate="'required|decimal'"
                    type="number"
                    v-model="initial_balance"
                    class="mt-3"
                  />
                  <span class="red--text">{{ errors.first("initial_balance") }}</span>
              </div>
            </div>
          </v-card-text>          

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="close">Cerrar</v-btn>
            <v-btn color="success" text type="submit" v-if="!isViewModal()" :loading="loading">{{ formBtn }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import ModalGenericMixin from "@/mixins/ModalGenericMixin.vue";
import { generalService } from "@/libs/ws/generalService";

export default {
  mixins: [ModalGenericMixin],
  props: {
    parent: Object,    
    modal_title: String,
    dialog: Boolean,
    item: Object,
    button_name: String,
    formBtn: String,
    section: String,
    closeModal: Function,
  },
  data() {
    return {
      show: false,            
      initial_balance: 0                  
    };
  },
  watch: {
    item() {
      this.$emit("updateItem", this.item);
    },
    dialog() {
      this.errors.clear();
    },
  },
  mounted() {
    
  },
  methods: {    
    isViewModal() {
      return this.formBtn == "";
    },
    close(){
      this.$emit("updateDialog", false);
      this.$emit("closeModal");
    },
    createItem(e) {
      this.$validator.validate().then((valid) => {
        if (valid) {
          e.preventDefault();  
          this.loading = true;

          var data = {
            id: this.item.id,            
            initial_balance: this.initial_balance,
          }          
          generalService
              .query(data,this.section + "/set_initial_balance/" + this.item.id)
              .then((response) => {
                if (response.status == true) {
                  this.$toast.success("El balance inicial se modifico correctamente.", "OK");
                  this.close();
                  this.$emit("loadList");
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    this.$toast.error(response.msg, "Error");
                  }
                }
              })
              .catch((response) => {
                this.$toast.error(response.msg, "Error");
              })
              .finally(() => this.loading = false);
        }
      });
    },
  },
};
</script>
