<template>
  <div>
    <Title :pages="[{ icon: 'file-invoice', page: page_title.toUpperCase() }]"></Title>

    <v-container fluid>
      <v-card v-if="list_access || 1 == 1">
        <div class="row mb-2 mt-0">
          <div class="col-md-12">
            <div class="col-md-12" style="background:white; elevation-0">
              <div class="row pt-5">
                <div class="col-md-2 pt-0">
                  <v-menu
                    ref="desde_selected"
                    v-model="desde_selected_open"
                    :close-on-content-click="false"
                    :close-on-click="false"
                    :return-value.sync="desde_selected"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                    clearable
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="desde_selected" label="Desde" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" class="pt-0 mt-0"></v-text-field>
                    </template>
                    <v-date-picker v-model="desde_selected" type="date" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="desde_selected_open = false"> Cancel </v-btn>
                      <v-btn text color="primary" @click="$refs.desde_selected.save(desde_selected)"> OK </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>

                <div class="col-md-2 pt-0">
                  <v-menu
                    ref="hasta_selected"
                    v-model="hasta_selected_open"
                    :close-on-content-click="false"
                    :close-on-click="false"
                    :return-value.sync="hasta_selected"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                    clearable
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="hasta_selected" label="Hasta" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" class="pt-0 mt-0"></v-text-field>
                    </template>
                    <v-date-picker v-model="hasta_selected" type="date" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="hasta_selected = false"> Cancel </v-btn>
                      <v-btn text color="primary" @click="$refs.hasta_selected.save(hasta_selected)"> OK </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>

                <div class="col-md-2 pt-0">
                  <v-combobox v-model="client_selected" autocomplete="off" item-text="name" item-value="id" :items="clients" label="Cliente" class="pt-0 mt-0" clearable></v-combobox>
                </div>

                <div class="col-md-2 pt-0">
                  <v-combobox v-model="category_selected" autocomplete="off" item-text="name" item-value="id" :items="categories" label="Rubro" class="pt-0 mt-0" clearable></v-combobox>
                </div>

                <div class="col-md-2 pt-0">
                  <v-combobox v-model="emitter_selected" autocomplete="off" item-text="name" item-value="id" :items="emitters" label="Emisor" class="pt-0 mt-0" clearable></v-combobox>
                </div>

                <div class="col-md-2 pt-0" style="display: flex; justify-content: end">
                  <v-btn color="info" class="" @click="filter">Buscar</v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>
      <v-card v-if="list_access || 1 == 1">
        <v-card-title>
          <v-btn color="blue-grey" class="mt-3" small dark :loading="dialog_export" @click="exportFile()"> Exportar <font-awesome-icon class="ml-2" icon="download" /></v-btn>

          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="search" label="Buscar" single-line hide-details></v-text-field>
        </v-card-title>

        <v-data-table
          :headers="isProductCompany() ? headersProducto : headersServicio"
          :items="list"
          :search="search"
          :loading="loading"
          loading-text="Cargando..."
          :options="{
            descending: true,
            itemsPerPage: 15,
          }"
          @pagination="updatePagination"
          :footer-props="{
            itemsPerPageOptions: [5, 10, 15, 50, -1],
          }"
        >
          <template v-slot:item="props">
            <tr>
              <td>{{ props.item.sku }}</td>
              <td v-if="isProductCompany()">{{ props.item.barcode }}</td>
              <td>{{ props.item.name }}</td>
              <td>{{ props.item.category }}</td>
              <td>{{ props.item.subcategory }}</td>
              <td class="text-right">{{ props.item.quantity }}</td>
              <td v-if="isProductCompany()" class="text-right">{{ props.item.total_weight + props.item.weight_unit }}</td>
              <td class="text-right">{{ formatUtils.formatPrice(props.item.total_sin_iva) }}</td>
            </tr>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              {{ "Busqueda sin resultados - " + search }}
            </v-alert>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import Constant from "@/constants/sections";
import { generalService } from "@/libs/ws/generalService";
import { formatUtils } from "@/libs/utils/formatUtils";
import { clientsService } from "@/libs/ws/clientsService";
import { emmiterService } from "@/libs/ws/emmiterService";
import GenericMixin from "@/mixins/GenericMixin.vue";
import Title from "@/components/Title.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import ExportMixin from "@/mixins/ExportMixin.vue";
import SettingsMixin from "@/mixins/SettingsMixin.vue";
export default {
  components: { Title },
  mixins: [GenericMixin, RolMixin, ExportMixin, SettingsMixin],
  data() {
    return {
      Constant: Constant,
      formatUtils: formatUtils,
      service: generalService,
      page_title: "Ventas por producto",
      modal_title: "Ventas por producto",
      headersServicio: [
        {
          text: "SKU",
          align: "left",
          sortable: true,
          value: "sku",
        },
        {
          text: "Producto",
          align: "left",
          sortable: true,
          value: "name",
        },
        {
          text: "Rubro",
          align: "left",
          sortable: true,
          value: "category",
        },
        {
          text: "Subrubro",
          align: "left",
          sortable: true,
          value: "subcategory",
        },
        {
          text: "Cantidad",
          align: "right",
          sortable: true,
          value: "quantity",
        },
        { text: "Total S/IVA", value: "total_sin_iva", align: "right" },
      ],
      headersProducto: [
        {
          text: "SKU",
          align: "left",
          sortable: true,
          value: "sku",
        },
        {
          text: "Codigo de barra",
          align: "left",
          sortable: true,
          value: "barcode",
        },
        {
          text: "Producto",
          align: "left",
          sortable: true,
          value: "name",
        },
        {
          text: "Rubro",
          align: "left",
          sortable: true,
          value: "category",
        },
        {
          text: "Subrubro",
          align: "left",
          sortable: true,
          value: "subcategory",
        },
        {
          text: "Cantidad",
          align: "right",
          sortable: true,
          value: "quantity",
        },
        {
          text: "Peso total",
          align: "right",
          sortable: true,
          value: "total_weight",
        },
        { text: "Total S/IVA", value: "total_sin_iva", align: "right" },
      ],
      list: [],
      desde_selected: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().slice(0, 10),
      hasta_selected: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().slice(0, 10),
      desde_selected_open: false,
      hasta_selected_open: false,
      client_selected: null,
      category_selected: null,
      emitter_selected: null,
      emitters: [],
      clients: [],
      categories: [],
    };
  },
  mounted() {
    this.loadList(this.service);
    this.loadClients();
    this.loadCategories();
    this.loadEmitters();
  },
  methods: {
    getFilterParams() {
      const param = {
        from: this.desde_selected,
        to: this.hasta_selected,
        client: this.client_selected != null ? this.client_selected.id : null,
        category: this.category_selected != null ? this.category_selected.id : null,
        emitter: this.emitter_selected != null ? this.emitter_selected.id : null,
      };
      return param;
    },
    filter() {
      this.loadList(this.service, this.getFilterParams());
    },
    loadEmitters() {
      emmiterService.filter({ enabled: true }).then((response) => {
        this.emitters = response.result;
      });
    },
    loadClients() {
      clientsService.filter().then((response) => {
        this.clients = response.result;
      });
    },
    loadCategories() {
      generalService.filter({}, Constant.SECTION_CATEGORIES).then((response) => {
        this.categories = response.result;
      });
    },
    loadList(service, param = null) {
      this.loading = true;

      if (param == null) {
        param = this.getFilterParams();
      }

      this.open_filter = false;
      service
        .query(param, Constant.SECTION_REPORTS + "/ventas-por-producto")
        .then((response) => {
          this.list = response.result;
          this.loading = false;

          if (typeof this.selected !== "undefined") {
            this.selected = [];
          }
        })
        .catch((e) => {
          console.log("service.filter error");
          console.log(e);
          this.loading = false;
        });
    },
    exportFile() {
      var param = this.getFilterParams();
      param["export"] = true;
      this.exportExcel(Constant.SECTION_REPORTS + "/ventas-por-producto", param, "Ventas-por-producto.xlsx");
    },
  },
};
</script>
