<template>
  <v-navigation-drawer
    :width="240"
    :permanent="!$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs"
    dark
    app
    class="rounded-0"
    v-model="$store.state.sidebar.open"
    :mini-variant.sync="$store.state.sidebar.mini"
    :expand-on-hover="!$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs && $store.state.sidebar.expand_on_hover"
  >
    <v-card @mouseenter="handleExpandMenu(true)" @mouseleave="handleExpandMenu(false)" style="min-height: 100vh" width="240">
      <div>
        <v-list style="height: 100px" v-show="!$store.state.sidebar.mini">
          <v-list-item>
            <v-list-item-content class="pt-1" style="display: flex; justify-content: center">
              <v-img transition="fade-transition" :src="require('@/assets/images/syscore_logo.svg')" contain style="max-width: 100%; margin-top: 14px" class="pt-0" />
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <div style="height: 100px" v-show="$store.state.sidebar.mini" class="pt-3">
          <v-img transition="fade-transition" :src="require('@/assets/images/syscore_logo_mini.svg')" contain height="80" width="80%" style="margin: 0 auto" class="pt-8" />
        </div>
        <v-divider class="mt-0"></v-divider>
      </div>

      <div style="scroll-margin-top: 100px" v-for="item in items" :key="item.title">
        <v-list v-if="item.visible" dense nav>
          <v-list-group v-if="item.visible && 'items' in item && item.items.length > 0" v-model="item.active" :key="item.title" no-action>
            <v-list-item slot="activator">
              <v-list-item-icon>
                <font-awesome-icon :icon="item.icon" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <router-link v-for="subItem in item.items" :key="subItem.title" :to="'/' + subItem.path">
              <v-list-item v-if="subItem.visible" ripple @click="close" class="subitem">
                <!--<v-list-item-icon>
                  <font-awesome-icon :icon="subItem.icon" />
                </v-list-item-icon>-->
                <v-list-item-content class="pl-6">
                  <v-list-item-title>{{ subItem.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </router-link>
          </v-list-group>

          <router-link v-else-if="item.visible" :to="'/' + item.path">
            <v-list-item :key="item.title" link>
              <v-list-item-icon>
                <font-awesome-icon :icon="item.icon" />
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
        </v-list>
      </div>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import Constant from "@/constants/sections";
import GenericMixin from "@/mixins/GenericMixin.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import SettingsMixin from "@/mixins/SettingsMixin.vue";
import { cryptoUtils } from "@/libs/utils/cryptoUtils";
export default {
  mixins: [GenericMixin, RolMixin, SettingsMixin],
  data() {
    return {
      isExternal: null,
      allItems: [
        {
          title: "Métricas",
          icon: "tachometer-alt",
          path: Constant.SECTION_METRICS,
          visible: false,
        },
        {
          title: "Facturación",
          icon: "cash-register",
          active: false,
          visible: false,
          items: [
            {
              title: "Comprobantes",
              icon: "file-invoice",
              path: Constant.SECTION_VOUCHERS,
              visible: false,
            },
            {
              title: "Remitos",
              icon: "receipt",
              path: Constant.SECTION_REMITOS,
              visible: false,
            },
            {
              title: "Cobros",
              icon: "money-check",
              path: Constant.SECTION_CHARGES,
              visible: false,
            },
            {
              title: "Clientes",
              icon: "users",
              path: Constant.SECTION_CLIENTS,
              visible: false,
            },
            {
              title: "Receptores",
              icon: "inbox",
              path: Constant.SECTION_RECEIVERS,
              visible: false,
            },
            {
              title: "Emisores",
              icon: "inbox",
              path: Constant.SECTION_EMITTERS,
              visible: false,
            },
            {
              title: "Productos",
              icon: "circle",
              path: Constant.SECTION_PRODUCTS,
              visible: false,
            },
            {
              title: "Lista de precios",
              icon: "circle",
              path: Constant.SECTION_PRICE_LIST,
              visible: false,
            },
            {
              title: "Rubros",
              icon: "circle",
              path: Constant.SECTION_CATEGORIES,
              visible: false,
            },
            {
              title: "Subrubros",
              icon: "circle",
              path: Constant.SECTION_SUBCATEGORIES,
              visible: false,
            },
          ],
        },

        {
          title: "Gastos",
          icon: "dollar",
          active: false,
          visible: false,
          items: [
            {
              title: "Comprobantes",
              icon: "file-invoice",
              path: Constant.SECTION_SPENTS,
              visible: false,
            },
            {
              title: "Pagos",
              icon: "money-check",
              path: Constant.SECTION_SPENT_PAYMENTS,
              visible: false,
            },
            {
              title: "Proveedores",
              icon: "circle",
              path: Constant.SECTION_SUPPLIERS,
              visible: false,
            },
          ],
        },
        /*{
          title: "Integraciones",
          icon: "exchange-alt",
          path: Constant.SECTION_INTEGRATIONS,
          visible: false,
        },*/
        {
          title: "Inventario",
          icon: "boxes-stacked",
          active: false,
          visible: false,
          only_visible_with_stock: true,
          items: [
            { title: "Stock", icon: "archive", path: Constant.SECTION_STOCKS, visible: false },
            { title: "Historial de stock", icon: "clock", path: Constant.SECTION_HISTORIAL_STOCKS, visible: false },
            { title: "Depositos", icon: "building", path: Constant.SECTION_WAREHOUSES, visible: false },
          ],
        },
        {
          title: "Tesoreria",
          icon: "landmark",
          active: false,
          visible: false,
          items: [
            {
              title: "Cuentas",
              icon: "circle",
              path: Constant.SECTION_MONEY_ACCOUNTS,
              visible: false,
            },
            {
              title: "Cheques",
              icon: "circle",
              path: Constant.SECTION_CHECKS,
              visible: false,
            },
            {
              title: "Movimientos de cuenta",
              icon: "sync",
              path: Constant.SECTION_BANKS_MOVEMENTS,
              visible: false,
            },
          ],
        },
        {
          title: "Logística",
          icon: "truck",
          active: false,
          visible: false,
          items: [
            {
              title: "Viajes",
              icon: "user-tie",
              path: Constant.SECTION_SHIPPING,
              visible: false,
            },
            {
              title: "Tarifas",
              icon: "user-tie",
              path: Constant.SECTION_RATE,
              visible: false,
            },

            {
              title: "Zonas",
              icon: "user-tie",
              path: Constant.SECTION_ZONE,
              visible: false,
            },
            {
              title: "Transportes",
              icon: "user-tie",
              path: Constant.SECTION_CARRIER,
              visible: false,
            },
          ],
        },
        {
          title: "Reportes",
          icon: "file-excel",
          active: false,
          visible: false,
          items: [
            {
              title: "Cta. Cte. clientes",
              icon: "file-invoice",
              path: Constant.SECTION_REPORTE_CTA_CTE_CLIENT,
              visible: false,
            },
            {
              title: "Cta. Cte. proveedores",
              icon: "file-invoice",
              path: Constant.SECTION_REPORTE_CTA_CTE_SUPPLIER,
              visible: false,
            },
            {
              title: "Cobranzas pendientes",
              icon: "file-invoice",
              path: Constant.SECTION_REPORTE_CLIENT_PENDING_CHARGE,
              visible: false,
            },
            {
              title: "Pagos pendientes",
              icon: "file-invoice",
              path: Constant.SECTION_REPORTE_SUPPLIER_PENDING_SPENT,
              visible: false,
            },
            {
              title: "Ventas por producto",
              icon: "file-invoice",
              path: Constant.SECTION_REPORTE_SALES_BY_PRODUCT,
              visible: false,
            },
            {
              title: "Retenciones",
              icon: "file-invoice",
              path: Constant.SECTION_REPORT_RETENTIONS,
              visible: false,
            },
            {
              title: "Percepciones",
              icon: "file-invoice",
              path: Constant.SECTION_REPORT_PERCEPTIONS,
              visible: false,
            },
            {
              title: "Comisiones por vendedor",
              icon: "file-invoice",
              path: Constant.SECTION_REPORT_SALESMAN_COMMISSION,
              visible: false,
            },
            {
              title: "Centro de Costos",
              icon: "file-invoice",
              path: Constant.SECTION_REPORT_COSTS_CENTERS,
              visible: false,
            },
          ],
        },

        {
          title: "Administrar",
          icon: "cog",
          active: false,
          visible: false,
          items: [
            {
              title: "Vendedores",
              icon: "user-tie",
              path: Constant.SECTION_SALESMAN,
              visible: false,
            },
            {
              title: "Centros de costos",
              icon: "user-tie",
              path: Constant.SECTION_COSTS_CENTERS,
              visible: false,
            },
            {
              title: "Subcentros de costos",
              icon: "user-tie",
              path: Constant.SECTION_SUB_COSTS_CENTERS,
              visible: false,
            },
            {
              title: "Colaboradores",
              icon: "user-tie",
              path: Constant.SECTION_EMPLOYEES,
              visible: false,
            },
            {
              title: "Usuarios",
              icon: "user-tie",
              path: Constant.SECTION_USERS,
              visible: false,
            },
            {
              title: "Roles",
              icon: "user-cog",
              path: Constant.SECTION_ROLES,
              visible: false,
            },
            {
              title: "Configuración",
              icon: "cogs",
              path: Constant.SECTION_SETTINGS,
              visible: false,
            },
          ],
        },
      ],
      items: [],
      right: null,
      expandMenu: this.$store.state.sidebar.open ? true : false,
      isMobile: false,
      show: true,
    };
  },
  mounted() {
    this.loadSideBarExternal();
  },
  watch: {},
  methods: {
    handleExpandMenu(value) {
      if (this.$store.state.sidebar.mini) {
        this.$store.commit("setOpenSidebar", {
          expand_on_hover: true,
        });
      }
    },
    close() {},
    loadSideBarExternal() {
      this.items = this.getItemsAvailable();
    },
    getItemsAvailable() {
      let itemsAvailable = [...this.allItems];
      itemsAvailable.forEach((item) => {
        if ("items" in item) {
          let any_visible = false;
          item.items.forEach((sub_item) => {
            sub_item.visible = sub_item.path in this.roles && this.roles[sub_item.path].sidebar;
            if (sub_item.visible) any_visible = true;
          });
          item.visible = any_visible;
        } else {
          item.visible = item.path in this.roles && this.roles[item.path].sidebar;
        }

        //Si el item solo es visible con stock y no es una empresa de productos, se oculta
        if (item.only_visible_with_stock != undefined && item.only_visible_with_stock && !this.isProductCompany()) {
          item.visible = false;
        }
      });
      return itemsAvailable;
    },
  },
};
</script>
