<template>
  <v-layout>
    <v-dialog v-bind:value="dialog" @click:outside="$emit('updateDialog', false)" max-width="750px">
      <template v-slot:activator="{ on }">
        <v-btn color="primary" class="mb-5" dark v-on="on" @click="$emit('updateDialog', true)">{{ button_name }}</v-btn>
      </template>

      <v-card>
        <v-form v-on:submit.prevent="createItem">
          <v-card-title>
            <span class="headline">{{ modal_title }}</span>
          </v-card-title>

          <v-card-text>
            <!-- START FORM DIALOG -->
            <div class="row">
              <div class="col-md-12">
                <v-combobox
                  v-model="item.client"
                  data-vv-as="cliente"
                  name="client"
                  item-text="business_name"
                  item-value="id"
                  :items="clients"
                  label="Cliente*"
                  autocomplete="off"
                  v-validate="'required'"
                  :disabled="isViewModal()"
                ></v-combobox>
                <span class="red--text">{{ errors.first("client") }}</span>

                <v-text-field data-vv-as="name" label="Razon social*" name="name" v-model="item.name" v-validate="'required'" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("name") }}</span>

                <div>
                  <v-combobox
                    label="Tipo de documento"
                    v-model="item.doc_type"
                    data-vv-as="tipo de documento"
                    name="doc_type"
                    :items="doc_types"
                    item-text="name"
                    item-value="id"
                    autocomplete="off"
                    v-validate="'required'"
                    @blur="query_padron()"
                    :disabled="isViewModal()"
                  ></v-combobox>
                  <span class="red--text">{{ errors.first("doc_type") }}</span>
                </div>

                <v-text-field
                  autocomplete="off"
                  data-vv-as="numero de documento"
                  label="Nº de documento*"
                  name="doc_nr"
                  v-validate="'integer|required'"
                  v-model="item.doc_nr"
                  :disabled="isViewModal()"
                  @blur="query_padron()"
                ></v-text-field>
                <span class="red--text">{{ errors.first("doc_nr") }}</span>

                <div>
                  <v-combobox
                    label="Condición frente al IVA"
                    v-validate="'required'"
                    v-model="item.iva_condition"
                    data-vv-as="Condición frente al IVA"
                    name="iva_condition"
                    :items="iva_conditions"
                    item-text="name"
                    item-value="id"
                    autocomplete="off"
                    :disabled="isViewModal()"
                  ></v-combobox>
                  <span class="red--text">{{ errors.first("iva_condition") }}</span>
                </div>

                <v-text-field autocomplete="off" data-vv-as="domicilio" label="Domicilio*" name="address" v-validate="'required'" v-model="item.address" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("address") }}</span>

                <div>
                  <v-combobox
                    label="Tipo de transferencia"
                    v-model="item.transfer_type"
                    data-vv-as="Tipo de transferencia"
                    name="transfer_type"
                    :items="transfer_types"
                    item-text="name"
                    item-value="id"
                    autocomplete="off"
                    :disabled="isViewModal()"
                  ></v-combobox>
                </div>

                <div>
                  <v-combobox
                    label="Código de Jurisdiccion de IIBB"
                    v-model="item.iibb_code"
                    data-vv-as="Código de Jurisdiccion de IIBB"
                    name="iibb_code"
                    :items="iibb_codes"
                    item-text="name"
                    item-value="id"
                    autocomplete="off"
                    :disabled="isViewModal()"
                  ></v-combobox>
                </div>

                <v-row>
                  <v-col>
                    <v-text-field
                      autocomplete="off"
                      data-vv-as="provincia"
                      label="Provincia"
                      name="provincia"                        
                      v-model="item.provincia"
                      :disabled="isViewModal()"
                    ></v-text-field>
                    <span class="red--text">{{ errors.first("provincia") }}</span>
                  </v-col>
                  <v-col>
                    <v-text-field
                      autocomplete="off"
                      data-vv-as="location"
                      label="Localidad"
                      name="location"
                      v-model="item.location"
                      :disabled="isViewModal()"
                    ></v-text-field>
                    <span class="red--text">{{ errors.first("location") }}</span>
                  </v-col>                  
                  <v-col>
                    <v-text-field
                      autocomplete="off"
                      data-vv-as="postal_code"
                      label="Codigo Postal"
                      v-validate="'min:4|max:5'"
                      name="postal_code"                        
                      v-model="item.postal_code"
                      :disabled="isViewModal()"
                    ></v-text-field>
                    <span class="red--text">{{ errors.first("postal_code") }}</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      autocomplete="off"
                      data-vv-as="ingresos_brutos_nr"
                      label="Ingresos Brutos"
                      v-validate="'max:60'"
                      name="ingresos_brutos_nr"                        
                      v-model="item.ingresos_brutos_nr"
                      :disabled="isViewModal()"
                    ></v-text-field>
                    <span class="red--text">{{ errors.first("ingresos_brutos_nr") }}</span>
                  </v-col>
                  <v-col>
                    <v-text-field
                      autocomplete="off"
                      data-vv-as="external_reference"
                      label="Codigo de referencia externo"
                      v-validate="'max:60'"
                      name="external_reference"                        
                      v-model="item.external_reference"
                      :disabled="isViewModal()"
                    ></v-text-field>
                    <span class="red--text">{{ errors.first("external_reference") }}</span>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('updateDialog', false)">Cerrar</v-btn>
            <v-btn color="success" text type="submit" v-if="!isViewModal()" :loading="loading">{{ formBtn }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import Constant from "@/constants/sections";
import { billingGeneralService } from "@/libs/ws/billingGeneralService";
import { generalService } from "@/libs/ws/generalService";
import ModalGenericMixin from "@/mixins/ModalGenericMixin.vue";

export default {
  components: {},
  mixins: [ModalGenericMixin],
  props: {
    parent: Object,
    service: Object,
    modal_title: String,
    dialog: Boolean,
    item: Object,
    button_name: String,
    formBtn: String,
  },
  data() {
    return {
      show: false,
      roles: [],
      doc_types: [],
      iva_conditions: [],
      iibb_codes: [],
      clients: [],
      transfer_types: [
        { id: "SCA", name: "TRANSFERENCIA AL SISTEMA DE CIRCULACION ABIERTA" },
        { id: "ADC", name: "AGENTE DE DEPOSITO COLECTIVO" },
      ],
    };
  },
  watch: {
    item() {
      this.$emit("updateItem", this.item);
    },
    dialog() {
      this.errors.clear();
    },
  },
  mounted() {
    this.loadDocTypes();
    this.loadIVAConditions();
    this.loadIIBBCodes();
    this.loadClients();
  },
  methods: {
    loadClients() {
      generalService
        .filter({}, Constant.SECTION_CLIENTS)
        .then((response) => {
          this.clients = response.result;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    isViewModal() {
      return this.formBtn == "";
    },
    loadIIBBCodes() {
      this.service.iibb_codes().then((response) => {
        this.iibb_codes = response.result;
      });
    },
    loadDocTypes() {
      billingGeneralService.list_doc_types().then((response) => {
        this.doc_types = response.result;
      });
    },
    loadIVAConditions() {
      billingGeneralService.list_iva_conditions().then((response) => {
        this.iva_conditions = response.result;
      });
    },
    query_padron(){
      if(this.item.doc_nr != null && this.item.doc_type != null){
        this.service
          .query_padron({
            tax_id: this.item.doc_nr,
            doc_type: this.item.doc_type.id,
          })
          .then((response) => {
            console.log(response)
            //TODO: ver si volvio bien y con algun alerta
            if(response.status == true){
              this.item.name = response.result.name;
              this.item.address = response.result.address;
              this.item.location = response.result.location;
              this.item.provincia = response.result.provincia;
              this.item.postal_code = response.result.postal_code;

              this.item.iva_condition = this.iva_conditions.find((x) => x.id == response.result.iva_condition);
              this.item.ingresos_brutos_nr = response.result.ingresos_brutos_nr;                            
            }
          })
          .catch((response) => {
            console.log(response);
            this.$toast.error(response.msg, "Error");
          });
      }
    },
    createItem(e) {
      this.$validator.validate().then((valid) => {
        if (valid) {
          this.loading = true;
          e.preventDefault();
          //Se crea data para reemplazar criteria_price co nel id
          let data = new FormData();
          data.append("id", this.item.id);
          data.append("name", this.item.name);
          data.append("doc_type", this.item.doc_type.id);
          data.append("doc_nr", this.item.doc_nr);
          data.append("client", this.item.client.id);
          data.append("iva_condition", this.item.iva_condition.id);
          data.append("address", this.item.address);
          if(this.item.transfer_type != null)
            data.append("transfer_type", this.item.transfer_type.id);
          
          if(this.item.iibb_code != null)
            data.append("iibb_code", this.item.iibb_code.id);

          if(this.item.external_reference != null)//Porque el FormData manda "null" como string
            data.append("external_reference", this.item.external_reference);
          if(this.item.postal_code != null)
            data.append("postal_code", this.item.postal_code);
          if(this.item.location != null)
            data.append("location", this.item.location);
          if(this.item.provincia != null)
            data.append("provincia", this.item.provincia);
          if(this.item.ingresos_brutos_nr != null)
            data.append("ingresos_brutos_nr", this.item.ingresos_brutos_nr);

          if (this.item.id > 0) {
            this.service
              .update(data)
              .then((response) => {
                if (response.status == true) {
                  this.$toast.success("El " + this.modal_title + " se modifico correctamente.", "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    this.$toast.error(response.msg, "Error");
                  }
                }
              })
              .catch((response) => {
                this.$toast.error(response.msg, "Error");
              })
              .finally(() => {
                this.loading = false;
              });
          } else {
            this.service
              .create(data)
              .then((response) => {
                console.log(response);
                if (response.status == true) {
                  this.$toast.success("El " + this.modal_title + " se creo correctamente", "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    this.$toast.error(response.msg, "Error");
                  }
                }
              })
              .catch((response) => {
                console.log(response);
                this.$toast.error(response.msg, "Error");
              })
              .finally(() => {
                this.loading = false;
              });
          }
        }
      });
    },
  },
};
</script>
