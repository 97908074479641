<template>
  <div>
    <v-dialog v-model="show" max-width="600">
      <v-card>
        <v-card-title class="headline">Exportar</v-card-title>

        <v-card-text>
          <p class="mt-5 mb-5 fs-16">Selecciones el periodo a exportar</p>
          <div class="row pt-5">
            <div class="col-md-6 pt-0">
              <v-menu
                ref="desde_selected"
                v-model="desde_selected_open"
                :close-on-content-click="false"
                :close-on-click="false"
                :return-value.sync="desde_selected"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
                clearable
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="desde_selected" label="Desde" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" class="pt-0 mt-0"></v-text-field>
                </template>
                <v-date-picker v-model="desde_selected" type="date" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="desde_selected_open = false"> Cancel </v-btn>
                  <v-btn text color="primary" @click="$refs.desde_selected.save(desde_selected)"> OK </v-btn>
                </v-date-picker>
              </v-menu>
            </div>

            <div class="col-md-6 pt-0">
              <v-menu
                ref="hasta_selected"
                v-model="hasta_selected_open"
                :close-on-content-click="false"
                :close-on-click="false"
                :return-value.sync="hasta_selected"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
                clearable
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="hasta_selected" label="Hasta" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" class="pt-0 mt-0"></v-text-field>
                </template>
                <v-date-picker v-model="hasta_selected" type="date" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="hasta_selected = false"> Cancel </v-btn>
                  <v-btn text color="primary" @click="$refs.hasta_selected.save(hasta_selected)"> OK </v-btn>
                </v-date-picker>
              </v-menu>
            </div>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="$emit('close')"> Cancelar </v-btn>

          <v-btn color="green darken-1" text @click="$emit('confirm', { desde: desde_selected, hasta: hasta_selected })"> Exportar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    show: Boolean,
    confirm: Function,
    cancel: Function,
  },
  data() {
    return {
      desde_selected_open: false,
      hasta_selected_open: false,
      desde_selected: new Date(new Date().getFullYear() - 1, new Date().getMonth(), 1).toISOString().slice(0, 10),
      hasta_selected: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().slice(0, 10),
    };
  },
};
</script>
