import { wsService } from "./wsService";
import sections from "@/constants/sections";

const section = sections.SECTION_METRICS;

function getFacturacion(param = { enable: true }) {
  return wsService.make_post_request(section + "/facturacion", param);
}

function getFacturacionByCategoria(param = { enable: true }) {
  return wsService.make_post_request(section + "/facturacion-by-categoria", param);
}

function getFacturacionByComprobante(param = { enable: true }) {
  return wsService.make_post_request(section + "/facturacion-by-comprobante", param);
}

function getFacturacionByCliente(param = { enable: true }) {
  return wsService.make_post_request(section + "/facturacion-by-cliente", param);
}

function getFacturacionByYear(param = { enable: true }) {
  return wsService.make_post_request(section + "/facturacion-by-year", param);
}

function getGastos(param = { enable: true }) {
  return wsService.make_post_request(section + "/gastos", param);
}

function getGastosByCategoria(param = { enable: true }) {
  return wsService.make_post_request(section + "/gastos-by-categoria", param);
}

function getGastosByProveedor(param = { enable: true }) {
  return wsService.make_post_request(section + "/gastos-by-proveedor", param);
}

function getGastosByYear(param = { enable: true }) {
  return wsService.make_post_request(section + "/gastos-by-year", param);
}

function getRentabilidadByYear(param = { enable: true }) {
  return wsService.make_post_request(section + "/rentabilidad-by-year", param);
}

function getCobros(param = { enable: true }) {
  return wsService.make_post_request(section + "/cobros", param);
}

function getPagos(param = { enable: true }) {
  return wsService.make_post_request(section + "/pagos", param);
}

function getPendienteDeCobro(param = { enable: true }) {
  return wsService.make_post_request(section + "/pendiente-de-cobro", param);
}

function getPendienteDePago(param = { enable: true }) {
  return wsService.make_post_request(section + "/pendiente-de-pago", param);
}

function getIVA(param = { enable: true }) {
  return wsService.make_post_request(section + "/saldos-iva", param);
}

function getIIBB(param = { enable: true }) {
  return wsService.make_post_request(section + "/saldos-iibb", param);
}

function getImpGanancias(param = { enable: true }) {
  return wsService.make_post_request(section + "/saldos-ganancias", param);
}

function getMonedas() {
  return [
    { id: "ARS", name: "ARS" },
    { id: "USD", name: "USD" },
  ];
}

export const metricsService = {
  getCobros,
  getPagos,
  getPendienteDeCobro,
  getPendienteDePago,
  getGastos,
  getGastosByCategoria,
  getGastosByProveedor,
  getGastosByYear,
  getFacturacion,
  getFacturacionByCategoria,
  getFacturacionByComprobante,
  getFacturacionByCliente,
  getFacturacionByYear,
  getRentabilidadByYear,
  getMonedas,
  getIVA,
  getIIBB,
  getImpGanancias,
};
