<template>
  <v-card v-if="invoice.emitter != null && invoice.emitter.iva_condition.id != emmiterService.CONDITION_IVA_MONO" elevation="1" class="mb-4">
    <v-card-text>
      <h6 class="text-h6 black--text">Tributos</h6>
      <p v-if="!isViewModal" class="text-body-2">Percepciones especiales que se aplican a perfil fiscal</p>
      <v-row>
        <v-col md="12" v-if="!isViewModal">
          <v-btn small :disabled="isDisabledAdd()" color="blue-grey" class="text-white" @click="addTributeDetail()">
            <v-icon big color="green"> add </v-icon>
            <span>Agregar item</span>
          </v-btn>
        </v-col>
        <v-col md="12">
          <v-data-table class="pb-4" :headers="header_tribute" :items="invoice.tributes" style="border: solid 1px #1c1c1c" :hide-default-footer="true" :items-per-page="100">
            <template v-slot:item="props">
              <tr>
                <td>
                  <v-combobox
                    :loading="loading_combos['tributes']"
                    v-model="props.item.tribute"
                    data-vv-as="tribute"
                    name="tribute"
                    item-text="Desc"
                    item-value="Id"
                    :items="tributes"
                    label="Tributo"
                    autocomplete="off"
                    v-validate="'required'"
                    :disabled="isViewModal || invoice.id > 0"
                  ></v-combobox>
                  <span class="red--text">{{ errors.first("tributes") }}</span>
                </td>
                <td style="min-width: 45%">
                  <v-text-field
                    :disabled="isViewModal || invoice.id > 0"
                    autocomplete="off"
                    name="descripcion"
                    placeholder="Descripción*"
                    dense
                    type="text"
                    v-model="props.item.descripcion"
                    class="mt-3"
                  />
                  <span class="red--text">{{ errors.first("descripcion") }}</span>
                </td>

                <td style="width: 30px">
                  <v-text-field
                    :disabled="isViewModal || invoice.id > 0"
                    autocomplete="off"
                    name="alic"
                    placeholder="Alicuota*"
                    dense
                    v-validate="'decimal'"
                    type="number"
                    v-model="props.item.alic"
                    class="mt-3"
                  />
                  <span class="red--text">{{ errors.first("alic") }}</span>
                </td>
                <td>
                  <v-text-field
                    :disabled="isViewModal || invoice.id > 0"
                    autocomplete="off"
                    name="base_imp"
                    placeholder="Base Imponible*"
                    dense
                    v-validate="'required|decimal'"
                    v-model="props.item.base_imp"
                    class="mt-3"
                  />
                  <span class="red--text">{{ errors.first("base_imp") }}</span>
                </td>
                <td class="text-right">{{ formatUtils.formatPrice((props.item.alic / 100.0) * props.item.base_imp) }}</td>
                <td class="text-center">
                  <v-tooltip bottom v-if="!isViewModal">
                    <template v-slot:activator="{ on }">
                      <v-icon :disabled="invoice.id > 0" v-on="on" small color="red" @click="removeDetail(props.item, 'tribute')"> indeterminate_check_box </v-icon>
                    </template>
                    <span>Remover</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { emmiterService } from "@/libs/ws/emmiterService";
import { formatUtils } from "@/libs/utils/formatUtils";
export default {
  created() {
    this.$validator = this.validator;
  },
  components: {},
  props: {
    invoice: Object,
    loadItemsFromInvoiceCancel: Function,
    loading_combos: Object,
    validator: Object,
    isViewModal: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    formatUtils: formatUtils,
    emmiterService: emmiterService,
    header_tribute: [
      { text: "Tributo", value: "tribute.Name", sortable: false },
      { text: "Descripción", value: "descripcion", sortable: false },
      { text: "Alicuota %", value: "alic", sortable: false, class: "bg-grey" },
      { text: "Base imponible", value: "base_imp", sortable: false },
      { text: "Total", value: "total", sortable: false },
      { text: "", value: "", sortable: false, class: "bg-grey" },
    ],
    tributes: [],
  }),
  watch: {
    invoice: {
      handler: function (newValue) {
        if ((this.emitter_selected == null && newValue.emitter != null) || (newValue.emitter != null && this.emitter_selected.id != newValue.emitter.id)) {
          this.emitter_selected = newValue.emitter;
          this.emitterChangeEvent();
        }
      },
      deep: true,
    },
  },
  mounted() {},
  methods: {
    isDisabledAdd() {
      return this.invoice.emitter == null || this.invoice.id > 0;
    },
    addTributeDetail() {
      this.invoice.tributes.push({
        descripcion: "",
        alic: "",
        base_imp: 0,
        tribute: null,
      });
    },
    removeDetail(item, colection_name) {
      if (colection_name == "tribute") {
        const index_item = this.invoice.tributes.indexOf(item);
        if (index_item > -1) this.invoice.tributes.splice(index_item, 1);
      }
    },
    emitterChangeEvent(event) {
      if (this.invoice.emitter != null && this.invoice.emitter.id) {
        this.loading_combos["tributes"] = true;
        emmiterService
          .tributos(this.invoice.emitter.id)
          .then((response) => {
            if (response.status) {
              this.tributes = response.result;
              //this.$emit("loadItemsFromInvoiceCancel");
            }
          })
          .finally(() => {
            this.loading_combos["tributes"] = false;
          });
      }
    },
  },
};
</script>
