<template>
  <div>
    <Title :pages="[{ icon: 'file-invoice', page: page_title.toUpperCase() }]"></Title>

    <v-container fluid>
      <v-card v-if="list_access || 1 == 1">
        <div class="row mb-2 mt-0">
          <div class="col-md-12">
            <div class="col-md-12" style="background:white; elevation-0">
              <div class="row pt-5">
                <div class="col-md-2 pt-0">
                  <v-menu
                    ref="desde_selected"
                    v-model="desde_selected_open"
                    :close-on-content-click="false"
                    :close-on-click="false"
                    :return-value.sync="desde_selected"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                    clearable
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="desde_selected" label="Desde" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" class="pt-0 mt-0"></v-text-field>
                    </template>
                    <v-date-picker v-model="desde_selected" type="date" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="desde_selected_open = false"> Cancel </v-btn>
                      <v-btn text color="primary" @click="$refs.desde_selected.save(desde_selected)"> OK </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>

                <div class="col-md-2 pt-0">
                  <v-menu
                    ref="hasta_selected"
                    v-model="hasta_selected_open"
                    :close-on-content-click="false"
                    :close-on-click="false"
                    :return-value.sync="hasta_selected"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                    clearable
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="hasta_selected" label="Hasta" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" class="pt-0 mt-0"></v-text-field>
                    </template>
                    <v-date-picker v-model="hasta_selected" type="date" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="hasta_selected = false"> Cancel </v-btn>
                      <v-btn text color="primary" @click="$refs.hasta_selected.save(hasta_selected)"> OK </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>

                <div class="col-md-3 pt-0">
                  <v-combobox
                    @change="loadClients"
                    v-model="salesman_selected"
                    autocomplete="off"
                    item-text="name"
                    item-value="id"
                    :items="salesmans"
                    label="Vendedor"
                    class="pt-0 mt-0"
                    clearable
                  ></v-combobox>
                </div>

                <div class="col-md-3 pt-0">
                  <v-combobox
                    :loading="loading_clients"
                    v-model="client_selected"
                    autocomplete="off"
                    item-text="name"
                    item-value="id"
                    :items="clients"
                    label="Cliente"
                    class="pt-0 mt-0"
                    clearable
                  ></v-combobox>
                </div>

                <div class="col-md-2 pt-0" style="display: flex; justify-content: end">
                  <v-btn color="info" class="" @click="filter">Calcular</v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>
      <v-card v-if="list_access || 1 == 1">
        <v-card-title>
          <div class="col-md-8 pb-0 pl-0">
            <v-btn color="blue-grey" class="mr-2" small dark :loading="dialog_export" @click="exportFile()"> Exportar <font-awesome-icon class="ml-2" icon="download" /></v-btn>

            <v-chip outlined color="primary"
              >Total Comisiones:&nbsp;<span>{{ formatUtils.formatPrice(total) }}</span></v-chip
            >
          </div>
          <div class="col-md-4 pb-0">
            <v-text-field v-model="search" append-icon="search" label="Buscar" single-line hide-details></v-text-field>
          </div>
        </v-card-title>

        <v-data-table
          :headers="salesman_selected != null && salesman_selected.type.id == salesmanService.TYPE_CHARGE ? headersCobros : headersVentas"
          :items="list"
          :search="search"
          :loading="loading"
          loading-text="Cargando..."
          :options="{
            descending: true,
            itemsPerPage: 15,
          }"
          @pagination="updatePagination"
          :footer-props="{
            itemsPerPageOptions: [5, 10, 15, 50, -1],
          }"
        >
          <template v-slot:item="props" v-if="salesman_selected != null && salesman_selected.type.id == salesmanService.TYPE_CHARGE">
            <tr>
              <td>{{ props.item.nro_recibo }}</td>
              <td>{{ props.item.cbte_fch }}</td>
              <td>{{ props.item.client }}</td>
              <td class="text-right">{{ formatUtils.formatPrice(props.item.cobrado) }}</td>
              <td class="text-right">{{ formatUtils.formatPrice(props.item.retenciones) }}</td>
              <td class="text-right">{{ formatUtils.formatPrice(props.item.iva) }}</td>
              <td class="text-right">{{ formatUtils.formatPrice(props.item.cobro_real) }}</td>
              <td class="text-right">{{ formatUtils.formatPrice(props.item.comision) }}</td>
            </tr>
          </template>
          <template v-slot:item="props" v-else>
            <tr>
              <td>{{ props.item.voucher_label }}</td>
              <td>{{ props.item.cbte_fch }}</td>
              <td>{{ props.item.client }}</td>
              <td class="text-right">{{ formatUtils.formatPrice(props.item.neto) }}</td>
              <td class="text-right">{{ formatUtils.formatPrice(props.item.iva) }}</td>
              <td class="text-right">{{ formatUtils.formatPrice(props.item.tributos) }}</td>
              <td class="text-right">{{ formatUtils.formatPrice(props.item.facturado_real) }}</td>
              <td class="text-right">{{ formatUtils.formatPrice(props.item.comision) }}</td>
            </tr>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              {{ "Busqueda sin resultados - " + search }}
            </v-alert>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import Constant from "@/constants/sections";
import { reportService } from "@/libs/ws/reportService";
import { formatUtils } from "@/libs/utils/formatUtils";
import { clientsService } from "@/libs/ws/clientsService";
import { salesmanService } from "@/libs/ws/salesmanService";

import GenericMixin from "@/mixins/GenericMixin.vue";
import Title from "@/components/Title.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import ExportMixin from "@/mixins/ExportMixin.vue";
import SettingsMixin from "@/mixins/SettingsMixin.vue";
export default {
  components: { Title },
  mixins: [GenericMixin, RolMixin, ExportMixin, SettingsMixin],
  data() {
    return {
      Constant: Constant,
      formatUtils: formatUtils,
      service: reportService,
      salesmanService: salesmanService,
      page_title: "Comisiones por vendedor",
      modal_title: "Comisiones por vendedor",
      headersCobros: [
        {
          text: "Nº Recibo",
          align: "left",
          sortable: true,
          value: "nro_recibo",
        },
        {
          text: "Fecha",
          align: "left",
          sortable: true,
          value: "creation_date",
        },
        {
          text: "Cliente",
          align: "left",
          sortable: true,
          value: "client",
        },
        { text: "Cobrado", value: "cobrado", align: "right" },
        { text: "Retenciones", value: "retenciones", align: "right" },
        { text: "IVA", value: "iva", align: "right" },
        { text: "Total a calcular", value: "cobro_real", align: "right" },
        { text: "Comision", value: "comision", align: "right" },
      ],
      headersVentas: [
        {
          text: "Comprobante",
          align: "left",
          sortable: true,
          value: "voucher_label",
        },
        {
          text: "Fecha",
          align: "left",
          sortable: true,
          value: "cbte_fch",
        },
        {
          text: "Cliente",
          align: "left",
          sortable: true,
          value: "client",
        },
        { text: "Total Neto", value: "neto", align: "right" },
        { text: "IVA", value: "iva", align: "right" },
        { text: "Tributos", value: "tributos", align: "right" },
        { text: "Total a calcular", value: "facturado_real", align: "right" },
        { text: "Comision", value: "comision", align: "right" },
      ],
      list: [],
      desde_selected: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().slice(0, 10),
      hasta_selected: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().slice(0, 10),
      desde_selected_open: false,
      hasta_selected_open: false,
      client_selected: null,
      salesman_selected: null,
      clients: [],
      salesmans: [],
      loading_clients: false,
      total: 0,
    };
  },
  mounted() {
    this.loadClients();
    this.loadSalesman();
  },
  methods: {
    getFilterParams() {
      const param = {
        from: this.desde_selected,
        to: this.hasta_selected,
        client: this.client_selected != null ? this.client_selected.id : null,
        salesman: this.salesman_selected != null ? this.salesman_selected.id : null,
      };
      return param;
    },
    filter() {
      this.loadList(this.service, this.getFilterParams());
    },
    getTotal() {
      this.total = 0;
      if (this.list.length > 0) {
        this.list.forEach((element) => {
          this.total = (parseFloat(this.total) + parseFloat(element.comision)).toFixed(2);
        });
      }
    },
    loadClients() {
      this.client_selected = null;
      var param = {};
      if (this.salesman_selected != null && Object.prototype.hasOwnProperty.call(this.salesman_selected, "id")) {
        param["salesman"] = this.salesman_selected.id;
        this.loading_clients = true;
      }
      clientsService
        .filter(param)
        .then((response) => {
          this.clients = response.result;
        })
        .finally(() => {
          this.loading_clients = false;
        });
    },
    loadSalesman() {
      salesmanService
        .filter({ enabled: true })
        .then((response) => {
          this.salesmans = response.result;
        })
        .catch((e) => {});
    },
    loadList(service, param = null) {
      if (param == null) {
        param = this.getFilterParams();
      }

      if (this.salesman_selected == null || !Object.prototype.hasOwnProperty.call(this.salesman_selected, "id")) {
        this.$toast.error("Debe seleccionar un vendedor para realizar la busqueda.", "Error");
        return;
      }
      this.loading = true;

      this.open_filter = false;
      service
        .getSalesmanCommission(param)
        .then((response) => {
          this.list = response.result;
          this.loading = false;

          if (typeof this.selected !== "undefined") {
            this.selected = [];
          }
          this.getTotal();
        })
        .catch((e) => {
          console.log("service.filter error");
          console.log(e);
          this.total = 0;
          this.loading = false;
        });
    },
    exportFile() {
      var param = this.getFilterParams();
      param["export"] = true;
      this.exportExcel(Constant.SECTION_REPORT_SALESMAN_COMMISSION, param, "Comisiones-por-vendedor.xlsx");
    },
  },
};
</script>
