<template>
  <div>
    <Title :pages="[{ icon: 'file-invoice', page: page_title.toUpperCase() }]"></Title>

    <v-container fluid>
      <v-card v-if="list_access || 1 == 1">
        <div class="row mb-2 mt-0">
          <div class="col-md-12">
            <div class="col-md-12" style="background:white; elevation-0">
              <div class="row pt-5">
                <div class="col-md-2 pt-0">
                  <v-menu
                    ref="desde_selected"
                    v-model="desde_selected_open"
                    :close-on-content-click="false"
                    :close-on-click="false"
                    :return-value.sync="desde_selected"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                    clearable
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="desde_selected" label="Desde" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" class="pt-0 mt-0"></v-text-field>
                    </template>
                    <v-date-picker v-model="desde_selected" type="date" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="desde_selected_open = false"> Cancel </v-btn>
                      <v-btn text color="primary" @click="$refs.desde_selected.save(desde_selected)"> OK </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>

                <div class="col-md-2 pt-0">
                  <v-menu
                    ref="hasta_selected"
                    v-model="hasta_selected_open"
                    :close-on-content-click="false"
                    :close-on-click="false"
                    :return-value.sync="hasta_selected"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                    clearable
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="hasta_selected" label="Hasta" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" class="pt-0 mt-0"></v-text-field>
                    </template>
                    <v-date-picker v-model="hasta_selected" type="date" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="hasta_selected = false"> Cancel </v-btn>
                      <v-btn text color="primary" @click="$refs.hasta_selected.save(hasta_selected)"> OK </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>

                <div class="col-md-3 pt-0">
                  <v-combobox
                    v-model="cost_center_selected"
                    autocomplete="off"
                    item-text="name"
                    item-value="id"
                    :items="costs_centers"
                    label="Centro de Costo"
                    class="pt-0 mt-0"
                    clearable
                  ></v-combobox>
                </div>

                <div class="col-md-3 pt-0">
                  <v-combobox v-model="receiver_selected" autocomplete="off" item-text="name" item-value="id" :items="receivers" label="Receptor" class="pt-0 mt-0" clearable></v-combobox>
                </div>

                <div class="col-md-2 pt-0" style="display: flex; justify-content: end">
                  <v-btn color="info" class="" @click="filter">Buscar</v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>
      <v-card v-if="list_access || 1 == 1">
        <v-card-title>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="blue-grey" class="mt-3" small dark :loading="dialog_export" v-bind="attrs" v-on="on"> Exportar <font-awesome-icon class="ml-2" icon="download" /></v-btn>
            </template>

            <v-list>
              <v-list-item dense @click="exportFile('cost_centes')">
                <v-list-item-title>Exportar Centros de Costo</v-list-item-title>
              </v-list-item>

              <v-list-item dense @click="exportFile('subcost_centes')">
                <v-list-item-title>Exportar Subcentros de Costo</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="search" label="Buscar" single-line hide-details></v-text-field>
        </v-card-title>

        <v-data-table
          :headers="headers"
          :items="list"
          :search="search"
          item-key="id"
          :loading="loading"
          :options="pagination"
          :mobile-breakpoint="0"
          loading-text="Cargando... Por favor espere"
          :single-expand="false"
          :expanded.sync="expanded"
          show-expand
          @pagination="updatePagination"
          :footer-props="{
            itemsPerPageOptions: [5, 10, 15, 50, -1],
          }"
        >
          <template v-slot:item="{ item, expand, isExpanded }">
            <tr>
              <td style="width: 30%">{{ item.cc_padre_name }}</td>
              <td style="width: 20%" class="text-right">{{ formatUtils.formatPrice(item.total_ingreso) }}</td>
              <td style="width: 20%" class="text-right">{{ formatUtils.formatPrice(item.total_egreso) }}</td>
              <td style="width: 20%" class="text-right">{{ formatUtils.formatPrice(item.total_saldo) }}</td>
              <td style="width: 10%" class="text-center">
                <font-awesome-icon v-if="!isExpanded" class="cursor" icon="chevron-down" @click="expand(!isExpanded)" />
                <font-awesome-icon v-else class="cursor" icon="chevron-up" @click="expand(!isExpanded)" />
              </td>
            </tr>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="pl-0 pr-0">
              <v-data-table
                :headers="headersHijo"
                :items="item.cc_hijos"
                :search="search"
                loading-text="Cargando... Por favor espere"
                hide-default-footer
                disable-pagination
                class="background-color: grey lighten-3"
              >
                <template v-slot:item="props">
                  <tr>
                    <td style="width: 30%" @click="openDetalleModal(props.item)">{{ props.item.cc_hijo_name }}</td>
                    <td style="width: 20%" class="text-right">{{ formatUtils.formatPrice(props.item.total_ingreso) }}</td>
                    <td style="width: 20%" class="text-right">{{ formatUtils.formatPrice(props.item.total_egreso) }}</td>
                    <td style="width: 20%" class="text-right">{{ formatUtils.formatPrice(props.item.total_saldo) }}</td>
                    <td style="width: 10%"></td>
                  </tr>
                </template>
                <template v-slot:no-results>
                  <v-alert :value="true" color="error" icon="warning"> No se encontraron resultados para "{{ search }}". </v-alert>
                </template>
              </v-data-table>
            </td>
          </template>
          <v-alert :value="true" color="error" icon="warning"> No se encontraron resultados para "{{ search }}". </v-alert>
        </v-data-table>
      </v-card>

      <ModalTable
        :title="modal_title"
        :open="open_modal"
        :cancel="
          () => {
            (open_modal = false), (selected_item = []);
          }
        "
        :items="selected_item"
        :headers="[
          { text: 'Concepto', align: 'left', sortable: true, value: 'concepto' },
          { text: 'Comprobante', align: 'left', sortable: true, value: 'comprobante' },
          { text: 'Fecha', align: 'left', sortable: true, value: 'fecha' },
          { text: 'Ingreso', align: 'left', sortable: true, value: 'ingreso', format: 'price' },
          { text: 'Egreso', align: 'left', sortable: true, value: 'egreso', format: 'price' },
          { text: 'Saldo', align: 'left', sortable: true, value: null, operation: '-', fields: ['ingreso', 'egreso'], format: 'price' },
        ]"
      />
    </v-container>
  </div>
</template>

<script>
import Constant from "@/constants/sections";
import { generalService } from "@/libs/ws/generalService";
import { formatUtils } from "@/libs/utils/formatUtils";
import { clientsService } from "@/libs/ws/clientsService";
import { emmiterService } from "@/libs/ws/emmiterService";

import GenericMixin from "@/mixins/GenericMixin.vue";
import Title from "@/components/Title.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import ExportMixin from "@/mixins/ExportMixin.vue";
import SettingsMixin from "@/mixins/SettingsMixin.vue";
import ModalTable from "@/components/ModalTable.vue";

export default {
  components: { Title, ModalTable },
  mixins: [GenericMixin, RolMixin, ExportMixin, SettingsMixin],
  data() {
    return {
      open_modal: false,
      selected_item: null,
      modal_title: "",
      Constant: Constant,
      formatUtils: formatUtils,
      service: generalService,
      page_title: "Centro de Costo",
      expanded: [],
      headers: [
        {
          text: "Centro de Costo",
          align: "left",
          sortable: true,
          value: "sku",
        },
        {
          text: "Ingreso",
          align: "right",
          sortable: true,
          value: "barcode",
        },
        {
          text: "Egreso",
          align: "right",
          sortable: true,
          value: "name",
        },
        {
          text: "Saldo",
          align: "right",
          sortable: true,
          value: "category",
        },
        {
          text: "",
          align: "left",
          sortable: true,
          value: "data-table-expand",
        },
      ],
      headersHijo: [
        {
          text: "Subcentro de Costo",
          align: "left",
          sortable: true,
          value: "cc_hijo_name",
        },
        {
          text: "Ingreso",
          align: "right",
          sortable: true,
          value: "total_ingreso",
        },
        {
          text: "Egreso",
          align: "right",
          sortable: true,
          value: "total_egreso",
        },
        {
          text: "Saldo",
          align: "right",
          sortable: true,
          value: "total_saldo",
        },
        { text: "" },
      ],
      list: [],
      desde_selected: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().slice(0, 10),
      hasta_selected: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().slice(0, 10),
      desde_selected_open: false,
      hasta_selected_open: false,
      cost_center_selected: null,
      receiver_selected: null,
      receivers: [],
      costs_centers: [],
    };
  },
  mounted() {
    this.loadList(this.service);
    this.loadCostsCenters();
    this.loadReceivers();
  },
  methods: {
    openDetalleModal(item) {
      this.selected_item = item.detalle;
      this.modal_title = "Detalle " + item.cc_hijo_name;
      this.open_modal = true;
    },
    getFilterParams(export_option = "") {
      const param = {
        from: this.desde_selected,
        to: this.hasta_selected,
        cost_center: this.cost_center_selected != null ? this.cost_center_selected.id : null,
        receiver: this.receiver_selected != null ? this.receiver_selected.id : null,
        export_option: export_option,
      };
      return param;
    },
    loadReceivers() {
      emmiterService.filter({ enabled: true }).then((response) => {
        this.receivers = response.result;
      });
    },
    filter() {
      this.loadList(this.service, this.getFilterParams());
    },
    loadCostsCenters() {
      generalService.filter({}, Constant.SECTION_COSTS_CENTERS).then((response) => {
        this.costs_centers = response.result;
      });
    },
    loadList(service, param = null) {
      this.loading = true;

      if (param == null) {
        param = this.getFilterParams();
      }

      this.open_filter = false;
      service
        .query(param, Constant.SECTION_REPORT_COSTS_CENTERS)
        .then((response) => {
          this.list = response.result;
          this.loading = false;

          if (typeof this.selected !== "undefined") {
            this.selected = [];
          }
        })
        .catch((e) => {
          console.log("service.filter error");
          console.log(e);
          this.loading = false;
        });
    },
    exportFile(option) {
      var param = this.getFilterParams(option);
      param["export"] = true;

      var filename = "Reporte-centro-costo.xlsx";
      if (option == "subcost_centes") {
        filename = "Reporte-subcentro-costo.xlsx";
      }
      this.exportExcel(Constant.SECTION_REPORT_COSTS_CENTERS, param, filename);
    },
  },
};
</script>
