<template>
  <v-layout>
    <v-dialog v-bind:value="dialog" @click:outside="$emit('updateDialog', false)" max-width="750px">
      <v-card>
        <v-form v-on:submit.prevent="createItem">
          <v-card-title>
            <span class="headline">{{ modal_title }}</span>
          </v-card-title>

          <v-card-text>
            <!-- START FORM DIALOG -->
            <div class="row">
              <div class="col-md-12">
                <v-checkbox label="¿Es cheque propio?" :disabled="isViewModal() || mainItem.id != ''" v-model="mainItem.is_own"></v-checkbox>
              </div>

              <div class="col-md-6">
                <DatePickerEsp
                  :initToday="true"
                  label="Fecha de emision"
                  :date="mainItem.emission_date"
                  :isRequired="true"
                  name="emission_date"
                  :isViewModal="isViewModal()"
                  @setDate="
                    (date) => {
                      mainItem.emission_date = date;
                    }
                  "
                />
              </div>

              <div class="col-md-6">
                <DatePickerEsp
                  :initToday="true"
                  :label="mainItem.is_own ? 'Fecha de pago' : 'Fecha de cobro'"
                  :date="mainItem.payment_date"
                  :isRequired="true"
                  name="payment_date"
                  :isViewModal="isViewModal()"
                  @setDate="
                    (date) => {
                      mainItem.payment_date = date;
                    }
                  "
                />
              </div>

              <div class="col-md-6">
                <v-text-field
                  autocomplete="off"
                  data-vv-as="Numero de cheque"
                  v-validate="'required'"
                  name="number_cheque"
                  label="Nro de cheque*"
                  :disabled="isViewModal()"
                  v-model="mainItem.number"
                />
                <span class="red--text">{{ errors.first("number_cheque") }}</span>
              </div>

              <div class="col-md-6">
                <v-text-field
                  autocomplete="off"
                  name="importe"
                  label="Importe*"
                  :disabled="isViewModal()"
                  placeholder=""
                  :v-validate="!mainItem.is_own ? 'required|decimal' : ''"
                  type="number"
                  v-model="mainItem.amount"
                />
                <span class="red--text">{{ errors.first("importe") }}</span>
              </div>

              <!--NO Es cheque propio -->
              <div class="col-md-6" v-if="!mainItem.is_own">
                <v-text-field autocomplete="off" name="emitter_name" label="Emisor*" :disabled="isViewModal()" :v-validate="!mainItem.is_own ? 'required' : ''" v-model="mainItem.emitter_name" />
                <span class="red--text">{{ errors.first("emitter_name") }}</span>
              </div>

              <!--Es cheque propio -->
              <div class="col-md-4" v-if="mainItem.is_own">
                <v-combobox
                  v-model="mainItem.money_account"
                  data-vv-as="Cuenta monetaria de entrada"
                  name="money_account"
                  item-text="name"
                  item-value="id"
                  :items="money_accounts"
                  label="Cuenta monetaria de entrada*"
                  autocomplete="off"
                  :v-validate="mainItem.is_own ? 'required' : ''"
                  :disabled="isViewModal()"
                  @change="
                    (val) => {
                      mainItem.bank = getBank();
                    }
                  "
                ></v-combobox>
                <span class="red--text">{{ errors.first("money_account") }}</span>
              </div>

              <div :class="mainItem.is_own ? 'col-md-4' : 'col-md-6'">
                <v-combobox
                  v-model="mainItem.bank"
                  data-vv-as="Banco"
                  name="bank"
                  item-text="name"
                  item-value="id"
                  :items="banks"
                  label="Banco*"
                  autocomplete="off"
                  v-validate="'required'"
                  :disabled="isViewModal() || mainItem.is_own"
                ></v-combobox>
                <span class="red--text">{{ errors.first("bank") }}</span>
              </div>

              <div class="col-md-4" v-if="mainItem.is_own">
                <v-combobox
                  v-if="mainItem.is_own"
                  v-model="mainItem.emitter"
                  data-vv-as="Emisor"
                  name="emitter"
                  item-text="name"
                  item-value="id"
                  :items="emitters"
                  label="Emisor*"
                  autocomplete="off"
                  :v-validate="mainItem.is_own ? 'required' : ''"
                  :disabled="isViewModal()"
                ></v-combobox>
                <span class="red--text">{{ errors.first("emitter") }}</span>
              </div>

              <div class="col-md-12">
                <v-text-field autocomplete="off" label="Observaciones" :disabled="isViewModal()" v-model="mainItem.observations" />
              </div>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('updateDialog', false)">Cerrar</v-btn>
            <v-btn color="success" text type="submit" v-if="!isViewModal()" :loading="loading">{{ formBtn }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import Constant from "@/constants/sections";
import { generalService } from "@/libs/ws/generalService";
import { emmiterService } from "@/libs/ws/emmiterService";
import ModalGenericMixin from "@/mixins/ModalGenericMixin.vue";
import DatePickerEsp from "@/components/DatePickerEsp.vue";

export default {
  mixins: [ModalGenericMixin],
  components: { DatePickerEsp },
  props: {
    service: Object,
    modal_title: String,
    dialog: Boolean,
    item: {
      type: Object,
      default: null,
    },
    button_name: String,
    formBtn: String,
    showCreateButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      show: false,
      money_accounts: [],
      emitters: [],
      banks: [],
      mainItem: {
        id: "",

        //NO propio
        emitter_name: "",

        //SI propio
        emitter: null,
        money_account: null,

        number: "",
        amount: "",
        emission_date: null,
        bank: null,
        payment_date: null,

        observations: null,
        state: null,

        is_own: false,
      },
      defaultItem: null,
    };
  },
  watch: {
    item() {
      this.$emit("updateItem", this.item);
      if (this.item != null) this.mainItem = JSON.parse(JSON.stringify(this.item));
    },
    dialog() {
      if (this.dialog && this.item == null) {
        this.mainItem = JSON.parse(JSON.stringify(this.defaultItem));
        this.$validator.reset();
      }
      this.errors.clear();
    },
  },
  mounted() {
    this.defaultItem = JSON.parse(JSON.stringify(this.mainItem));
    //Get MoneyAccounts that has bank setted
    this.loadMonetaryAccounts();
    this.loadEmmiters();
    this.loadBanks();
  },
  methods: {
    loadMonetaryAccounts() {
      generalService.setSection(Constant.SECTION_MONEY_ACCOUNTS);

      generalService
        .filter({ has_bank: true })
        .then((response) => {
          this.money_accounts = response.result;
          this.loading = false;
        })
        .catch((e) => {
          console.log("money_accounts.filter error");
          console.log(e);
          this.loading = false;
        });
    },
    loadBanks() {
      generalService.setSection(Constant.SECTION_BANKS);

      generalService
        .filter({ has_bank: true })
        .then((response) => {
          this.banks = response.result;
          this.loading = false;
        })
        .catch((e) => {
          console.log("banks.filter error");
          console.log(e);
          this.loading = false;
        });
    },
    loadEmmiters() {
      emmiterService
        .filter()
        .then((response) => {
          this.emitters = response.result;
          this.loading = false;
        })
        .catch((e) => {
          console.log("emitters.filter error");
          console.log(e);
          this.loading = false;
        });
    },
    getBank() {
      if (this.mainItem.money_account != null && this.mainItem.money_account.bank != null) {
        return this.mainItem.money_account.bank;
      }
      return null;
    },
    isViewModal() {
      return this.formBtn == "";
    },
    createItem(e) {
      generalService.setSection(Constant.SECTION_CHECKS);
      if (this.loading) return;

      this.$validator.validate().then((valid) => {
        if (valid) {
          e.preventDefault();
          this.loading = true;
          if (this.mainItem.id > 0) {
            this.service
              .update(this.mainItem)
              .then((response) => {
                if (response.status == true) {
                  this.$toast.success("El Cheque se modifico correctamente.", "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    this.$toast.error(response.msg, "Error");
                  }
                }
              })
              .catch((response) => {
                this.$toast.error(response.msg, "Error");
              })
              .finally(() => (this.loading = false));
          } else {
            this.service
              .create(this.mainItem)
              .then((response) => {
                if (response.status == true) {
                  this.$toast.success("El Cheque se creo correctamente", "OK");
                  this.$emit("callbackItem", response.result);
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    this.$toast.error(response.msg, "Error");
                  }
                }
              })
              .catch((response) => {
                console.log(response);
                this.$toast.error(response.msg, "Error");
              })
              .finally(() => (this.loading = false));
          }
        } else {
          console.log(this.errors);
          console.log(this.mainItem);
          let msg = "";
          this.errors.items.forEach((e) => {
            msg += e.msg + "\n";
          });
          this.$toast.error(msg, "Error");
        }
      });
    },
  },
};
</script>
