<template>
  <v-dialog v-model="show" max-width="700px" @keydown.esc="close()">
    <v-card>
      <v-card-title>
        <span class="headline">Historial</span>
      </v-card-title>

      <v-card-text>
        <v-data-table v-if="check != null" :headers="headers" :items="check.history" item-key="id">
          <template v-slot:item="props">
            <tr>
              <td>{{ props.item.date }}</td>
              <td>
                <span :class="getClassState(props.item.state)">{{ props.item.state }}</span>
              </td>
              <td>
                <v-tooltip bottom v-if="props.item.state != ChequesState.STATE_EN_CARTERA && props.item.state != ChequesState.STATE_UTILIZADO && props.item.state == check.state"><!--Solo se puede revertir el estado actual -->
                  <template v-slot:activator="{ on }">
                    <v-icon :disabled="loading" v-on="on" small color="red" class="mr-2" @click="rollbackState(check)"> delete </v-icon>
                  </template>
                  <span>Revertir</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" text @click="$emit('close')">Cerrar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ChequesState from "@/constants/chequesStates";
import { checkService } from "@/libs/ws/checkService";
export default {
  name: "HistoryModal",
  props: {
    show: Boolean,
    check: Object,
    refresh: Function,
    close: Function,
  },
  data() {
    return {
      headers: [
        {
          text: "Fecha",
          align: "left",
          sortable: true,
          value: "date",
        },
        { text: "Estado", value: "" },
        { text: "Accion", value: "action" },
      ],
      ChequesState: ChequesState,
      loading: false,
    };
  },
  mounted() {},
  computed: {},
  methods: {
    getClassState(state) {
      return checkService.getClassState(state);
    },
    rollbackState(check) {
      if(this.loading) return;

      this.loading = true;
      checkService
        .rollbackState({ 
          id: check.id,
          state_to_rollback: check.state,
        })
        .then((res) => {
          console.log(res)
          this.$toast.success("El Cheque se modifico correctamente.", "OK");
          this.$emit("updateDialog", false);
          this.$emit("loadList");
          this.$emit("close");
        })
        .catch((err) => {
          this.$toast.error(err.msg, "Error");
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
