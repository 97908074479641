<template>
  <v-dialog v-model="show" max-width="500">
    <v-card>
      <v-card-title class="headline">Cuentas Conectadas</v-card-title>

      <v-card-text>
        <div class="row mt-4" v-for="(item, index) in accounts" :key="index" :style="index + 1 != accounts.length ? 'border-bottom:solid 1px rgba(0,0,0,.12)' : ''">
          <div class="col-md-2 d-flex align-items-center">
            <v-avatar color="primary" size="45">
              <span class="white--text headline">{{ item.nickname_ml[0].toUpperCase() }}</span>
            </v-avatar>
          </div>
          <div class="col-md-8 d-flex align-items-center pl-0">
            <p class="mb-0">{{ item.nickname_ml }}</p>
          </div>
          <div class="col-md-2 d-flex align-items-center">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <font-awesome-icon icon="times" v-on="on" @click="desvincularClick(item.id)" style="cursor: pointer" />
              </template>
              <span>Desvincular</span>
            </v-tooltip>
          </div>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="red darken-1" text @click="$emit('close')"> Cerrar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { integrationsService } from "@/libs/ws/integrationsService";
import GenericMixin from "@/mixins/GenericMixin.vue";
import RolMixin from "@/mixins/RolMixin.vue";
export default {
  components: {},
  mixins: [GenericMixin, RolMixin],
  props: {
    show: Boolean,
    close: Function,
    reload: Function,
    accounts: Array,
  },
  data() {
    return {
      loading_desvincular: false,
    };
  },
  mounted() {},
  watch: {},
  methods: {
    desvincularClick(account) {
      //desvincular
      integrationsService
        .desvincular({ id: account })
        .then((response) => {
          if (response.status === true) {
            this.$toast.success(response.msg, "OK");
            this.$emit("close");
            this.$emit("reload");
          } else {
            this.$toast.error(response.msg, "Error");
          }
        })
        .catch((e) => {
          console.log("service.filter error");
          console.log(e);
          this.$toast.error("Error exception", "Error");
        });
    },
  },
};
</script>
