<template>
  <v-layout>
    <v-dialog v-bind:value="show" @click:outside="$emit('close')" max-width="550px">
      <v-card>
        <v-form v-on:submit.prevent="aceptar">
          <v-card-title>
            <span class="headline">Aumento masivo de precios</span>
          </v-card-title>

          <v-card-text>
            <!-- START FORM DIALOG -->
            <div class="row">
              <div class="col-md-12">
                <v-text-field
                  data-vv-as="porcentaje"
                  label="Porcentaje*"
                  name="porcentaje"
                  v-model="item.percentage"
                  v-validate="'required|decimal'"
                  hint="Indique el porcentaje de aumento o descuento que quiera aplicar sobre los productos."
                  persistent-hint
                  class="mb-5"
                ></v-text-field>
                <span class="red--text">{{ errors.first("porcentaje") }}</span>

                <v-combobox
                  class="mt-3 mb-5"
                  v-model="item.categories"
                  data-vv-as="Rubro"
                  name="category"
                  item-text="name"
                  item-value="id"
                  :items="categories"
                  label="Rubros"
                  autocomplete="off"
                  chips
                  hint="Si desea puede seleccionar un rubro especifico para aplicar el aumento o descuento. Caso contrario se aplicara a todos los rubros."
                  persistent-hint
                  multiple
                ></v-combobox>
                <span class="red--text">{{ errors.first("category") }}</span>

                <div class="row">
                  <div class="col-md-12">
                    <label class="subtitle-2 mt-3">Listas de precios</label>
                    <v-divider></v-divider>
                    <div v-for="list of seleted" :key="list.id">
                      <v-chip small class="mt-1">{{ list.name }} </v-chip>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('close')">Cerrar</v-btn>
            <v-btn color="success" text type="submit" :loading="loading">Aceptar</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import ModalGenericMixin from "@/mixins/ModalGenericMixin.vue";
import { generalService } from "@/libs/ws/generalService";
import { priceListService } from "@/libs/ws/priceListService";
import ConstantSections from "@/constants/sections";
export default {
  mixins: [ModalGenericMixin],
  props: {
    show: Boolean,
    close: Function,
    refresh: Function,
    seleted: Array,
  },
  data() {
    return {
      loading: false,
      item: {
        percentage: 0,
        categories: [],
        prices_list: [],
      },
      categories: [],
    };
  },
  watch: {
    show(val) {
      if (val) {
        this.$validator.reset();
      }
    },
  },
  mounted() {
    this.loadComboData();
  },
  methods: {
    loadComboData() {
      generalService.filter({}, ConstantSections.SECTION_CATEGORIES).then((response) => {
        this.categories = response.result;
      });
    },
    aceptar() {
      this.loading = true;
      const prices_list_ids = this.seleted.map((item) => item.id);
      const categories_ids = this.item.categories.map((item) => item.id);
      
      const param = {
        percentage: this.item.percentage,
        categories: categories_ids,
        prices_list: prices_list_ids
      };

      priceListService
        .bulk_update(param)
        .then((response) => {
          if (response.status === true) {
            this.$toast.success(response.msg, "OK");
            this.$emit("close", true);
            this.$emit("refresh");
            this.item = {
              percentage: 0,
              categories: [],
              prices_list: [],
            };
          } else {
            if (Object.prototype.hasOwnProperty.call(response, "message")) {
              this.$toast.error(response.message, "Error");
            } else {
              this.$toast.error(response.msg, "Error");
            }
          }
        })
        .catch((e) => {
          console.log("checkService.changeState error");
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
