<template>
  <v-layout>
    <v-dialog v-bind:value="dialog" @click:outside="$emit('updateDialog', false)" max-width="550px">
      <template v-slot:activator="{ on }">
        <v-btn color="primary" class="mb-5" dark v-on="on" @click="$emit('updateDialog', true)">{{ button_name }}</v-btn>
      </template>

      <v-card>
        <v-form v-on:submit.prevent="createItem">
          <v-card-title>
            <span class="headline">{{ modal_title }}</span>
          </v-card-title>

          <v-card-text>
            <!-- START FORM DIALOG -->
            <div class="row">
              <div class="col-md-12">
                <v-text-field data-vv-as="nombre" label="Nombre*" name="name" v-model="item.name" v-validate="'required'" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("name") }}</span>

                <v-text-field
                  v-if="item.id <= 0"
                  data-vv-as="porcentaje"
                  label="Porcentaje*"
                  name="porcentaje"
                  v-model="item.percentage"
                  v-validate="'required|decimal'"
                  :disabled="isViewModal()"
                  hint="Indique, si desea, un porcentaje con el cual se creara la lista de precios teniendo en cuenta el precio base de cada producto."
                  persistent-hint
                ></v-text-field>
                <span class="red--text">{{ errors.first("porcentaje") }}</span>

                <v-checkbox label="Habilitado" v-model="item.enabled"></v-checkbox>
              </div>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('updateDialog', false)">Cerrar</v-btn>
            <v-btn color="success" text type="submit" v-if="!isViewModal()" :loading="loading">{{ formBtn }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import ModalGenericMixin from "@/mixins/ModalGenericMixin.vue";

export default {
  mixins: [ModalGenericMixin],
  props: {
    parent: Object,
    service: Object,
    modal_title: String,
    dialog: Boolean,
    item: Object,
    button_name: String,
    formBtn: String,
  },
  data() {
    return {
      show: false,
    };
  },
  watch: {
    item() {
      this.$emit("updateItem", this.item);
    },
    dialog() {
      this.errors.clear();
    },
  },
  mounted() {},
  methods: {
    isViewModal() {
      return this.formBtn == "";
    },
    createItem(e) {
      if (this.loading) return;
      this.$validator.validate().then((valid) => {
        if (valid) {
          e.preventDefault();

          this.loading = true;
          //Se crea data para reemplazar criteria_price co nel id
          const data = {
            id: this.item.id,
            name: this.item.name,
            percentage: this.item.percentage,
            enabled: this.item.enabled ? 1 : 0,
          };

          if (this.item.id > 0) {
            this.service
              .update(data)
              .then((response) => {
                if (response.status == true) {
                  this.$toast.success("El " + this.modal_title + " se modifico correctamente.", "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    this.$toast.error(response.msg, "Error");
                  }
                }
              })
              .catch((response) => {
                this.$toast.error(response.msg, "Error");
              })
              .finally(() => (this.loading = false));
          } else {
            this.service
              .create(data)
              .then((response) => {
                console.log(response);
                if (response.status) {
                  this.$toast.success("El " + this.modal_title + " se creo correctamente", "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    this.$toast.error(response.msg, "Error");
                  }
                }
              })
              .catch((response) => {
                console.log(response);
                this.$toast.error(response.msg, "Error");
              })
              .finally(() => (this.loading = false));
          }
        }
      });
    },
  },
};
</script>
