<template>
  <div>
    <Title
      :pages="[
        { icon: 'file-invoice', path: '/clients', page: 'CLIENTES' },
        { icon: 'file-invoice', page: page_title.toUpperCase() },
      ]"
    >
    </Title>

    <v-container fluid>
      <v-card v-if="list_access">
        <v-card-title>
          <div class="col-md-8 pb-0 pl-0">
            <v-chip outlined color="primary"
              >Comprobantes pendiente de cobro:&nbsp;<span>{{ formatUtils.formatPrice(total) }}</span></v-chip
            >
            <v-chip class="ml-2" outlined color="blue-grey">
              Cobros a cuenta:&nbsp;<span class="">{{ formatUtils.formatPrice(total_cobro_a_cuenta) }}</span></v-chip
            >
          </div>
          <div class="col-md-4 pb-0">
            <v-text-field v-model="search" append-icon="search" label="Buscar" single-line hide-details></v-text-field>
          </div>
        </v-card-title>

        <v-col md="12">
          <v-btn color="blue-grey" small dark :loading="dialog_export" @click="exportFile()"> Exportar <font-awesome-icon class="ml-2" icon="download" /></v-btn>
        </v-col>

        <v-data-table
          :headers="headers"
          :items="list"
          :search="search"
          :loading="loading"
          loading-text="Cargando..."
          :options="{
            descending: true,
            itemsPerPage: 15,
          }"
          @pagination="updatePagination"
          :footer-props="{
            itemsPerPageOptions: [5, 10, 15, 50, -1],
          }"
        >
          <template v-slot:item="props">
            <tr>
              <td>{{ props.item.cbte_fch }}</td>
              <td>{{ props.item.voucher_number_label }}</td>
              <td>{{ props.item.receiver_name }}</td>
              <td>{{ props.item.mon_name }}</td>
              <td class="text-right">{{ formatUtils.formatPrice(props.item.total_factura) }}</td>
              <td class="text-right">{{ formatUtils.formatPrice(props.item.total_cobrado_de_factura) }}</td>
              <td class="text-right text-danger">{{ formatUtils.formatPrice(props.item.total_pendiente) }}</td>
              <td v-html="getStatus(props.item.expiration)"></td>
            </tr>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              {{ "Busqueda sin resultados - " + search }}
            </v-alert>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import Constant from "@/constants/sections";
import { formatUtils } from "@/libs/utils/formatUtils";
import { reportService } from "@/libs/ws/reportService";
import { chargeService } from "@/libs/ws/chargeService";
import GenericMixin from "@/mixins/GenericMixin.vue";
import Title from "@/components/Title.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import ExportMixin from "@/mixins/ExportMixin.vue";
export default {
  components: { Title },
  mixins: [GenericMixin, RolMixin, ExportMixin],
  data() {
    return {
      Constant: Constant,
      formatUtils: formatUtils,
      service: reportService,
      page_title: "Comprobantes pendientes de cobro",
      headers: [
        { text: "Fecha", align: "left", sortable: true, value: "cbte_fch" },
        { text: "Comprobante", align: "left", sortable: true, value: "voucher_number_label" },
        { text: "Receptor", align: "left", sortable: true, value: "receiver.name" },
        { text: "Moneda", align: "left", sortable: true, value: "mon_name" },
        { text: "Total comprobante", align: "center", sortable: true, value: "total_factura" },
        { text: "Total cobrado", align: "center", sortable: true, value: "total_cobrado_de_factura" },
        { text: "Total pendiente", align: "center", sortable: true, value: "total_pendiente" },
        { text: "Estado", align: "center", sortable: true, value: "expiration" },
      ],
      list: [],
      total: 0,
      total_cobro_a_cuenta: 0,
    };
  },
  mounted() {
    this.loadList(this.service);
    this.getTotalCobroACuenta();
  },
  methods: {
    getStatus(expiration) {
      if (expiration > 0) {
        return '<span class="por_vencer">Vence en ' + expiration + " días</span>";
      } else if (expiration == 0) {
        return '<span class="vence_hoy">Vence hoy</span>';
      } else if (expiration < 0) {
        return '<span class="vencida">Vencida hace ' + expiration * -1 + " días</span>";
      } else {
        return "";
      }
    },
    getTotalCobroACuenta() {
      this.total_cobro_a_cuenta = 0;
      var param = {
        client: this.$route.params.client_id,
        cobranzas_a_cuenta: true,
      };
      chargeService
        .filter(param)
        .then((response) => {
          response.result.forEach((element) => {
            this.total_cobro_a_cuenta = (parseFloat(this.total_cobro_a_cuenta) + parseFloat(element.cobranza_a_cuenta)).toFixed(2);
          });
        })
        .catch((e) => {
          this.total_cobro_a_cuenta = 0;
        });
    },
    getTotal() {
      this.total = 0;
      if (this.list.length > 0) {
        this.list.forEach((element) => {
          this.total = (parseFloat(this.total) + parseFloat(element.total_pendiente)).toFixed(2);
        });
      }
    },
    getFilterParams() {
      const param = {
        client: this.$route.params.client_id,
      };
      return param;
    },
    loadList(service, param = null) {
      this.loading = true;

      if (param == null) {
        param = this.getFilterParams();
      }

      this.open_filter = false;
      service
        .pendingVoucherClient(param)
        .then((response) => {
          this.list = response.result;
          this.loading = false;
          this.getTotal();
          if (typeof this.selected !== "undefined") {
            this.selected = [];
          }
        })
        .catch((e) => {
          console.log("service.filter error");
          console.log(e);
          this.total = 0;
          this.loading = false;
        });
    },
    exportFile() {
      var param = this.getFilterParams();
      param["export"] = true;
      this.exportExcel(Constant.SECTION_REPORTS + "/client/pending-vouchers", param, "Comprobantes-Pendientes-Cobro.xlsx");
    },
  },
};
</script>
