const retentions = [
    { Id: 900, Desc: "Retencion IIBB" },
    { Id: 901, Desc: "Retencion IVA" },    
    { Id: 902, Desc: "Retencion Ganancias" },
    { Id: 903, Desc: "Retencion SUSS" },
    { Id: 904, Desc: "Retencion Seguridad e Higiene" },
    { Id: 999, Desc: "Otros" },
  ];

export default {
  retentions: retentions,
};
