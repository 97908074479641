<template>
  <div style="padding: 20px; min-height: 630px; background-color: rgba(221, 225, 230, 1); border: solid 1px #ccc">
    <div class="row">
      <div class="col-md-12">
        <span class="title_bi mr-4">Tus Cobros</span>
        <input name="desde" type="date" v-model="from_date" class="mt-1" style="font-size: 14px; cursor: pointer" title="Desde" @change="fillCobros" />
        -
        <input name="hasta" type="date" v-model="to_date" class="mt-1" style="font-size: 14px; cursor: pointer" title="Hasta" @change="fillCobros" />
        -
        <div style="max-width: 90px; display: inline-block" class="ml-2">
          <v-combobox v-model="currency" autocomplete="off" item-text="name" item-value="id" :items="monedas" class="pt-0 mt-0" dense @change="fillCobros"></v-combobox>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8">
        <v-card style="min-height: 400px">
          <v-card-title> </v-card-title>
          <div class="col-md-12">
            <v-chip outlined color="primary"> Volumen de cobros: {{ currency.name }} {{ new Intl.NumberFormat("en-EN").format(total_cobros) }} </v-chip>
            <v-chip outlined class="ml-2"> Cantidad de comprobantes: {{ cant_comprobantes }} </v-chip>
          </div>

          <apexchart type="area" height="250" ref="statCobros" :options="chartOptionsCobros" :series="seriesCobros"></apexchart>
        </v-card>
      </div>
      <div class="col-md-4">
        <v-card style="height: 192px">
          <v-card-title class="pb-0">
            <div class="col-md-12">
              <p class="title_bi mb-0">Pendiente de cobro</p>
              <p class="caption">Total pendiente de cobro en el periodo seleccionado</p>
            </div>
          </v-card-title>

          <v-card-text>
            <div class="row">
              <div class="col p-12">
                <p class="text-center text-h5 font-weight-bold">${{ new Intl.NumberFormat("en-EN").format(total_pendiente_cobro_periodo) }}</p>
              </div>
            </div>
          </v-card-text>
        </v-card>
        <v-card class="mt-4" style="height: 192px">
          <v-card-title class="pb-0">
            <div class="col-md-12">
              <p class="title_bi mb-0">Pendiente de cobro</p>
              <p class="caption">Total pendiente de cobro global</p>
            </div>
          </v-card-title>

          <v-card-text>
            <div class="row">
              <div class="col p-12">
                <p class="text-center text-h5 font-weight-bold">${{ new Intl.NumberFormat("en-EN").format(total_pendiente_cobro_global) }}</p>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </div>
    <v-divider></v-divider>
    <div class="row">
      <div class="col-md-12">
        <span class="title_bi mr-4">Tus Pagos</span>
        <input name="desde" type="date" v-model="from_date_pago" class="mt-1" style="font-size: 14px; cursor: pointer" title="Desde" @change="fillPagos" />
        -
        <input name="hasta" type="date" v-model="to_date_pago" class="mt-1" style="font-size: 14px; cursor: pointer" title="Hasta" @change="fillPagos" />
        -
        <div style="max-width: 90px; display: inline-block" class="ml-2">
          <v-combobox v-model="currency_pago" autocomplete="off" item-text="name" item-value="id" :items="monedas" class="pt-0 mt-0" dense @change="fillPagos"></v-combobox>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8">
        <v-card style="min-height: 400px">
          <v-card-title> </v-card-title>
          <div class="col-md-12">
            <v-chip outlined color="primary"> Volumen de pagos: {{ currency_pago.name }} {{ new Intl.NumberFormat("en-EN").format(total_pagos) }} </v-chip>
          </div>

          <apexchart type="area" height="250" ref="statPagos" :options="chartOptionsPagos" :series="seriesPagos"></apexchart>
        </v-card>
      </div>
      <div class="col-md-4">
        <v-card style="height: 192px">
          <v-card-title class="pb-0">
            <div class="col-md-12">
              <p class="title_bi mb-0">Pendiente de pago</p>
              <p class="caption">Total pendiente de pago en el periodo seleccionado</p>
            </div>
          </v-card-title>

          <v-card-text>
            <div class="row">
              <div class="col p-12">
                <p class="text-center text-h5 font-weight-bold">${{ new Intl.NumberFormat("en-EN").format(total_pendiente_pago_periodo) }}</p>
              </div>
            </div>
          </v-card-text>
        </v-card>
        <v-card class="mt-4" style="height: 192px">
          <v-card-title class="pb-0">
            <div class="col-md-12">
              <p class="title_bi mb-0">Pendiente de pago</p>
              <p class="caption">Total pendiente de pago global</p>
            </div>
          </v-card-title>

          <v-card-text>
            <div class="row">
              <div class="col p-12">
                <p class="text-center text-h5 font-weight-bold">${{ new Intl.NumberFormat("en-EN").format(total_pendiente_pago_global) }}</p>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { metricsService } from "@/libs/ws/metricsService.js";
import { generalService } from "@/libs/ws/generalService";
import Constant from "@/constants/sections";
export default {
  components: {},
  data() {
    return {
      loading: false,
      menu: false,
      service: metricsService,
      from_date: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().slice(0, 10),
      to_date: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().slice(0, 10),
      currency: { id: "ARS", name: "ARS" },
      from_date_pago: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().slice(0, 10),
      to_date_pago: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().slice(0, 10),
      currency_pago: { id: "ARS", name: "ARS" },
      total_cobros: 0,
      total_pagos: 0,
      cant_comprobantes: 0,
      monedas: metricsService.getMonedas(),
      total_pendiente_cobro_periodo: 0,
      total_pendiente_cobro_global: 0,
      total_pendiente_pago_periodo: 0,
      total_pendiente_pago_global: 0,
      headers: [
        { text: "Ene", sortable: false },
        { text: "Feb", sortable: false },
        { text: "Mar", sortable: false },
        { text: "Abr", sortable: false },
        { text: "May", sortable: false },
        { text: "Jun", sortable: false },
        { text: "Jul", sortable: false },
        { text: "Ago", sortable: false },
        { text: "Sep", sortable: false },
        { text: "Oct", sortable: false },
        { text: "Nov", sortable: false },
        { text: "Dic", sortable: false },
      ],
      months: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
      seriesPagos: [
        {
          name: "Gastos",
          data: [],
        },
      ],
      seriesCobros: [
        {
          name: "Facturación",
          data: [],
        },
      ],
      chartOptionsCobros: {
        chart: {
          type: "area",
          height: 180,
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
          width: 2,
        },
        colors: ["#4a7def", "#E91E63", "#9C27B0"],
        labels: [],
        legend: {
          horizontalAlign: "left",
        },
        yaxis: {
          labels: {
            formatter: function (val, index) {
              return new Intl.NumberFormat("en-EN").format(val);
            },
          },
        },
        xaxis: {
          categories: [],
          tickAmount: 3,
        },
      },
      chartOptionsPagos: {
        chart: {
          type: "area",
          height: 180,
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
          width: 2,
        },
        colors: ["#4a7def", "#E91E63", "#9C27B0"],
        labels: [],
        legend: {
          horizontalAlign: "left",
        },
        yaxis: {
          labels: {
            formatter: function (val, index) {
              return new Intl.NumberFormat("en-EN").format(val);
            },
          },
        },
        xaxis: {
          categories: [],
          tickAmount: 3,
        },
      },
    };
  },
  mounted() {
    this.fillCobros();
    this.fillPagos();
  },
  watch: {},
  methods: {
    fillPagos() {
      this.getPagos();
      this.getPendienteDePagoPeriodo();
      this.getPendienteDePagoGlobal();
    },
    fillCobros() {
      this.getCobros();
      this.getPendienteDeCobroPeriodo();
      this.getPendienteDeCobroGlobal();
    },
    getPendienteDeCobroPeriodo() {
      this.loading = true;

      this.total_pendiente_cobro_periodo = 0;

      metricsService
        .getPendienteDeCobro({
          from_date: this.from_date,
          to_date: this.to_date,
          currency: this.currency.id,
        })
        .then((response) => {
          if(response.status === false){
            return;
          }
          this.total_pendiente_cobro_periodo = response.result;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);          
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getPendienteDeCobroGlobal() {
      this.loading = true;

      this.total_pendiente_cobro_global = 0;

      metricsService
        .getPendienteDeCobro({
          currency: this.currency.id,
          from_date: null,
          to_date: null,
        })
        .then((response) => {
          if(response.status === false){
            return;
          }
          this.total_pendiente_cobro_global = response.result;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);          
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getPendienteDePagoPeriodo() {
      this.loading = true;

      this.total_pendiente_pago_periodo = 0;

      metricsService
        .getPendienteDePago({
          from_date: this.from_date,
          to_date: this.to_date,
          currency: this.currency.id,
        })
        .then((response) => {
          if(response.status === false){
            return;
          }
          this.total_pendiente_pago_periodo = response.result;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);          
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getPendienteDePagoGlobal() {
      this.loading = true;

      this.total_pendiente_pago_global = 0;

      metricsService
        .getPendienteDePago({
          currency: this.currency.id,
          from_date: null,
          to_date: null,
        })
        .then((response) => {
          if(response.status === false){
            return;
          }
          this.total_pendiente_pago_global = response.result;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);          
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getCobros() {
      this.loading = true;

      this.total_cobros = 0;
      this.cant_comprobantes = 0;

      metricsService
        .getCobros({
          from_date: this.from_date,
          to_date: this.to_date,
          currency: this.currency.id,
        })
        .then((response) => {
          if(response.status === false){
            return;
          }

          var arr = [];
          var xaxis = [];

          response.result.forEach((element) => {
            this.total_cobros = this.total_cobros + parseFloat(element.amount);
            this.cant_comprobantes = this.cant_comprobantes + parseInt(element.cantidad);
            arr.push(element.amount);
            xaxis.push(this.getDateFormat(element.cbte_fch));
          });

          this.seriesCobros[0].data = arr;
          this.$refs.statCobros.updateSeries(this.seriesCobros, false, true);
          this.$refs.statCobros.updateOptions({
            xaxis: {
              categories: xaxis,
              tickAmount: 10,
            },
          });
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);          
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getDateFormat(date) {
      var date_aux = date.split("-");
      var d = new Date(date);
      return date_aux[2] + " " + this.months[d.getMonth()];
    },

    getPagos() {
      this.loading = true;

      this.total_pagos = 0;

      metricsService
        .getPagos({
          from_date: this.from_date_pago,
          to_date: this.to_date_pago,
          currency: this.currency_pago.id,
        })
        .then((response) => {
          if(response.status === false){
            return;
          }

          var arr = [];
          var xaxis = [];

          response.result.forEach((element) => {
            this.total_pagos = this.total_pagos + parseFloat(element.amount);
            arr.push(element.amount);
            xaxis.push(this.getDateFormat(element.cbte_fch));
          });

          this.seriesPagos[0].data = arr;
          this.$refs.statPagos.updateSeries(this.seriesPagos, false, true);
          this.$refs.statPagos.updateOptions({
            xaxis: {
              categories: xaxis,
              tickAmount: 10,
            },
          });
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
