<template>
  <v-col md="6">
    <v-card elevation="1" class="mb-4" style="height: 300px">
      <v-card-text>
        <h6 class="text-h6 black--text">Totales</h6>
        <p class="text-body-2">Detalle de totales que componen la factura</p>

        <v-row>
          <v-col md="12" class="pb-0 pt-1">
            <span class="float-right">Total Tributos: {{ formatUtils.formatPrice(imp_trib) }} </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="12" class="pb-0 pt-1">
            <span class="float-right">Total IVA: {{ formatUtils.formatPrice(imp_iva) }} </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="12" class="pb-0 pt-1">
            <span class="float-right">Total Gravado: {{ formatUtils.formatPrice(imp_neto) }} </span></v-col
          >
        </v-row>
        <v-row>
          <v-col md="12" class="pb-0 pt-1">
            <span class="float-right">Total No Gravado: {{ formatUtils.formatPrice(imp_tot_conc) }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="12" class="pb-0 pt-1">
            <span class="float-right">Total Excento: {{ formatUtils.formatPrice(imp_op_ex) }} </span></v-col
          >
        </v-row>        
        <v-row>
          <v-col md="12">
            <v-chip class="float-right" outlined color="primary">Total: {{ formatUtils.formatPrice(imp_total) }} </v-chip>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-col>
</template>
<script>
import { emmiterService } from "@/libs/ws/emmiterService";
import { formatUtils } from "@/libs/utils/formatUtils";
export default {
  components: {},
  props: { invoice: Object, imp_iva: Number, imp_neto: Number, imp_tot_conc: Number, imp_op_ex: Number, imp_total: Number, imp_trib: Number, imp_discount: Number },
  data: () => ({
    formatUtils: formatUtils,
    emmiterService: emmiterService,
  }),
  watch: {},
  mounted() {},
  methods: {},
};
</script>
