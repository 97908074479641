<template>
  <v-layout>
    <v-dialog v-bind:value="dialog" @click:outside="$emit('updateDialog', false)" max-width="750px">
      <template v-slot:activator="{ on }">
        <v-btn color="primary" class="mb-5" dark v-on="on" @click="$emit('updateDialog', true)">{{ button_name }}</v-btn>
      </template>

      <v-card>
        <v-form v-on:submit.prevent="createItem">
          <v-card-title>
            <span class="headline">{{ modal_title }}</span>
          </v-card-title>

          <v-card-text>
            <!-- START FORM DIALOG -->
            <div class="row">
              <div class="col-md-12">
                <v-text-field data-vv-as="name" label="Nombre*" name="name" v-model="item.name" v-validate="'required'" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("name") }}</span>

                <v-combobox
                  v-model="item.emitter"
                  data-vv-as="emisor"
                  name="emitter"
                  item-text="name"
                  item-value="id"
                  :items="emitters"
                  label="Empresa*"
                  autocomplete="off"
                  v-validate="'required'"
                  :loading="loading_emitters"
                  :disabled="isViewModal()"
                ></v-combobox>
                <span class="red--text">{{ errors.first("emitter") }}</span>

                <v-combobox
                  v-model="item.bank"
                  data-vv-as="banco"
                  name="bank"
                  item-text="name"
                  item-value="id"
                  :items="banks"
                  label="Banco"
                  autocomplete="off"
                  :loading="loading_banks"
                  :disabled="isViewModal()"
                ></v-combobox>
                <span class="red--text">{{ errors.first("banco") }}</span>
              </div>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('updateDialog', false)">Cerrar</v-btn>
            <v-btn color="success" text type="submit" v-if="!isViewModal()" :loading="loading">{{ formBtn }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import Constant from "@/constants/sections";
import ModalGenericMixin from "@/mixins/ModalGenericMixin.vue";
import { emmiterService } from "@/libs/ws/emmiterService";
import { generalService } from "@/libs/ws/generalService";

export default {
  mixins: [ModalGenericMixin],
  props: {
    parent: Object,
    service: Object,
    modal_title: String,
    dialog: Boolean,
    item: Object,
    button_name: String,
    formBtn: String,
  },
  data() {
    return {
      show: false,
      emitters: [],
      loading_emitters: false,
      banks: [],
      loading_banks: false,
    };
  },
  watch: {
    item() {
      this.$emit("updateItem", this.item);
    },
    dialog() {
      this.errors.clear();
    },
  },
  mounted() {
    this.loadComboData();
  },
  methods: {
    loadComboData() {
      this.loading_emitters = true;
      emmiterService
        .filter()
        .then((response) => {
          this.emitters = response.result;
        })
        .finally(() => {
          this.loading_emitters = false;
        });

      this.loading_banks = true;
      generalService.setSection(Constant.SECTION_BANKS);
      generalService
        .filter({ has_bank: true })
        .then((response) => {
          this.banks = response.result;
        })
        .catch((e) => {
          console.log("banks.filter error");
          console.log(e);
        })
        .finally(() => {
          this.loading_banks = false;
        });
    },
    isViewModal() {
      return this.formBtn == "";
    },
    createItem(e) {
      generalService.setSection(Constant.SECTION_MONEY_ACCOUNTS);
      this.$validator.validate().then((valid) => {
        if (valid) {
          e.preventDefault();
          this.loading = true;
          //Se crea data para reemplazar criteria_price co nel id
          var data = {
            id: this.item.id,
            name: this.item.name,
            emitter: this.item.emitter,
            bank: this.item.bank,
          };

          if (this.item.ctacte == null) data["ctacte"] = 0; //Inicializar

          if (this.item.id > 0) {
            this.service
              .update(data)
              .then((response) => {
                if (response.status == true) {
                  this.$toast.success("El " + this.modal_title + " se modifico correctamente.", "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    this.$toast.error(response.msg, "Error");
                  }
                }
              })
              .catch((response) => {
                this.$toast.error(response.msg, "Error");
              })
              .finally(() => (this.loading = false));
          } else {
            this.service
              .create(data)
              .then((response) => {
                console.log(response);
                if (response.status) {
                  this.$toast.success("El " + this.modal_title + " se creo correctamente", "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    this.$toast.error(response.msg, "Error");
                  }
                }
              })
              .catch((response) => {
                console.log(response);
                this.$toast.error(response.msg, "Error");
              })
              .finally(() => (this.loading = false));
          }
        }
      });
    },
  },
};
</script>
