<template>
  <div>
    <v-subheader class="title-setting">Mi Email</v-subheader>
    <v-form v-on:submit.prevent="save">
      <v-alert style="border-radius: 0px !important" type="info">Configurá el email con el que se enviaran tus correos de Syscore.</v-alert>
      <div class="col-md-12">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-6">
              <v-text-field data-vv-as="email" outlined label="Email" name="email" v-model="mainItem.email_sender" v-validate="'email|required'"></v-text-field>
              <span class="red--text">{{ errors.first("email") }}</span>
            </div>
            <div class="col-md-6">
              <v-text-field data-vv-as="nombre" outlined label="Nombre" name="email_sender_name" v-model="mainItem.email_sender_name" v-validate="validateField"></v-text-field>
              <span class="red--text">{{ errors.first("email_sender_name") }}</span>
            </div>
            <div class="col-md-6">
              <v-text-field
                data-vv-as="servidor de salida"
                outlined
                label="Servidor de salida"
                name="email_host"
                v-model="mainItem.email_host"
                @blur="validateFields('email_host')"
                v-validate="validateField"
              ></v-text-field>
              <span class="red--text">{{ errors.first("email_host") }}</span>
            </div>
            <div class="col-md-6">
              <v-text-field
                data-vv-as="puerto de salida"
                outlined
                label="Puerto de salida"
                name="email_port"
                v-model="mainItem.email_port"
                @blur="validateFields('email_port')"
                v-validate="validateField"
              ></v-text-field>
              <span class="red--text">{{ errors.first("email_port") }}</span>
            </div>
            <div class="col-md-6">
              <v-text-field
                data-vv-as="usuario"
                outlined
                label="Usuario"
                name="email_username"
                v-model="mainItem.email_username"
                @blur="validateFields('email_username')"
                v-validate="validateField"
              ></v-text-field>
              <span class="red--text">{{ errors.first("email_username") }}</span>
            </div>
            <div class="col-md-6">
              <v-text-field
                data-vv-as="contraseña"
                outlined
                label="Contraseña"
                name="email_password"
                v-model="mainItem.email_password"
                @blur="validateFields('email_password')"
                v-validate="validateField"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="show = !show"
                :type="show ? 'text' : 'password'"
              ></v-text-field>
              <span class="red--text">{{ errors.first("email_password") }}</span>
            </div>
            <div class="col-md-6">
              <v-combobox
                data-vv-as="encriptación"
                @blur="validateFields('email_encryption')"
                v-validate="validateField"
                outlined
                v-model="mainItem.email_encryption"
                name="email_encryption"
                item-text="name"
                item-value="id"
                :items="emailEncryptions"
                label="Encriptación"
                autocomplete="off"
              >
              </v-combobox>
              <span class="red--text">{{ errors.first("email_encryption") }}</span>
            </div>
          </div>
        </div>

        <div class="col-md-12 mt-4 text-right">
          <v-btn color="blue" class="text-white" @click="openTestEmailModal" :disabled="isTestEmailDisabled">Probar Envio</v-btn>

          <v-btn color="green darken-1" text :loading="loading" type="submit"> Guardar </v-btn>
        </div>
      </div>
    </v-form>
    <v-dialog v-model="dialog_send_email" max-width="500px">
      <v-card>
        <v-card-title>PROBAR ENVIO DE EMAIL</v-card-title>
        <v-card-text class="mt-4">
          <v-form v-on:submit.prevent="sendTestEmail">
            <v-text-field outlined label="Email" v-model="test_email" v-validate="'required|email'" name="test_email"></v-text-field>
            <span class="red--text">{{ errors.first("test_email") }}</span>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="closeTestEmailModal">Cancelar</v-btn>
          <v-btn color="green darken-1" text @click="sendTestEmail" :loading="loading_send_email">Enviar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    mainItem: Object,
    createItem: Function,
    loading: Boolean,
    service: Object,
  },
  data() {
    return {
      emailEncryptions: [
        {
          id: 1,
          name: "tls",
        },
        {
          id: 2,
          name: "ssl",
        },
      ],
      dialog_send_email: false,
      test_email: "",
      loading_send_email: false,
      validateField: "",
      show: false,
    };
  },

  computed: {
    isTestEmailDisabled() {
      return (
        !this.mainItem.email_sender ||
        !this.mainItem.email_sender_name ||
        !this.mainItem.email_host ||
        !this.mainItem.email_port ||
        !this.mainItem.email_username ||
        !this.mainItem.email_password ||
        !this.mainItem.email_encryption
      );
    },
  },
  methods: {
    async validateFields(field) {
      try {
        const result = await this.$validator.validate(field);
        if (result) {
          this.validateField = "required";
        }
      } catch (error) {
        console.error("Ocurrió un error durante la validación:", error);
      }
    },
    openTestEmailModal() {
      this.$validator.validate().then((valid) => {
        if (valid) {
          this.dialog_send_email = true;
        }
      });
    },
    closeTestEmailModal() {
      this.dialog_send_email = false;
    },
    sendTestEmail() {
      if (this.$validator.validate("test_email")) {
        this.loading_send_email = true;
        const body = {
          email_sender: this.mainItem.email_sender,
          email_sender_name: this.mainItem.email_sender_name,
          email_host: this.mainItem.email_host,
          email_port: this.mainItem.email_port,
          email_username: this.mainItem.email_username,
          email_password: this.mainItem.email_password,
          email_encryption: this.mainItem.email_encryption != null ? this.mainItem.email_encryption.name : null,
          test_email: this.test_email,
        };

        this.service
          .testEmail(body)
          .then((response) => {
            if (response.status == true) {
              this.$toast.success("El email se envió correctamente.", "OK");
              this.closeTestEmailModal();
            } else {
              if (Object.prototype.hasOwnProperty.call(response, "message")) {
                this.$toast.error(response.message, "Error");
              } else {
                this.$toast.error(response.msg, "Error");
              }
            }
            this.loading_send_email = false;
          })
          .catch((response) => {
            this.$toast.error(response.msg, "Error");
            this.loading_send_email = false;
          })
          .finally(() => {
            this.loading_send_email = false;
            this.closeTestEmailModal();
          });
      }
    },
    save(e) {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.$emit("createItem", e);
        }
      });
    },
  },
};
</script>
