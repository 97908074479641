<script>
import { settingsService } from "@/libs/ws/settingsService";
import ConstantSettings from "@/constants/settings";
export default {
  data() {
    return {
      settings: null,
    };
  },
  mounted() {
    this.getSettings();
  },
  methods: {
    getSettings() {
      const settings = localStorage.getItem("settings");

      if (settings != null) {
        this.settings = JSON.parse(settings);
        return;
      }

      this.updateSettings();
    },
    updateSettings(reload = false) {
      settingsService
        .filter({})
        .then((response) => {
          this.settings = response.result;
          localStorage.setItem("settings", JSON.stringify(response.result));
          if (reload) {
            location.reload();
          }
        })
        .catch((e) => {});
    },
    isProductCompany() {
      return this.settings != undefined && this.settings != null && this.settings.business_type == ConstantSettings.business_type_product;
    },
  },
};
</script>
