import { wsService } from "./wsService";

import sections from "@/constants/sections";

const section = sections.SECTION_BANKS_MOVEMENTS;

const NACION = 11;
const BBVA = 17;
const PROVINCIA_BA = 14;

function filter(param = { enable: true }) {
  return wsService.make_post_request(section + "/list", param);
}

function create(param = {}) {
  return wsService.make_post_request(section + "/create", param);
}

function update(param = {}) {
  return wsService.make_put_request(section + "/update", param);
}

function remove(param = {}) {
  return wsService.make_delete_request(section + "/delete", param);
}

function formatDate(date) {
  //caso de fecha en formato objeto
  try {
    if (!isNaN(date.getTime())) {
      // Obtiene el año, el mes y el día de la fecha
      const año = date.getFullYear();
      const mes = String(date.getMonth() + 1).padStart(2, "0"); // Se agrega 1 porque los meses van de 0 a 11
      const dia = String(date.getDate()).padStart(2, "0");

      // Formatea la fecha en el formato YYYY-MM-DD
      return `${año}-${mes}-${dia}`;
    }
  } catch (e) {
    console.log(e);
  }

  //caso de fecha en formato dd-mm-yyy o dd/mm/yyyy
  var parts;
  //caso de fecha en formato dd-mm-yyyy
  if (date.includes("-")) {
    // Dividir el string en día, mes y año
    parts = date.split("-");
    // Crear una nueva fecha con las partes en el orden deseado (año-mes-día)
    return parts[2] + "-" + parts[1] + "-" + parts[0];
  }

  if (date.includes("/")) {
    // Dividir el string en día, mes y año
    parts = date.split("/");
    // Crear una nueva fecha con las partes en el orden deseado (año-mes-día)
    return parts[2] + "-" + parts[1] + "-" + parts[0];
  }
}

function getStartIndex(bank) {
  if (bank == null) {
    return 0;
  }
  switch (bank.code) {
    case BBVA:
      return 7;
    case NACION:
      return 9;
    case PROVINCIA_BA:
      return 2;
    default:
      return 0;
  }
}

function getDate(bank, row, backend_format = false) {
  if (bank == null) {
    return "";
  }
  switch (bank.code) {
    case BBVA:
      return backend_format ? formatDate(row[0]) : row[0];
    case NACION:
      return backend_format ? formatDate(new Date(row[0])) : formatDate(new Date(row[0]));
    case PROVINCIA_BA:
      return backend_format ? formatDate(row[1]) : row[1];
    default:
      return "";
  }
}

function getConcept(bank, row) {
  if (bank == null) {
    return "";
  }
  switch (bank.code) {
    case BBVA:
      return row[2];
    case NACION:
      return row[4];
    case PROVINCIA_BA:
      return row[4];
    default:
      return "";
  }
}

function getReference(bank, row) {
  if (bank == null) {
    return "";
  }
  switch (bank.code) {
    case BBVA:
      return row[3];
    case NACION:
      return row[3];
    case PROVINCIA_BA:
      return "";
    default:
      return "";
  }
}

function getMovementType(bank, row) {
  if (bank == null) {
    return "";
  }
  switch (bank.code) {
    case BBVA:
      return row[6] == null || row[6] == "" ? "H" : "D";
    case NACION:
      return transformAmount(row[2]) < 0 ? "H" : "D";
    case PROVINCIA_BA:
      return transformAmount(row[2]) < 0 ? "H" : "D";
    default:
      return "";
  }
}

function getAmount(bank, row) {
  if (bank == null) {
    return "";
  }
  switch (bank.code) {
    case BBVA:
      return row[6] == null || row[6] == "" ? row[7] : row[6];
    case NACION:
      return transformAmount(row[2]);
    case PROVINCIA_BA:
      return row[2];
    default:
      return "";
  }
}

function transformAmount(texto) {
  texto = texto.toString();
  texto = texto.replace(/\./g, "");
  texto = texto.replace(",", ".");
  return texto;
}

export const bankMovementService = {
  section,
  filter,
  remove,
  create,
  update,
  getStartIndex,
  getDate,
  getConcept,
  getReference,
  getMovementType,
  getAmount,
};
