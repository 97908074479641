<template>
  <v-layout>
    <v-dialog v-bind:value="dialog" @click:outside="$emit('updateDialog', false)" max-width="650px">
      <template v-slot:activator="{ on }">
        <v-btn color="primary" class="mb-5" dark v-on="on" @click="$emit('updateDialog', true)">{{ button_name }}</v-btn>
      </template>

      <v-card>
        <v-form v-on:submit.prevent="createItem">
          <v-card-title>
            <span class="headline">{{ modal_title }}</span>
          </v-card-title>

          <v-card-text>
            <!-- START FORM DIALOG -->
            <div class="row">
              <div class="col-md-12">
                <v-text-field data-vv-as="name" label="Razon social*" name="name" v-model="item.name" v-validate="'required'" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("name") }}</span>

                <v-text-field autocomplete="off" data-vv-as="cuit" label="CUIT*" name="cuit" v-validate="'integer|required'" v-model="item.cuit" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("cuit") }}</span>

                <v-combobox
                  label="Condición frente al IVA*"
                  v-model="item.iva_condition"
                  data-vv-as="iva_condition"
                  name="iva_condition"
                  :items="iva_conditions"
                  item-text="name"
                  item-value="id"
                  autocomplete="off"
                  :disabled="isViewModal()"
                ></v-combobox>
                <span class="red--text">{{ errors.first("iva_condition") }}</span>

                <v-combobox
                  v-if="item.iva_condition != null && item.iva_condition.id == service.CONDITION_IVA_MONO"
                  label="Categoria Monotributo*"
                  v-model="item.mono_category"
                  data-vv-as="categoria monotributo"
                  name="mono_category"
                  :items="mono_categories"
                  item-text="name"
                  item-value="id"
                  autocomplete="off"
                  validate="'required'"
                  :disabled="isViewModal()"
                ></v-combobox>
                <span class="red--text">{{ errors.first("mono_category") }}</span>

                <v-text-field autocomplete="off" data-vv-as="address" label="Domicilio" name="address" v-validate="''" v-model="item.address" :disabled="isViewModal()"></v-text-field>
                <v-text-field autocomplete="off" data-vv-as="phone" label="Telefono" name="phone" v-model="item.phone" :disabled="isViewModal()"></v-text-field>
                <v-text-field autocomplete="off" data-vv-as="mail" label="Email" name="mail" v-model="item.mail" :disabled="isViewModal()"></v-text-field>

                <v-divider></v-divider>
                <h6 class="text-h6 black--text">Punto de venta facturación</h6>
                <p class="text-body-2">Ingrese el/los puntos de ventas utilizados para facturar</p>
                <v-row class="mb-1">
                  <v-col md="12" v-if="!isViewModal()">
                    <v-btn :disabled="isViewModal()" small color="blue-grey" class="pl-4 text-white" @click="addPuntoDeVenta('facturacion')">
                      <v-icon big color="green"> add </v-icon>
                      <span>Agregar punto de venta</span>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-data-table :headers="header_pto_venta" :items="item.ptos_venta_facturacion" style="border: solid 1px #1c1c1c" :hide-default-footer="true" :items-per-page="100">
                  <template v-slot:item="props">
                    <tr>
                      <td style="width: 40%">
                        <v-text-field :disabled="isViewModal()" autocomplete="off" name="name" placeholder="Nombre" dense v-validate="'required'" v-model="props.item.name" />
                        <span class="red--text">{{ errors.first("name") }}</span>
                      </td>
                      <td style="width: 40%">
                        <v-text-field :disabled="isViewModal()" autocomplete="off" name="numero" placeholder="Numero" type="number" dense v-validate="'required|integer'" v-model="props.item.number" />
                        <span class="red--text">{{ errors.first("numero") }}</span>
                      </td>

                      <td class="text-center">
                        <v-tooltip bottom v-if="!isViewModal()">
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on" small color="red" @click="removePuntoDeVenta(props.item, 'facturacion')"> indeterminate_check_box </v-icon>
                          </template>
                          <span>Remover</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
                <v-divider></v-divider>
                <h6 class="text-h6 black--text mt-3">Punto de venta remitos</h6>
                <p class="text-body-2">Ingrese el/los puntos de ventas utilizados para remitar</p>
                <v-row class="mb-1">
                  <v-col md="12" v-if="!isViewModal()">
                    <v-btn :disabled="isViewModal()" small color="blue-grey" class="pl-4 text-white" @click="addPuntoDeVenta('remito')">
                      <v-icon big color="green"> add </v-icon>
                      <span>Agregar punto de venta</span>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-data-table :headers="header_pto_venta" :items="item.ptos_venta_remitos" style="border: solid 1px #1c1c1c" :hide-default-footer="true" :items-per-page="100">
                  <template v-slot:item="props">
                    <tr>
                      <td style="width: 40%">
                        <v-text-field :disabled="isViewModal()" autocomplete="off" name="name" placeholder="Nombre" dense v-validate="'required'" v-model="props.item.name" />
                        <span class="red--text">{{ errors.first("name") }}</span>
                      </td>
                      <td style="width: 40%">
                        <v-text-field :disabled="isViewModal()" autocomplete="off" name="numero" placeholder="Numero" type="number" dense v-validate="'required|integer'" v-model="props.item.number" />
                        <span class="red--text">{{ errors.first("numero") }}</span>
                      </td>

                      <td class="text-center">
                        <v-tooltip bottom v-if="!isViewModal()">
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on" small color="red" @click="removePuntoDeVenta(props.item, 'remito')"> indeterminate_check_box </v-icon>
                          </template>
                          <span>Remover</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </template>
                </v-data-table>

                <v-divider></v-divider>

                <v-checkbox label="Habilitado" :disabled="isViewModal()" v-model="item.enabled"></v-checkbox>

                <v-checkbox label="El emisor factura utilizando el webservice de la AFIP" :disabled="isViewModal()" v-model="item.use_afip"></v-checkbox>

                <div v-if="item.use_afip">
                  <v-divider></v-divider>
                  <v-row>
                    <v-col>
                      <v-text-field
                        autocomplete="off"
                        data-vv-as="cbu"
                        label="CBU*"
                        name="cbu"
                        v-validate="'required|integer|min:22|max:22'"
                        v-model="item.cbu"
                        :disabled="isViewModal()"
                      ></v-text-field>
                      <span class="red--text">{{ errors.first("cbu") }}</span>
                    </v-col>
                    <v-col>
                      <v-text-field
                        autocomplete="off"
                        data-vv-as="alias"
                        label="Alias*"
                        name="alias"
                        v-validate="'required|min:6|max:20'"
                        v-model="item.alias"
                        :disabled="isViewModal()"
                      ></v-text-field>
                      <span class="red--text">{{ errors.first("alias") }}</span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        autocomplete="off"
                        data-vv-as="ingresos brutos"
                        label="Ingresos brutos*"
                        name="ingresos_brutos_nr"
                        v-validate="'required'"
                        v-model="item.ingresos_brutos_nr"
                        :disabled="isViewModal()"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <DatePickerEsp
                        label="Fecha de inicio de actividad*"
                        :date="item.start_activity_date"
                        :isRequired="true"
                        name="start_activity_date"
                        :isViewModal="isViewModal()"
                        @setDate="
                          (date) => {
                            item.start_activity_date = date;
                          }
                        "
                      />
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <v-text-field autocomplete="off" data-vv-as="provincia" label="Provincia" name="provincia" v-model="item.provincia" :disabled="isViewModal()"></v-text-field>
                      <span class="red--text">{{ errors.first("provincia") }}</span>
                    </v-col>
                    <v-col>
                      <v-text-field autocomplete="off" data-vv-as="location" label="Localidad" name="location" v-model="item.location" :disabled="isViewModal()"></v-text-field>
                      <span class="red--text">{{ errors.first("location") }}</span>
                    </v-col>
                    <v-col>
                      <v-text-field
                        autocomplete="off"
                        data-vv-as="postal_code"
                        label="Codigo Postal"
                        v-validate="'min:4|max:5'"
                        name="postal_code"
                        v-model="item.postal_code"
                        :disabled="isViewModal()"
                      ></v-text-field>
                      <span class="red--text">{{ errors.first("postal_code") }}</span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        autocomplete="off"
                        data-vv-as="external_reference"
                        label="Codigo de referencia externo"
                        v-validate="'max:60'"
                        name="external_reference"
                        v-model="item.external_reference"
                        :disabled="isViewModal()"
                      ></v-text-field>
                      <span class="red--text">{{ errors.first("external_reference") }}</span>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <DatePickerEsp
                        label="Fecha de Expiracion de Certificados AFIP*"
                        :date="item.afip_key_expiring_date"
                        :isRequired="true"
                        name="afip_key_expiring_date"
                        :isViewModal="isViewModal()"
                        @setDate="
                          (date) => {
                            item.afip_key_expiring_date = date;
                          }
                        "
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-file-input
                        counter
                        truncate-length="15"
                        :v-validate="item.afip_key_path == null || item.afip_key_path == undefined ? 'required' : ''"
                        name="afip_key_file"
                        v-model="afip_key_file"
                        label="Clave privada AFIP*"
                        :disabled="isViewModal()"
                      ></v-file-input>
                    </v-col>
                    <v-col>
                      <v-file-input
                        counter
                        truncate-length="15"
                        v-validate="item.afip_cert_path == null || item.afip_cert_path == undefined ? 'required' : ''"
                        name="afip_cert_file"
                        v-model="afip_cert_file"
                        label="Certificado AFIP*"
                        :disabled="isViewModal()"
                      ></v-file-input>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('updateDialog', false)">Cerrar</v-btn>
            <v-btn color="success" text type="submit" v-if="!isViewModal()" :loading="loading">{{ formBtn }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import Constant from "@/constants/sections";
import { billingGeneralService } from "@/libs/ws/billingGeneralService";
import { generalService } from "@/libs/ws/generalService";
import DatePickerEsp from "@/components/DatePickerEsp.vue";
import ModalGenericMixin from "@/mixins/ModalGenericMixin.vue";

export default {
  mixins: [ModalGenericMixin],
  components: { DatePickerEsp },
  props: {
    parent: Object,
    service: Object,
    modal_title: String,
    dialog: Boolean,
    item: Object,
    button_name: String,
    formBtn: String,
  },
  data() {
    return {
      afip_key_file: null,
      afip_cert_file: null,
      show: false,
      roles: [],
      iva_conditions: [],
      mono_categories: [],
      header_pto_venta: [
        { text: "Nombre", value: "name", sortable: false },
        { text: "Numero", value: "number", sortable: false },
        { text: "Accion", value: "", sortable: false, align: "center" },
      ],
    };
  },
  watch: {
    item() {
      this.$emit("updateItem", this.item);
    },
    dialog() {
      this.errors.clear();
    },
  },
  mounted() {
    this.loadIVAConditions();
    this.loadMonoCategories();
  },
  methods: {
    isViewModal() {
      return this.formBtn == "";
    },
    loadIVAConditions() {
      billingGeneralService.list_iva_conditions_emitter().then((response) => {
        this.iva_conditions = response.result;
      });
    },
    loadMonoCategories() {
      generalService.setSection(Constant.SECTION_MONO_CATEGORIES);
      generalService.filter().then((response) => {
        this.mono_categories = response.result;
      });
    },
    addPuntoDeVenta(type) {
      if (type == "facturacion") {
        this.item.ptos_venta_facturacion.push({
          name: "",
          number: "",
        });
      } else {
        this.item.ptos_venta_remitos.push({
          name: "",
          number: "",
        });
      }
    },
    removePuntoDeVenta(item, type) {
      if (type == "facturacion") {
        this.item.ptos_venta_facturacion.splice(this.item.ptos_venta_facturacion.indexOf(item), 1);
      } else {
        this.item.ptos_venta_remitos.splice(this.item.ptos_venta_remitos.indexOf(item), 1);
      }
    },
    createItem(e) {
      this.$validator.validate().then((valid) => {
        if (valid) {
          this.loading = true;
          e.preventDefault();
          //Se crea data para reemplazar criteria_price co nel id
          let data = new FormData();
          data.append("id", this.item.id);
          data.append("name", this.item.name);
          data.append("use_afip", this.item.use_afip ? 1 : 0);
          data.append("cuit", this.item.cuit);
          data.append("iva_condition", this.item.iva_condition.id);
          data.append("address", this.item.address);
          data.append("phone", this.item.phone);
          data.append("enabled", this.item.enabled ? 1 : 0);

          if (this.item.external_reference != null)
            //Porque el FormData manda "null" como string
            data.append("external_reference", this.item.external_reference);
          if (this.item.postal_code != null) data.append("postal_code", this.item.postal_code);
          if (this.item.location != null) data.append("location", this.item.location);
          if (this.item.provincia != null) data.append("provincia", this.item.provincia);

          if (this.item.mono_category != null) data.append("mono_category_id", this.item.mono_category.id);

          //Puntos de venta
          for (var i = 0; i < this.item.ptos_venta_facturacion.length; i++) {
            data.append("ptos_venta_facturacion_arr[]", JSON.stringify(this.item.ptos_venta_facturacion[i]));
          }

          for (var j = 0; j < this.item.ptos_venta_remitos.length; j++) {
            data.append("ptos_venta_remitos_arr[]", JSON.stringify(this.item.ptos_venta_remitos[j]));
          }

          if (this.item.use_afip) {
            data.append("ingresos_brutos_nr", this.item.ingresos_brutos_nr);
            data.append("cbu", this.item.cbu);
            data.append("alias", this.item.alias);
            data.append("start_activity_date", this.item.start_activity_date);

            if (this.afip_cert_file != null) data.append("afip_cert_file", this.afip_cert_file);
            if (this.afip_key_file != null) data.append("afip_key_file", this.afip_key_file);
            data.append("afip_key_expiring_date", this.item.afip_key_expiring_date);
          }

          if (this.item.id > 0) {
            this.service
              .update(data)
              .then((response) => {
                if (response.status == true) {
                  this.$toast.success("El " + this.modal_title + " se modifico correctamente.", "OK");
                  this.$emit("updateDialog", false);
                  this.afip_key_file = null;
                  this.afip_cert_file = null;
                  this.$emit("loadList");
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    this.$toast.error(response.msg, "Error");
                  }
                }
              })
              .catch((response) => {
                this.$toast.error(response.msg, "Error");
              })
              .finally(() => {
                this.loading = false;
              });
          } else {
            this.service
              .create(data)
              .then((response) => {
                console.log(response);
                if (response.status == true) {
                  this.$toast.success("El " + this.modal_title + " se creo correctamente", "OK");
                  this.$emit("updateDialog", false);
                  this.afip_key_file = null;
                  this.afip_cert_file = null;
                  this.$emit("loadList");
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    this.$toast.error(response.msg, "Error");
                  }
                }
              })
              .catch((response) => {
                console.log(response);
                this.$toast.error(response.msg, "Error");
              })
              .finally(() => {
                this.loading = false;
              });
          }
        } else {
          console.log(this.errors);
        }
      });
    },
  },
};
</script>
