import { wsService } from "./wsService";
import sections from "@/constants/sections";

const section = sections.SECTION_PRICE_LIST;

function filter(param = { enable: true }) {
  return wsService.make_post_request(section + "/list", param);
}

function create(param = {}) {
  return wsService.make_post_request(section + "/create", param);
}

function update(param = {}) {
  return wsService.make_put_request(section + "/update", param);
}

function remove(param = {}) {
  return wsService.make_delete_request(section + "/delete", param);
}

function bulk_update(param = {}) {
  return wsService.make_put_request(section + "/update_percentage", param);
}

function clone(param = {}) {
  return wsService.make_post_request(section + "/clone", param);
}

function getPriceList(param = {}) {
  return wsService.make_post_request(section + "/get_pesentations", param);
}

function updatePresentation(param = {}) {
  return wsService.make_put_request(section + "/update_pesentation", param);
}

export const priceListService = {
  section,
  filter,
  remove,
  create,
  update,
  bulk_update,
  clone,
  getPriceList,
  updatePresentation,
};
