<template>
  <div>
    <Title :pages="[{ icon: 'exchange-alt', page: page_title.toUpperCase() }]"></Title>

    <v-container fluid>
      <div v-if="accounts.length == 0 && this.loading" style="text-align: center; margin-top: 150px">
        <v-progress-circular indeterminate color="red"></v-progress-circular>
        <h4 style="margin-top: 20px">Cargando...</h4>
      </div>

      <div v-if="accounts.length == 0 && !this.loading" style="text-align: center; margin-top: 100px">
        <h4>
          <b>Autorizanos a leer tus cobros en Mercado Pago</b>
        </h4>
        <p>Podrás revocarnos el permiso cuando quieras desde Mercado Pago</p>
        <img :src="require('@/assets/images/Mercado-Pago-Logo.png')" class="img-responsive" style="max-width: 250px; margin: 0 auto; margin-top: 30px; margin-bottom: 30px" />
        <div class="row">
          <div class="col-md-12">
            <v-btn :href="'http://auth.mercadolibre.com.ar/authorization?response_type=code&client_id=' + service.MP_CLIENT_ID" color="blue" class="text-white">Conectar</v-btn>
          </div>
        </div>
      </div>

      <div v-if="accounts.length > 0">
        <v-btn :href="'http://auth.mercadolibre.com.ar/authorization?response_type=code&client_id=' + service.MP_CLIENT_ID" color="blue" class="text-white mb-5">Conectar cuenta</v-btn>
        <v-btn class="text-white ml-2 mb-5 btn-invert" @click="show_cuenta_conectadas = true">Cuentas conectadas</v-btn>

        <v-card>
          <div class="row mb-5 mt-0">
            <div class="col-md-12" v-if="list_access">
              <div class="col-md-12" style="background:white; elevation-0">
                <div class="row pt-5">
                  <div class="col-md-3 pt-0">
                    <v-menu
                      ref="desde_selected"
                      v-model="desde_selected_open"
                      :close-on-content-click="false"
                      :close-on-click="false"
                      :return-value.sync="desde_selected"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                      clearable
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="desde_selected" label="Desde" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="desde_selected" type="date" no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="desde_selected_open = false"> Cancel </v-btn>
                        <v-btn text color="primary" @click="$refs.desde_selected.save(desde_selected)"> OK </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </div>

                  <div class="col-md-3 pt-0">
                    <v-menu
                      ref="hasta_selected"
                      v-model="hasta_selected_open"
                      :close-on-content-click="false"
                      :close-on-click="false"
                      :return-value.sync="hasta_selected"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                      clearable
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="hasta_selected" label="Hasta" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="hasta_selected" type="date" no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="hasta_selected = false"> Cancel </v-btn>
                        <v-btn text color="primary" @click="$refs.hasta_selected.save(hasta_selected)"> OK </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </div>

                  <div class="col-md-3 pt-0">
                    <v-combobox clearable v-model="account_selected" autocomplete="off" item-text="nickname_ml" item-value="id" :items="accounts" label="Cuenta"></v-combobox>
                  </div>
                  <div class="col-md-3 pt-0 mt-3" style="display: flex; justify-content: end">
                    <v-btn color="info" class="" @click="filter">Buscar</v-btn>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-card>

        <v-card v-if="list_access">
          <v-card-title>
            <span class="text-uppercase">Mercado Pago</span>
            <v-spacer></v-spacer>
            <v-text-field v-model="search" append-icon="search" label="Buscar" single-line hide-details></v-text-field>
          </v-card-title>
          <v-data-table :headers="headers" :items="list.results" :search="search" :loading="loading" loading-text="Cargando..." hide-default-footer disable-pagination>
            <template v-slot:item="props">
              <tr>
                <td>{{ new Date(props.item.date_created).toLocaleDateString("es-AR") }}</td>
                <td>{{ isRecibido(props.item) ? new Date(props.item.money_release_date).toLocaleDateString("es-AR") : "-" }}</td>
                <td>{{ props.item.voucher != null ? props.item.voucher.voucher_number_label : "-" }}</td>
                <td>{{ service.getOperationType(props.item.operation_type) }}</td>
                <td>{{ props.item.card.hasOwnProperty("cardholder") ? props.item.card.cardholder.name : "Consumidor final" }}</td>
                <td>{{ props.item.hasOwnProperty("payer") && props.item.payer.hasOwnProperty("identification") ? props.item.payer.identification.number : "-" }}</td>

                <td style="white-space: nowrap">{{ isRecibido(props.item) ? formatUtils.formatPrice(props.item.transaction_details.net_received_amount) : "-" }}</td>
                <td style="white-space: nowrap" :class="isRecibido(props.item) ? 'text-green' : ''">
                  {{ isRecibido(props.item) ? "+ " : "- " }}{{ formatUtils.formatPrice(props.item.transaction_details.total_paid_amount) }}
                </td>
                <td class="text-center px-0">
                  <v-tooltip bottom v-if="canGenerateVoucher(props.item)">
                    <template v-slot:activator="{ on }">
                      <v-icon v-if="create_access" v-on="on" small color="blue" class="mr-2" @click="generateVoucher(props.item)"> request_page </v-icon>
                    </template>
                    <span>Generar Factura</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
            <template v-slot:no-results>
              <v-alert :value="true" color="error" icon="warning">
                {{ "Busqueda sin resultados - " + search }}
              </v-alert>
            </template>
          </v-data-table>
          <div v-if="loading && cant_pages > 1">
            <v-progress-linear :active="loading" indeterminate></v-progress-linear>
          </div>
        </v-card>

        <div v-if="cant_pages > 1" class="text-center">
          <v-pagination
            class="mt-5 mb-5"
            v-model="current_page"
            :length="cant_pages"
            total-visible="10"
            circle
            @next="loadList(service, getFilterParams())"
            @previous="loadList(service, getFilterParams())"
            @input="loadList(service, getFilterParams())"
          ></v-pagination>
        </div>
      </div>
      <ModalCuentasVinculadas :show="show_cuenta_conectadas" @close="show_cuenta_conectadas = false" @reload="getMpAccounts" :accounts="accounts" />

      <DialogGenerarComprobante
        title="Generar Comprobante"
        :open="open_generate_comprobante"
        @cancel="open_generate_comprobante = false"
        :invoice_to_generate="invoice_to_generate"
        @updateDialog="filter"
      />
    </v-container>
  </div>
</template>

<script>
import { integrationsService } from "@/libs/ws/integrationsService";
import GenericMixin from "@/mixins/GenericMixin.vue";
import Title from "@/components/Title.vue";
import ModalCuentasVinculadas from "./ModalCuentasVinculadas.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import { formatUtils } from "@/libs/utils/formatUtils";
import DialogGenerarComprobante from "@/views/Billing/Vouchers/DialogGenerarComprobante.vue";

export default {
  components: { Title, ModalCuentasVinculadas, DialogGenerarComprobante },
  mixins: [GenericMixin, RolMixin],
  data() {
    return {
      open_generate_comprobante: false,
      invoice_to_generate: null,
      parent: this,
      show_cuenta_conectadas: false,
      cant_pages: 0,
      current_page: 1,
      total_per_page: 0,
      formatUtils: formatUtils,
      service: integrationsService,
      page_title: "Integraciones",
      modal_title: "Integracion",
      mainItem: {},
      defaultItem: null,
      headers: [
        { text: "Fecha", align: "left", sortable: true, value: "date_created" },
        { text: "Fecha Cobro", align: "left", sortable: true, value: "money_release_date" },
        { text: "Fact Nº", align: "left", sortable: true, value: "voucher.voucher_number_label" },
        { text: "Tipo de operacion", align: "left", sortable: true, value: "operation_type" },
        { text: "Comprador", align: "left", sortable: true, value: "card.cardholder.name" },
        { text: "CUIT", align: "left", sortable: true, value: "payer.identification.number" },
        { text: "Recibido", align: "left", sortable: true, value: "transaction_details.net_received_amount" },
        { text: "Total", align: "left", sortable: true, value: "transaction_details.total_paid_amount" },
        { text: "Accion", value: "", align: "center" },
      ],
      list: [],
      desde_selected: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().slice(0, 10),
      hasta_selected: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().slice(0, 10),
      hasta_selected_open: false,
      desde_selected_open: false,
      account_selected: null,
      showAccounts: false,
      accounts: [],
      account_logout: null,
      loading_desvincular: false,
    };
  },
  mounted() {
    this.getMpAccounts();
    this.defaultItem = JSON.parse(JSON.stringify(this.mainItem));
  },
  methods: {
    getFilterParams() {
      const param = {
        from: this.desde_selected,
        to: this.hasta_selected,
        account: this.account_selected != null ? this.account_selected.id : null,
        offset: (this.current_page - 1) * this.total_per_page,
      };
      return param;
    },
    filter() {
      this.loadList(this.service, this.getFilterParams());
    },
    isRecibido(item) {
      return item.origin == "Recibido";
    },
    loadList(service, param = null) {
      this.loading = true;

      if (param == null) {
        param = this.getFilterParams();
      }

      this.open_filter = false;
      service
        .filter(param)
        .then((response) => {
          this.list = response.result;
          this.cant_pages = Math.ceil(response.result.paging.total / response.result.paging.limit);
          this.total_per_page = response.result.paging.limit;
          this.loading = false;

          if (typeof this.selected !== "undefined") {
            this.selected = [];
          }
        })
        .catch((e) => {
          console.log("service.filter error");
          console.log(e);
          this.loading = false;
        });
    },
    getMpAccounts() {
      this.loading = true;
      integrationsService
        .getMpAccounts()
        .then((response) => {
          this.accounts = response;
          if (this.accounts.length > 0) this.account_selected = this.accounts[0];
          this.loading = false;
          this.filter();
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    desvincularClick(account) {
      this.account_logout = account;
      this.dialog_logout = true;
      this.showAccounts = false;
    },
    getReceiverOrSupplier(item) {
      if (item.receiver != null) return item.receiver.name;
      if (item.supplier != null) return item.supplier.name;
    },
    generateVoucher(item) {
      this.invoice_to_generate = {
        emitter: item.emitter,
        receiver: item.receiver,
        cbte_tipo: null,
        pto_vta: null,
        cbte_fch: new Date(item.date_created).toISOString().slice(0, 10),
        cbte_fch_serv_desde: new Date(item.date_created).toISOString().slice(0, 10),
        cbte_fch_serv_hasta: new Date(item.date_created).toISOString().slice(0, 10),
        fch_vto_pago: new Date(item.date_approved).toISOString().slice(0, 10),
        concepto: null,
        moneda: "PES",
        mon_cotiz: 1,
        items_custom: [
          {
            descripcion: "",
            quantity: 1,
            price: item.transaction_details.total_paid_amount,
            product: null,
            iva: null,
          },
        ],

        purchase_origin: "MELI",
        external_reference: item.id,

        money_account_id: item.money_account.id,
        charge_cbte_fch: new Date(item.money_release_date).toISOString().slice(0, 10),
        spent_cbte_fch: new Date(item.date_created).toISOString().slice(0, 10),
        spent_imp_total: item.transaction_details.total_paid_amount - item.transaction_details.net_received_amount,
      };
      this.open_generate_comprobante = true;
    },
    canGenerateVoucher(item) {
      return (
        item.status == "approved" && //Aprovado
        item.payer != undefined && //Recibido
        item.operation_type == "recurring_payment" &&
        item.voucher == null //No tiene que ya estar facturado
      );
    },
    formatDate(dateObj) {
      let year = dateObj.getFullYear();

      let month = dateObj.getMonth();
      month = ("0" + (month + 1)).slice(-2);

      let date = dateObj.getDate();
      date = ("0" + date).slice(-2);

      let hour = dateObj.getHours();
      hour = ("0" + hour).slice(-2);

      let minute = dateObj.getMinutes();
      minute = ("0" + minute).slice(-2);

      return date + "-" + month + "-" + year + " " + hour + ":" + minute;
    },
  },
};
</script>
