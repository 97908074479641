<template>
  <div>
    <Title :pages="[{ icon: 'file-invoice', page: page_title.toUpperCase() }]"></Title>

    <v-container fluid>
      <v-card v-if="list_access">
        <v-card-title>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="blue-grey mr-2 mt-3" small dark v-bind="attrs" v-on="on" :loading="dialog_export"> Exportar <font-awesome-icon class="ml-2" icon="download" /></v-btn>
            </template>
            <v-list>
              <v-list-item @click="exportListado">
                <v-list-item-title>Exportar listado</v-list-item-title>
              </v-list-item>
              <v-list-item @click="dialog_export_ctacte = true">
                <v-list-item-title>Exportar Cuentas Corrientes</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="search" label="Buscar" single-line hide-details></v-text-field>
        </v-card-title>

        <v-data-table
          :headers="headers"
          :items="list"
          :search="search"
          :loading="loading"
          loading-text="Cargando..."
          :options="{
            descending: true,
            itemsPerPage: 15,
          }"
          @pagination="updatePagination"
          :footer-props="{
            itemsPerPageOptions: [5, 10, 15, 50, -1],
          }"
        >
          <template v-slot:item="props">
            <tr>
              <td>{{ props.item.name }}</td>
              <td class="text-right">
                <span :class="props.item.balance == 0 ? '' : props.item.balance > 0 ? 'bg-round-green' : 'bg-round-red'">{{ formatUtils.formatPrice(props.item.balance) }}</span>
              </td>
              <td class="text-center" style="min-width: 100px">
                <!--<v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-if="update_access" v-on="on" small color="blue-grey" class="mr-2" @click="goToComprobantesPendientes(props.item)"> description </v-icon>
                  </template>
                  <span>Comprobantes pendientes</span>
                </v-tooltip>-->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-if="update_access" v-on="on" small color="blue-grey" class="mr-2" @click="goToBalances(props.item)"> description </v-icon>
                  </template>
                  <span>Ver Detalle Cta. Cte.</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              {{ "Busqueda sin resultados - " + search }}
            </v-alert>
          </template>
        </v-data-table>
      </v-card>
    </v-container>

    <DialogExportCtasCtes :show="dialog_export_ctacte" @confirm="exportCtasCtes" @cancel="dialog_export_ctacte = false" />
  </div>
</template>

<script>
import Constant from "@/constants/sections";
import { generalService } from "@/libs/ws/generalService";
import { formatUtils } from "@/libs/utils/formatUtils";
import GenericMixin from "@/mixins/GenericMixin.vue";
import Title from "@/components/Title.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import ExportMixin from "@/mixins/ExportMixin.vue";
import DialogExportCtasCtes from "@/components/Reports/DialogExportCtasCtes.vue";

export default {
  components: { Title, DialogExportCtasCtes },
  mixins: [GenericMixin, RolMixin, ExportMixin],
  data() {
    return {
      Constant: Constant,
      formatUtils: formatUtils,
      service: generalService,
      dialog_export_ctacte: false,
      page_title: "Cta. Cte. Proveedores",
      modal_title: "Cta. Cte. Proveedores",
      headers: [
        {
          text: "Nombre",
          align: "left",
          sortable: true,
          value: "name",
        },
        { text: "Saldo Acumulado", value: "balance", align: "center" },
        { text: "Accion", value: "", align: "center" },
      ],
      list: [],
    };
  },
  mounted() {
    this.service.setSection(Constant.SECTION_SUPPLIERS);
    this.loadList(this.service);
  },
  methods: {
    goToBalances(item) {
      this.$router.push("/" + Constant.SECTION_SUPPLIERS_BALANCES + "/" + item.id);
    },
    goToComprobantesPendientes(item) {
      this.$router.push("/reports/supplier/pending-vouchers/" + item.id);
    },
    exportListado() {
      this.exportExcel(Constant.SECTION_REPORTS + "/cuentas-por-pagar", {}, "Cuentas-por-pagar.xlsx");
    },
    exportCtasCtes(params) {
      params["export"] = true;
      this.exportExcel(Constant.SECTION_SUPPLIERS_BALANCES + "/list", params, "Cuentas-Corrientes-Proveedores.xlsx");
      this.dialog_export_ctacte = false;
    },
    exportVoucherPendientes() {
      var param = {};
      param["export"] = true;
      this.exportExcel(Constant.SECTION_REPORTS + "/supplier/pending-vouchers", param, "Comprobantes-Pendientes-Pago.xlsx");
    },
  },
};

// TODO agregar un boton de limpiar busqueda.
</script>
