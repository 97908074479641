//Estados Base, a partir de Cobro o Pago
const STATE_EN_CARTERA = "En Cartera"; //P/NP

//Estados resultantes de una accion en ABM Chques
const STATE_DEPOSITADO = "Depositado"; //NP
const STATE_ACREDITADO = "Acreditado"; //NP
const STATE_COBRADO_VENTANILLA = "Cobrado por Ventanilla"; //NP
const STATE_DEBITADO = "Debitado"; //P
const STATE_RECHAZADO = "Rechazado"; //P/NP
const STATE_UTILIZADO = "Utilizado"; //P/NP -> Andosado
const STATE_VENCIDO = "Vencido"; //P/NP

export default {
  STATE_EN_CARTERA: STATE_EN_CARTERA,
  STATE_DEPOSITADO: STATE_DEPOSITADO,
  STATE_ACREDITADO: STATE_ACREDITADO,
  STATE_COBRADO_VENTANILLA: STATE_COBRADO_VENTANILLA,
  STATE_DEBITADO: STATE_DEBITADO,
  STATE_RECHAZADO: STATE_RECHAZADO,
  STATE_UTILIZADO: STATE_UTILIZADO,
  STATE_VENCIDO: STATE_VENCIDO,
};
