<template>
  <div style="padding: 20px; min-height: 630px; background-color: rgba(221, 225, 230, 1); border: solid 1px #ccc">
    <div class="row">
      <div class="col-md-12">
        <span class="title_bi mr-4">Periodo</span>
        <input name="desde" type="date" v-model="from_date" class="mt-1" style="font-size: 14px; cursor: pointer" title="Desde" @change="fills" />
        -
        <input name="hasta" type="date" v-model="to_date" class="mt-1" style="font-size: 14px; cursor: pointer" title="Hasta" @change="fills" />
        -
        <div style="max-width: 90px; display: inline-block" class="ml-2">
          <v-combobox v-model="currency" autocomplete="off" item-text="name" item-value="id" :items="monedas" class="pt-0 mt-0" dense @change="fills"></v-combobox>
        </div>
        -
        <div style="max-width: 350px; display: inline-block" class="ml-2">
          <v-combobox
            v-model="emitter"
            autocomplete="off"
            data-vv-as="emitter"
            name="emitter"
            item-text="name"
            item-value="id"
            :items="emitters"
            label="Emisor"
            clearable
            class="pt-0 mt-0"
            dense
            @change="fills"
          ></v-combobox>
        </div>
      </div>
    </div>

    <div class="row">
      <!-- IVA -->
      <div class="col-md-6">
        <v-card>
          <v-card-title class="pb-0">
            <div class="col-md-10">
              <p class="title_bi mb-0">IVA</p>
            </div>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div class="col-md-2">
                  <v-icon class="ml-2 float-right" small color="grey" dark v-bind="attrs" v-on="on"> help_outline </v-icon>
                </div>
              </template>
              <span> Saldos de IVA en el periodo seleccionado</span>
            </v-tooltip>
            <div class="col-md-12 pt-0">
              <v-chip outlined color="primary"> {{ getTextSaldo(saldo_iva) }}: {{ currency.name }} {{ new Intl.NumberFormat("en-EN").format(saldo_iva) }} </v-chip>
            </div>
          </v-card-title>

          <v-card-text>
            <div class="row">
              <div class="col p-12">
                <apexchart type="bar" ref="statIVA" :options="getOptionsIVA()" :series="seriesIVA"></apexchart>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </div>

      <!-- IIBB -->
      <div class="col-md-6">
        <v-card>
          <v-card-title class="pb-0">
            <div class="col-md-10">
              <p class="title_bi mb-0">Ingresos Brutos</p>
            </div>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div class="col-md-2">
                  <v-icon class="ml-2 float-right" small color="grey" dark v-bind="attrs" v-on="on"> help_outline </v-icon>
                </div>
              </template>
              <span> Total estimado de IIBB y Retenciones en Cobros</span>
            </v-tooltip>
            <div class="col-md-12 pt-0">
              <v-chip outlined color="primary"> {{ getTextSaldo(saldo_iibb) }}: {{ currency.name }} {{ new Intl.NumberFormat("en-EN").format(saldo_iibb) }} </v-chip>
            </div>
          </v-card-title>

          <v-card-text>
            <div class="row">
              <div class="col p-12">
                <apexchart type="bar" ref="statIIBB" :options="getOptionsIIBB()" :series="seriesIIBB"></apexchart>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </div>
    <div class="row" v-if="isIngeniAR()">
      <!-- GANANCIAS -->
      <div class="col-md-12">
        <v-card>
          <v-card-title class="pb-0">
            <div class="col-md-10">
              <p class="title_bi mb-0">Ganancia</p>
            </div>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div class="col-md-2">
                  <v-icon class="ml-2 float-right" small color="grey" dark v-bind="attrs" v-on="on"> help_outline </v-icon>
                </div>
              </template>
              <span> Total estimado de imp. a las ganancias en el periodo seleccionado</span>
            </v-tooltip>
            <div class="col-md-12 pt-0">
              <v-chip outlined color="primary"> Imp. Gs. {{ getTextSaldo(imp_ganancias) }}: {{ new Intl.NumberFormat("en-EN").format(imp_ganancias) }} </v-chip>
              <v-chip outlined color="primary"> Gs. Contables:{{ new Intl.NumberFormat("en-EN").format(ganancias_contables) }} </v-chip>
              <v-chip outlined color="primary"> Gs. Reales: {{ new Intl.NumberFormat("en-EN").format(ganancias_reales) }} </v-chip>
            </div>
          </v-card-title>

          <v-card-text>
            <div class="row">
              <div class="col p-12">
                <apexchart type="bar" ref="statGanancias" :options="getOptionsGanancias()" :series="seriesGanancias"></apexchart>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { metricsService } from "@/libs/ws/metricsService.js";
import { emmiterService } from "@/libs/ws/emmiterService";
import Constant from "@/constants/sections";
export default {
  components: {},
  data() {
    return {
      loading: false,
      menu: false,
      service: metricsService,
      from_date: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().slice(0, 10),
      to_date: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().slice(0, 10),
      currency: { id: "ARS", name: "ARS" },
      monedas: metricsService.getMonedas(),
      emitter: null,
      emitters: [],
      legend: {
        show: true,
        fontSize: "11px",
      },
      chart: {
        toolbar: {
          show: false,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: false,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
          },
        },
        legend: {
          show: true,
          onItemHover: {
            highlightDataSeries: true,
          },
          fontSize: "25px",
        },
      },
      yaxis: {
        labels: {
          formatter: function (val, index) {
            return new Intl.NumberFormat("en-EN").format(val);
          },
        },
      },
      saldo_iva: 0,
      seriesIVA: [
        {
          name: "IVA",
          data: [0, 0, 0, 0, 0, 0],
        },
      ],
      saldo_iibb: 0,
      seriesIIBB: [
        {
          name: "Ingresos Brutos",
          data: [0, 0, 0, 0, 0],
        },
      ],
      imp_ganancias: 0,
      ganancias_contables: 0,
      ganancias_reales: 0,
      seriesGanancias: [
        {
          name: "Ganancias",
          data: [0, 0, 0, 0, 0],//facturacion_neta,gastos_netos,gastos_no_deducibles,percepciones_gastos,retenciones_cobros
        },
      ],
    };
  },
  mounted() {
    this.loadEmitters();
    this.fills();
  },
  watch: {},
  methods: {
    getOptionsIVA() {
      return {
        legend: this.legend,
        chart: this.chart,
        fill: {
          type: "gradient",
          colors: ["#4a7def", "#E91E63", "#9C27B0"],
          gradient: {
            type: "vertical",
            shadeIntensity: 1,
            opacityFrom: 0.9,
            opacityTo: 0.9,
            stops: [0, 50, 100],
          },
        },
        xaxis: {
          categories: ["(+) IVA Venta", "(-) IVA Compra", "(+) Percepcion IVA Venta", "(-) Percepcion IVA Compra", "(-) Retencion IVA Venta", "(+)Retencion IVA Compra"],
        },
        yaxis: this.yaxis,
        dataLabels: { enabled: false },
      };
    },
    getOptionsIIBB() {
      return {
        legend: this.legend,
        chart: this.chart,
        fill: {
          type: "gradient",
          colors: ["#4a7def", "#E91E63", "#9C27B0"],
          gradient: {
            type: "vertical",
            shadeIntensity: 1,
            opacityFrom: 0.9,
            opacityTo: 0.9,
            stops: [0, 50, 100],
          },
        },
        xaxis: {
          categories: ["(+) Estimado segun Ventas", "(+) Percepciones en Ventas", "(-) Percepciones en Gastos", "(-) Retenciones en Cobros", "(+) Retenciones en Pagos"],
        },
        yaxis: this.yaxis,
        dataLabels: { enabled: false },
      };
    },
    getOptionsGanancias() {
      return {
        legend: this.legend,
        chart: this.chart,
        fill: {
          type: "gradient",
          colors: ["#4a7def", "#E91E63", "#9C27B0"],
          gradient: {
            type: "vertical",
            shadeIntensity: 1,
            opacityFrom: 0.9,
            opacityTo: 0.9,
            stops: [0, 50, 100],
          },
        },
        xaxis: {
          categories: ["Facturacion Neta", "Gastos Netos","Gastos No Deducibles", "Percepciones En Gastos", "Retenciones en Cobros"],
        },
        yaxis: this.yaxis,
        dataLabels: { enabled: false },
      };
    },
    isIngeniAR() {
      return localStorage.getItem("company_user_id") == 9;
    },
    loadEmitters() {
      emmiterService.filter({ enabled: true }).then((response) => {
        this.emitters = response.result;
      });
    },
    fills() {
      this.getIVA();
      this.getIIBB();
      this.getImpGanancias();
    },
    getTextSaldo(saldo) {
      if (saldo > 0) {
        return "Saldo a pagar";
      } else {
        return "Saldo a favor";
      }
    },
    getIVA() {
      this.loading = true;

      metricsService
        .getIVA({
          from_date: this.from_date,
          to_date: this.to_date,
          currency: this.currency.id,
          emitter: this.emitter != null ? this.emitter.id : null,
        })
        .then((response) => {
          if (response.status === true) {
            this.saldo_iva = response.result.saldo_iva;

            this.seriesIVA[0].data = response.result.stats_data;
            this.$refs.statIVA.updateSeries(this.seriesIVA, false, true);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getIIBB() {
      this.loading = true;

      metricsService
        .getIIBB({
          from_date: this.from_date,
          to_date: this.to_date,
          currency: this.currency.id,
          emitter: this.emitter != null ? this.emitter.id : null,
        })
        .then((response) => {
          if (response.status === true) {
            this.saldo_iibb = response.result.saldo_iibb;

            this.seriesIIBB[0].data = response.result.stats_data;
            this.$refs.statIIBB.updateSeries(this.seriesIIBB, false, true);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getDateFormat(date) {
      var date_aux = date.split("-");
      var d = new Date(date);
      return date_aux[2] + " " + this.months[d.getMonth()];
    },
    getImpGanancias() {
      this.loading = true;

      metricsService
        .getImpGanancias({
          from_date: this.from_date,
          to_date: this.to_date,
          currency: this.currency.id,
          emitter: this.emitter != null ? this.emitter.id : null,
        })
        .then((response) => {
          if (response.status === true) {
            this.ganancias_contables = response.result.ganancias_contables;
            this.ganancias_reales = response.result.ganancias_reales;
            this.imp_ganancias = response.result.saldo_imp_ganancias;

            this.seriesGanancias[0].data = response.result.stats_data;
            this.$refs.statGanancias.updateSeries(this.seriesGanancias, false, true);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
