import { wsService } from "./wsService";
import sections from "@/constants/sections";

const section = sections.SECTION_INTEGRATIONS;
const MP_CLIENT_ID = 7104435802222906;

function filter(param = { enable: true }) {
  return wsService.make_post_request(section + "/meli/filter", param);
}

function getMpAccounts(param = { enable: true }) {
  return wsService.make_get_request(section + "/meli/accounts", param);
}

function create(params) {
  return wsService.make_post_request(section + "/create", params);
}

function update(params) {
  return wsService.make_post_request(section + "/update", params);
}

function desvincular(param = {}) {
  return wsService.make_post_request(section + "/meli/desvincular", param);
}

function getStatus(name) {
  switch (name) {
    case "approved":
      return "Aprobado";
    case "rejected":
      return "Rechazado";
    case "in_process":
      return "En Proceso";
    case "in_mediation":
      return "En Mediación";
    case "refunded":
      return "Pago Devuelto";
    case "pending":
      return "Pendiente";
    case "processed":
      return "Procesada";
  }
}

function getOperationType(name) {
  switch (name) {
    case "money_transfer":
      return "Envio de dinero";
    case "regular_payment":
      return "Mercado Pago";
    case "mercadolibre":
      return "Mercado Libre";
    case "pos_payment":
      return "MPOS";
    case "payment_addition":
      return "Agregado de dinero";
    case "recurring_payment":
      return "Suscripcion";
    case "account_fund":
        return "Transferencia recibida";
  }
}

export const integrationsService = {
  MP_CLIENT_ID,
  getMpAccounts,
  getStatus,
  getOperationType,
  desvincular,
  filter,
  create,
  update,
};
