<template>
  <div>
    <v-dialog v-model="open" max-width="750" @click:outside="close">
      <v-card class="background-voucher">
        <v-form v-on:submit.prevent="sendEmail">
          <v-card-title class="headline mb-4">{{ title }}</v-card-title>
          <v-card-text>
            <div class="row">
              <div class="col-md-12 pt-0 pb-0">
                <v-text-field
                  persistent-hint
                  hint='Si queres escribir mas de un correo separarlo con ","'
                  data-vv-as="Para"
                  label="Para"
                  name="to"
                  v-model="send_email.to"
                  v-validate="'required'"
                ></v-text-field>
                <span class="red--text">{{ errors.first("to") }}</span>
              </div>
              <div class="col-md-12 pt-0 pb-0">
                <v-text-field data-vv-as="Responder a" label="Responder a" name="reply" v-model="send_email.reply" v-validate="'email|required'"></v-text-field>
                <span class="red--text">{{ errors.first("reply") }}</span>
              </div>
              <div class="col-md-12 pt-0 pb-0">
                <v-text-field data-vv-as="Asunto" label="Asunto" name="subject" v-model="send_email.subject" v-validate="'required'"></v-text-field>
                <span class="red--text">{{ errors.first("subject") }}</span>
              </div>
              <div class="col-md-12 pt-0 pb-0 mb-4">
                <v-chip style="border: 1px solid #2e3d45">Adjunto: {{ this.cbte_number }}</v-chip>
              </div>
              <div class="col-md-12 pt-0 pb-0">
                <v-textarea data-vv-as="Mensaje" label="Mensaje" name="message" v-model="send_email.message"></v-textarea>
                <span class="red--text">{{ errors.first("message") }}</span>
              </div>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="close">Cancelar</v-btn>
            <v-btn color="green darken-1" text type="submit" :loading="loading">Enviar Email</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <DialogLoading :dialog="dialog_loading" text="Cargando..." />
  </div>
</template>

<script>
import DialogLoading from "@/components/DialogLoading.vue";
import SettingsMixin from "@/mixins/SettingsMixin.vue";
import { sendEmailService } from "../../../libs/ws/sendEmailService";

export default {
  components: { DialogLoading },
  mixins: [SettingsMixin],
  props: {
    title: String,
    open: Boolean,
    isViewModal: {
      default: false,
      type: Boolean,
    },
    closeDialog: Function,
    item: Object,
  },
  data: () => ({
    service: sendEmailService,
    loading: false,
    dialog_loading: false,
    send_email: {
      to: "",
      reply: "",
      subject: "",
      message: "",
      type: "voucher",
      object_id: null,
    },
    cbte_number: "-",
    defaultSendEmail: null,
  }),
  watch: {
    open(val) {
      this.send_email = JSON.parse(JSON.stringify(this.defaultSendEmail));
      this.errors.clear();
      if (this.item) {
        if (this.item.receiver.client.invoiced_email != null) {
          let emailsString = this.item.receiver.client.invoiced_email;
          let emailsArray = emailsString.split(/\s+/);
          //remuevo las comas de los emails
          emailsArray = emailsArray.map((email) => email.replace(/,/g, ""));
          this.send_email.to = emailsArray.join(", ");
        } else {
          this.send_email.to = "";
        }
        this.cbte_number = this.item.pto_vta.toString().padStart(4, "0") + "-" + this.item.voucher_number.toString().padStart(8, "0");
        this.send_email.object_id = this.item.id;
      }
    },
  },
  mounted() {
    this.send_email.reply = this.settings.email_sender;
    this.defaultSendEmail = JSON.parse(JSON.stringify(this.send_email));
  },
  methods: {
    close() {
      this.send_email = JSON.parse(JSON.stringify(this.defaultSendEmail));
      this.$emit("closeDialog");
    },
    sendEmail(e) {
      if (this.loading) return;

      this.$validator.validate().then((valid) => {
        if (valid) {
          e.preventDefault();

          this.loading = true;
          this.service
            .create(this.send_email)
            .then((response) => {
              if (response.status == true) {
                this.$toast.success("El correo electronico se envio correctamente.", "OK");
              } else {
                if (Object.prototype.hasOwnProperty.call(response, "message")) {
                  this.$toast.error(response.message, "Error");
                } else {
                  this.$toast.error(response.msg, "Error");
                }
              }
            })
            .catch((response) => {
              this.$toast.error(response.msg, "Error");
            })
            .finally(() => {
              this.loading = false;
              this.close();
            });
        }
      });
    },
  },
};
</script>
