<template>
  <v-dialog v-bind:value="dialog" @click:outside="$emit('updateDialog', false)" max-width="750px">
    <v-card>
      <v-form v-on:submit.prevent="createItem">
        <v-card-title>
          <span class="headline">{{ modal_title }}</span>
        </v-card-title>

        <v-card-text>
          <!-- START FORM DIALOG -->
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-9">
                  <v-text-field
                    autocomplete="off"
                    data-vv-as="cuit"
                    label="CUIT (solo numeros)"
                    name="cuit"
                    v-validate="{
                      required: false,
                      regex: /\b(20|23|24|27|30|33|34)[0-9]{8}[0-9]/,
                    }"
                    v-model="item.cuit"
                    :disabled="isViewModal()"
                  ></v-text-field>
                  <span class="red--text">{{ errors.first("cuit") }}</span>
                </div>

                <div class="col-3">
                  <v-btn color="blue-grey" class="w-100 mt-3 text-white" @click="query_padron()" :loading="loading_search_afip"> <font-awesome-icon icon="search" class="mr-3" /> AFIP </v-btn>
                </div>
              </div>

              <v-text-field data-vv-as="Nombre" label="Nombre*" name="name" v-model="item.name" v-validate="'required'" :disabled="isViewModal()"></v-text-field>
              <span class="red--text">{{ errors.first("name") }}</span>

              <v-text-field data-vv-as="Razón social" label="Razón social*" name="business_name" v-model="item.business_name" v-validate="'required'" :disabled="isViewModal()"></v-text-field>
              <span class="red--text">{{ errors.first("business_name") }}</span>

              <v-text-field data-vv-as="Email" label="Email" name="invoiced_email" v-model="item.invoiced_email" :disabled="isViewModal()"></v-text-field>
              <span class="red--text">{{ errors.first("invoiced_email") }}</span>

              <v-text-field data-vv-as="Telefono" label="Telefono" name="telephone" v-model="item.telephone" :disabled="isViewModal()"></v-text-field>
              <span class="red--text">{{ errors.first("telefono") }}</span>

              <v-text-field data-vv-as="Direccion" label="Dirección" name="address" v-model="item.address" :disabled="isViewModal()"></v-text-field>
              <span class="red--text">{{ errors.first("address") }}</span>

              <v-text-field data-vv-as="Identificador externo" label="Identificador externo" name="external_reference" v-model="item.external_reference" :disabled="isViewModal()"></v-text-field>
              <span class="red--text">{{ errors.first("external_reference") }}</span>

              <v-text-field
                class="mb-4"
                type="number"
                hint="Ingresa la cantidad de dias que deben pasar para considerar el cobro de una factura atrasado"
                persistent-hint
                data-vv-as="Cobro atrasado"
                label="Cobro atrasado"
                name="invoice_expiration"
                v-model="item.invoice_expiration"
                :disabled="isViewModal()"
              ></v-text-field>
              <span class="red--text">{{ errors.first("invoice_expiration") }}</span>

              <v-combobox
                v-model="item.price_list"
                name="price_list"
                item-text="name"
                item-value="id"
                :items="prices_list"
                label="Lista de precios"
                autocomplete="off"
                clearable
                :disabled="isViewModal()"
              ></v-combobox>
              <span class="red--text">{{ errors.first("price_list") }}</span>

              <v-combobox
                v-model="item.salesman"
                name="vendedor"
                item-text="name"
                item-value="id"
                :items="vendedores"
                label="Vendedor"
                autocomplete="off"
                clearable
                :disabled="isViewModal()"
              ></v-combobox>
              <span class="red--text">{{ errors.first("vendedor") }}</span>

              <v-checkbox label="Habilitado" :disabled="isViewModal()" v-model="item.enabled"></v-checkbox>
            </div>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="$emit('updateDialog', false)">Cerrar</v-btn>
          <v-btn color="success" text type="submit" v-if="!isViewModal()" :loading="loading">{{ formBtn }}</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import ModalGenericMixin from "@/mixins/ModalGenericMixin.vue";
import { priceListService } from "@/libs/ws/priceListService";
import { salesmanService } from "@/libs/ws/salesmanService";
import { receiversService } from "@/libs/ws/receiversService";

export default {
  mixins: [ModalGenericMixin],
  props: {
    parent: Object,
    service: Object,
    modal_title: String,
    dialog: Boolean,
    item: Object,
    button_name: String,
    formBtn: String,
  },
  data() {
    return {
      show: false,
      roles: [],
      clients: [],
      prices_list: [],
      vendedores: [],
      loading_search_afip: false,
    };
  },
  watch: {
    item() {
      this.$emit("updateItem", this.item);
    },
    dialog() {
      this.errors.clear();
    },
  },
  mounted() {
    this.loadPricesList();
    this.loadVendedores();
  },
  methods: {
    loadPricesList() {
      priceListService
        .filter({ enabled: true })
        .then((response) => {
          this.prices_list = response.result;
        })
        .catch((e) => {});
    },
    loadVendedores() {
      salesmanService
        .filter({ enabled: true })
        .then((response) => {
          this.vendedores = response.result;
        })
        .catch((e) => {});
    },
    isViewModal() {
      return this.formBtn == "";
    },
    query_padron() {
      if (this.item.cuit != null && this.item.cuit != "") {
        this.loading_search_afip = true;
        receiversService
          .query_padron({
            tax_id: this.item.cuit,
            doc_type: 80, //CUIT
          })
          .then((response) => {
            console.log(response);
            if (response.status == true) {
              this.item.business_name = response.result.name;
              this.item.name = response.result.name;
              this.item.address = response.result.address;
              this.item.location = response.result.location;
              this.item.provincia = response.result.provincia;
              this.item.postal_code = response.result.postal_code;
            } else {
              this.$toast.error(response.msg, "Error");
            }
          })
          .catch((response) => {
            console.log(response);
            this.$toast.error(response.msg, "Error");
          })
          .finally(() => {
            this.loading_search_afip = false;
          });
      }
    },
    createItem(e) {
      if (this.loading) return;

      this.$validator.validate().then((valid) => {
        if (valid) {
          e.preventDefault();
          if (!this.item.isExternal) {
            this.item.companyExternal = { name: "", id: 1 };
          }
          this.loading = true;
          if (this.item.id > 0) {
            this.service
              .update(this.item)
              .then((response) => {
                if (response.status == true) {
                  this.$toast.success("El " + this.modal_title + " se modifico correctamente.", "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    this.$toast.error(response.msg, "Error");
                  }
                }
              })
              .catch((response) => {
                this.$toast.error(response.msg, "Error");
              })
              .finally(() => (this.loading = false));
          } else {
            this.service
              .create(this.item)
              .then((response) => {
                if (response.status == true) {
                  this.$toast.success("El " + this.modal_title + " se creo correctamente", "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    this.$toast.error(response.msg, "Error");
                  }
                }
              })
              .catch((response) => {
                console.log(response);
                this.$toast.error(response.msg, "Error");
              })
              .finally(() => (this.loading = false));
          }
        } else {
          console.log(this.errors);
          let msg = "";
          this.errors.items.forEach((e) => {
            msg += e.msg + "\n";
          });
          this.$toast.error(msg, "Error");
        }
      });
    },
  },
};
</script>
