<template>
  <v-layout>
    <v-dialog v-bind:value="dialog" @click:outside="$emit('updateDialog', false)" max-width="550px">
      <v-card>
        <v-form v-on:submit.prevent="createItem">
          <v-card-title>
            <span class="headline">{{ item.label }}</span>
          </v-card-title>

          <v-card-text>
            <!-- START FORM DIALOG -->
            <div class="row">
              <div class="col-md-12">
                <v-text-field data-vv-as="precio" label="Precio*" name="precio" v-model="item.price" v-validate="'decimal'" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("precio") }}</span>

                <v-text-field data-vv-as="bonificacion" label="% Bonificacion*" name="discount_per" v-model="item.discount_per" v-validate="'decimal|min:0|max:99'" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("discount_per") }}</span>
              </div>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('updateDialog', false)">Cerrar</v-btn>
            <v-btn color="success" text type="submit" v-if="!isViewModal()" :loading="loading">{{ formBtn }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import ModalGenericMixin from "@/mixins/ModalGenericMixin.vue";

export default {
  mixins: [ModalGenericMixin],
  props: {
    parent: Object,
    service: Object,
    modal_title: String,
    dialog: Boolean,
    item: Object,
    formBtn: String,
  },
  data() {
    return {
      show: false,
    };
  },
  watch: {
    item() {
      this.$emit("updateItem", this.item);
    },
    dialog() {
      this.errors.clear();
    },
  },
  mounted() {},
  methods: {
    isViewModal() {
      return this.formBtn == "";
    },
    createItem(e) {
      if (this.loading) return;
      this.$validator.validate().then((valid) => {
        if (valid) {
          e.preventDefault();

          this.loading = true;
          //Se crea data para reemplazar criteria_price co nel id
          const data = {
            price_list_id: this.item.price_list_id,
            product_presentation_id: this.item.product_presentation_id,
            price: this.item.price,
            discount_per: this.item.discount_per,
          };

          if (this.item.id > 0) {
            this.service
              .updatePresentation(data)
              .then((response) => {
                if (response.status == true) {
                  this.$toast.success("El " + this.modal_title + " se modifico correctamente.", "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    this.$toast.error(response.msg, "Error");
                  }
                }
              })
              .catch((response) => {
                this.$toast.error(response.msg, "Error");
              })
              .finally(() => (this.loading = false));
          }
        }
      });
    },
  },
};
</script>
