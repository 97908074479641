<script>
import { wsService } from "@/libs/ws/wsService";
export default {
  name: "ExportMixin",
  data() {
    return {
      dialog_export: false,
    };
  },
  mounted() {},
  computed: {},
  methods: {
    exportExcel(path, params, file_name) {
      var currentComponent = this;
      currentComponent.dialog_export = true;

      wsService
        .make_post_request(path, params, {
          responseType: "arraybuffer",
          headers: {
            Accept: "application/vnd.ms-excel",
          },
        })
        .then((response) => {
          //Si existe la propiedad status en el objeto response y es false
          if (Object.prototype.hasOwnProperty.call(response, "status") && !response.status) {
            currentComponent.$toast.error(response.message, "Error");
            return;
          }

          let blob = new Blob([response], {
            type: "application/vnd.ms-excel",
          });
          let link = document.createElement("a");
          link.download = file_name;
          link.href = window.URL.createObjectURL(blob);
          link.target = "_blank";
          link.click();
        })
        .catch(function (error) {
          currentComponent.$toast.error("Ocurrio un error al exportar el listado", "Error");
        })
        .finally(() => {
          currentComponent.dialog_export = false;
        });
    },
  },
};
</script>
