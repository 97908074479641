<template>
  <v-card elevation="1" class="mb-4">
    <v-card-text>
      <h6 class="text-h6 black--text">Datos generales</h6>
      <p class="text-body-2">Ingrese la fecha del pago y el proveedor</p>
      <v-row class="mt-0">
        <v-col md="4">
          <v-combobox
            v-model="item.supplier"
            data-vv-as="proveedor"
            autocomplete="off"
            item-text="name"
            item-value="id"
            :items="suppliers"
            v-validate="'required'"
            label="Proveedor"
            class="pt-0 mt-0"
            clearable
            :disabled="isViewModal || openFromSpent"
            filled
          ></v-combobox>
          <span class="red--text">{{ errors.first("proveedor") }}</span>
        </v-col>
        <v-col md="4" class="mt-2">
          <DatePickerEsp
            :initToday="true"
            label="Fecha"
            :date="item.cbte_fch"
            :isRequired="true"
            name="cbte_fch"
            @setDate="
              (date) => {
                item.cbte_fch = date;
              }
            "
            :isViewModal="isViewModal"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import Constant from "@/constants/sections";
import { generalService } from "@/libs/ws/generalService";
import DatePickerEsp from "@/components/DatePickerEsp.vue";
export default {
  components: { DatePickerEsp },
  props: { item: Object, isViewModal: Boolean, openFromSpent: Boolean, validator: Object },
  created() {
    this.$validator = this.validator;
  },
  data: () => ({ suppliers: [] }),
  watch: {},
  mounted() {
    this.geSuppliers();
  },
  methods: {
    geSuppliers() {
      generalService.setSection(Constant.SECTION_SUPPLIERS);
      generalService
        .filter()
        .then((response) => {
          this.suppliers = response.result;
          this.loading = false;
        })
        .catch((e) => {
          console.log("suppliers.filter error");
          console.log(e);
          this.loading = false;
        });
    },
  },
};
</script>
