<template>
  <div>
    <v-dialog v-model="open" max-width="1200" persistent>
      <v-card class="background-voucher" style="background: rgb(221, 225, 230)">
        <v-card-title class="headline mb-4">{{ title }}</v-card-title>

        <v-card-text>
          <div class="row">
            <div class="col-md-12">
              <!--Informacion Monotribto-->
              <MonotributoInfo :invoice="invoice" />

              <!--Datos generales-->
              <DatosGenerales
                :invoice_to_cancel="invoice_to_cancel"
                :invoice="invoice"
                :loading_combos="loading_combos"
                @getLastvoucherOf="getLastvoucherOf"
                :open="open"
                :settings="settings"
                :validator="$validator"
                :receiver="receiver"
                :emitter="emitter"
              />

              <!-- Comprobante Asociado -->
              <ComprobanteAsociado
                :invoice="invoice"
                :invoice_to_cancel="invoice_to_cancel"
                :loading_combos="loading_combos"
                :comprobante_asociado="comprobante_asociado"
                :loading_voucher_asociado="loading_voucher_asociado"
                :error_search_cbte_asociado="error_search_cbte_asociado"
                @selectComboId="selectComboId"
                @searchCbteAsociado="searchCbteAsociado"
                :validator="$validator"
              />

              <!--Periodo a imputar-->
              <Periodo :invoice="invoice" :validator="$validator" />

              <!-- Compra con o sin stock -->
              <ConStock :invoice="invoice" :validator="$validator" :open="open" :isViewModal="isViewModal" />

              <!-- Items Voucher -->
              <ItemsVoucher :invoice="invoice" :open="open" :loading_combos="loading_combos" @loadItemsFromInvoiceCancel="loadItemsFromInvoiceCancel" :validator="$validator" :items="items" />

              <!-- Tributos -->
              <Tributos :invoice="invoice" :open="open" :loading_combos="loading_combos" @loadItemsFromInvoiceCancel="loadItemsFromInvoiceCancel" :validator="$validator" />

              <!--Entrega-->
              <Shipping :item="invoice" :client="invoice.receiver != null ? invoice.receiver.client : null" :isViewModal="isViewModal" :validator="$validator" :isVoucher="true" />
              <!--Totales-->
              <Observation :item="invoice" :isViewModal="isViewModal" />

              <v-row>
                <!--Info Extra-->
                <InfoExtra :dialog="open" :item="invoice" minHeight="300px" :show_cost_center="true" :isViewModal="isViewModal" />
                <!--Totales-->
                <Totales
                  :invoice="invoice"
                  :imp_iva="imp_iva"
                  :imp_neto="imp_neto"
                  :imp_tot_conc="imp_tot_conc"
                  :imp_op_ex="imp_op_ex"
                  :imp_total="imp_total"
                  :imp_trib="imp_trib"
                  :imp_discount="imp_discount"
                  :isViewModal="isViewModal"
                />
              </v-row>

              <v-row v-if="invoice_to_generate != null">
                <v-col md="12" class="pb-0">
                  <v-alert color="#2A3B4D" class="text-white" border="start" density="compact">
                    La factura se marcara automaticamente como cobrada, generando un comprobante de cobro asociado, los impuestos y comisiones generados por dicho cobro seran registrados como un
                    gasto.
                  </v-alert>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="close()"> Cancelar </v-btn>

          <div v-if="!isViewModal">
            <v-btn color="green darken-1" text v-if="remitos != null" @click="generateInvoice"> {{ invoice.id == "" ? "Generar" : "Editar" }} </v-btn>
          </div>

          <div v-if="!isViewModal">
            <div v-if="invoice.id == ''">
              <v-menu offset-y v-if="remitos == null">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="green darken-1" text dark v-bind="attrs" v-on="on" :loading="loading"> Generar <v-icon right dark> arrow_drop_down </v-icon> </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="generateInvoice">
                    <v-list-item-title>Generar comprobante</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    @click="generateInvoiceAndRemito"
                    v-if="invoice.cbte_tipo != null && !vouchersService.NoteCreditList().includes(invoice.cbte_tipo.Id) && !vouchersService.NoteDebittList().includes(invoice.cbte_tipo.Id)"
                  >
                    <v-list-item-title>Generar comprobante y remito</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <div v-else>
              <v-btn color="green darken-1" text @click="generateInvoice">Editar</v-btn>
            </div>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <DialogLoading :dialog="dialog_loading" text="Cargando..." />
  </div>
</template>
<script>
import { vouchersService } from "@/libs/ws/vouchersService";
import { emmiterService } from "@/libs/ws/emmiterService";
import { billingUtils } from "@/libs/utils/billingUtils";

import { formatUtils } from "@/libs/utils/formatUtils";
import DialogLoading from "@/components/DialogLoading.vue";
import SettingsMixin from "@/mixins/SettingsMixin.vue";

import DatosGenerales from "@/views/Billing/Vouchers/VoucherComponent/DatosGenerales.vue";
import MonotributoInfo from "@/views/Billing/Vouchers/VoucherComponent/MonotributoInfo.vue";
import ItemsVoucher from "@/views/Billing/Vouchers/VoucherComponent/ItemsVoucher.vue";
import Tributos from "@/views/Billing/Vouchers/VoucherComponent/Tributos.vue";
import Totales from "@/views/Billing/Vouchers/VoucherComponent/Totales.vue";
import ComprobanteAsociado from "@/views/Billing/Vouchers/VoucherComponent/ComprobanteAsociado.vue";
import ConStock from "@/views/Billing/Vouchers/VoucherComponent/ConStock.vue";

import Periodo from "@/components/Comprobantes/Periodo.vue";
import InfoExtra from "@/components/Comprobantes/InfoExtra.vue";
import Shipping from "@/components/Comprobantes/Shipping.vue";
import Observation from "@/components/Comprobantes/Observation.vue";
export default {
  components: { ConStock, DialogLoading, DatosGenerales, Periodo, MonotributoInfo, ItemsVoucher, Tributos, InfoExtra, Totales, ComprobanteAsociado, Shipping, Observation },
  mixins: [SettingsMixin],
  props: {
    title: String,
    checkbox_label: String,
    open: Boolean,
    isViewModal: {
      default: false,
      type: Boolean,
    },
    invoice_to_cancel: {
      default: null,
      type: Object,
    },
    invoice_to_generate: {
      default: null,
      type: Object,
    },
    closeDialog: Function,
    openModalRemito: Function,
    reload: Function,
    cancel: Function,
    //se usa para ver el comprobante
    item: Object,
    updateMainItem: Function,
    receiver: {
      //Se usa para generar voucher a partir de remitos
      default: null,
      type: Object,
    },
    emitter: {
      //Se usa para generar voucher a partir de remitos
      default: null,
      type: Object,
    },
    items: {
      //Se usa para generar voucher a partir de remitos
      default: null,
      type: Array,
    },
    remitos: {
      //Se usa para generar voucher a partir de remitos
      default: null,
      type: Array,
    },
    shipping_address: {
      //Se usa para generar voucher a partir de remitos
      default: null,
      type: String,
    },
  },
  data: () => ({
    emmiterService: emmiterService,
    vouchersService: vouchersService,
    loading: false,
    dialog_loading: false,
    formatUtils: formatUtils,
    checkbox: false,
    ivas: [],
    comprobante_asociado: {
      id: null,
      voucher_number: null,
      pto_vta: null,
      cbte_tipo: null,
      anulacion: null,
    },
    comprobante_asociado_default: null,
    loading_voucher_asociado: false,
    error_search_cbte_asociado: false,
    invoice: {
      id: "",
      motivo: null,
      nro_orden_compra: null,
      sub_cost_center: null,
      warehouse: null,
      dolar_cotiz: null,
      emitter: null,
      receiver: null,
      cbte_tipo: null,
      shipping_address: null,
      client_shipping_address: null,
      pto_vta: null,
      cbte_fch: new Date(
        new Date().toLocaleDateString("en-US", {
          timeZone: "America/Buenos_Aires",
        })
      )
        .toISOString()
        .slice(0, 10),
      cbte_fch_serv_desde: new Date(
        new Date().toLocaleDateString("en-US", {
          timeZone: "America/Buenos_Aires",
        })
      )
        .toISOString()
        .slice(0, 10),
      cbte_fch_serv_hasta: new Date(
        new Date().toLocaleDateString("en-US", {
          timeZone: "America/Buenos_Aires",
        })
      )
        .toISOString()
        .slice(0, 10),
      fch_vto_pago: new Date(
        new Date().toLocaleDateString("en-US", {
          timeZone: "America/Buenos_Aires",
        })
      )
        .toISOString()
        .slice(0, 10),
      concepto: null,
      moneda: null,
      mon_cotiz: 1,
      items_custom: [],
      tributes: [],
      cbte_asoc: null,
      observation: "",
      shipping_type: null,
      shipping_carrier: null,
    },
    //Se separaron de invoice para poder usar el watch sin hacer un bucle infinito
    imp_total: 0,
    imp_neto: 0,
    imp_tot_conc: 0,
    imp_op_ex: 0,
    imp_iva: 0,
    imp_trib: 0,
    imp_discount: 0,
    defaultInvoice: null,
    loading_combos: {
      cbte_tipos_invoice: false,
      emitters: false,
      receivers: false,
      cbte_tipos: false,
      ptosvtas: false,
      conceptos: false,
      monedas: false,
      ivas: false,
      tributes: false,
    },
  }),
  created: function () {
    window.addEventListener("keydown", (e) => {
      if (e.key == "Escape") {
        this.close();
      }
    });
  },
  watch: {
    open: function (val) {
      if (val) {
        this.init();
      }

      if (this.item && this.item.id != "") {
        this.invoice = JSON.parse(JSON.stringify(this.item));

        if (this.item.cbtes_asoc.length > 0) {
          this.comprobante_asociado = JSON.parse(JSON.stringify(this.item.cbtes_asoc[0].voucher_asoc));
          this.comprobante_asociado.anulacion = this.item.cbtes_asoc[0].anulacion;
        } else {
          this.comprobante_asociado = JSON.parse(JSON.stringify(this.comprobante_asociado_default));
        }

        this.imp_total = this.item.imp_total;
        this.imp_neto = this.item.imp_neto;
        this.imp_tot_conc = this.item.imp_tot_conc;
        this.imp_op_ex = this.item.imp_op_ex;
        this.imp_iva = this.item.imp_iva;
        this.imp_trib = this.item.imp_trib;
        this.imp_discount = this.item.imp_discount;
        this.invoice.items_custom = this.item.items_custom;
        this.invoice.tributes = this.item.tributos;
      }
    },

    invoice: {
      handler: function (val) {
        this.calculateTotal();
      },
      deep: true,
    },
    loading_combos: {
      handler: function (val) {
        if (this.isFinishedLoadingCombos()) {
          this.dialog_loading = false;
        }
      },
      deep: true,
    },
    shipping_address: {
      handler: function (val) {
        this.invoice.shipping_address = this.shipping_address;
      },
      deep: true,
    },
  },
  mounted() {
    this.defaultInvoice = JSON.parse(JSON.stringify(this.invoice));
    this.comprobante_asociado_default = JSON.parse(JSON.stringify(this.comprobante_asociado));
  },
  methods: {
    isFinishedLoadingCombos() {
      return (
        this.loading_combos["cbte_tipos_invoice"] == false &&
        this.loading_combos["emitters"] == false &&
        this.loading_combos["receivers"] == false &&
        this.loading_combos["cbte_tipos"] == false &&
        this.loading_combos["ptosvtas"] == false &&
        this.loading_combos["conceptos"] == false &&
        this.loading_combos["monedas"] == false &&
        this.loading_combos["ivas"] == false &&
        this.loading_combos["tributes"] == false
      );
    },
    init() {
      this.errors.clear();
      this.$validator.reset();
      this.dialog_loading = true;

      let invoice_sample = this.invoice_to_generate;
      if (invoice_sample == null) invoice_sample = this.invoice_to_cancel;
      if (invoice_sample != null) {
        this.invoice.emitter = invoice_sample.emitter;
        this.invoice.receiver = invoice_sample.receiver;
        this.invoice.sub_cost_center = invoice_sample.sub_cost_center;

        //solo si voy envio una factura a cancelar por props
        if (this.invoice_to_cancel != null) {
          this.comprobante_asociado.voucher_number = invoice_sample.voucher_number;
          this.comprobante_asociado.pto_vta = invoice_sample.pto_vta;
          this.comprobante_asociado.cbte_tipo = invoice_sample.cbte_tipo;
          this.searchCbteAsociado();
          this.loadItemsFromInvoiceCancel();
        }

        this.invoice.cbte_fch = invoice_sample.cbte_fch;
        this.invoice.cbte_fch_serv_desde = invoice_sample.cbte_fch_serv_desde;
        this.invoice.cbte_fch_serv_hasta = invoice_sample.cbte_fch_serv_hasta;
        this.invoice.fch_vto_pago = invoice_sample.fch_vto_pago;
        this.invoice.mon_cotiz = invoice_sample.mon_cotiz;

        if (this.invoice_to_generate != null) {
          //Viene de una integracion
          //Agrego indicadores de la integracion
          this.invoice.purchase_origin = this.invoice_to_generate.purchase_origin;
          this.invoice.external_reference = this.invoice_to_generate.external_reference;

          this.invoice.money_account_id = this.invoice_to_generate.money_account_id;
          this.invoice.charge_cbte_fch = this.invoice_to_generate.charge_cbte_fch;
          this.invoice.spent_cbte_fch = this.invoice_to_generate.spent_cbte_fch;
          this.invoice.spent_imp_total = this.invoice_to_generate.spent_imp_total;
        }
      } else {
        this.dialog_loading = false;
      }
    },
    getLastvoucherOf() {
      if (this.settings != null && this.settings.preload_last_voucher && this.invoice.emitter != null && this.invoice.emitter.id > 0 && this.invoice.receiver != null && this.invoice.receiver.id > 0) {
        this.dialog_loading = true;
        vouchersService
          .getLastvoucherOf(this.invoice.emitter.id, this.invoice.receiver.id)
          .then((response) => {
            if (response.status && response.result) {
              this.invoice.items_custom = response.result.items_custom;
            }
          })
          .finally(() => (this.dialog_loading = false));
      }
    },
    loadItemsFromInvoiceCancel() {
      let items = {};
      let invoice_sample = this.invoice_to_generate;
      if (invoice_sample == null) invoice_sample = this.invoice_to_cancel;

      if (invoice_sample != null) {
        invoice_sample.items_custom.forEach((item) => {
          /*
          if (!Object.hasOwn(items, item.iva_id)) {
            //POque tiene que separar los creditos por tipo de IVA y accumularlos
            items[item.iva_id] = {
              descripcion: this.invoice_to_cancel != null ? "Credito por factura " + invoice_sample.voucher_number : "",
              quantity: 1, //No se aumenta la cantidad porque el total va por el precio
              price: 0,
              product_presentation: null,
              iva: this.selectComboId(this.ivas, item.iva_id, "Id"),
              total: 0, //el total lo calcula el frontend con iva
            };
          }
          items[item.iva_id].price += parseFloat(item.price);
          */
          this.invoice.items_custom.push({
            descripcion: item.product_presentation.name,
            quantity: item.quantity,
            price: item.price,
            discount_per: item.discount_per,
            product_presentation: item.product_presentation,
            iva: item.iva,
            total: item.total,
          });
        });
        /*
        Object.keys(items).forEach((key) => {
          this.invoice.items_custom.push(items[key]);
        });
        */
      }
    },
    selectComboId(combo, id, property_id = "id") {
      if (combo != null && combo.length > 0) {
        for (let i = 0; i < combo.length; i++) {
          if (combo[i][property_id] == id) {
            return combo[i];
          }
        }
      }
      return null;
    },
    close() {
      this.comprobante_asociado = JSON.parse(JSON.stringify(this.comprobante_asociado_default));
      this.invoice = JSON.parse(JSON.stringify(this.defaultInvoice));

      this.$emit("updateMainItem");
      this.$emit("closeDialog");
    },
    generateInvoice(event, generate_remito = false) {
      this.$validator.validate().then((valid) => {
        if (valid) {
          if (this.comprobante_asociado.cbte_tipo != null && this.invoice.cbte_asoc == null) {
            this.$toast.error("No se encontro el comprobante asociado.", "Error");
            return;
          }

          let data = this.getInvoiceParams();
          if (data.imp_total <= 0) {
            this.$toast.error("El importe del comprobante debe ser mayor a 0.", "Error");
            return;
          }
          this.loading = true;

          if (this.item && this.item.id > 0) {
            vouchersService
              .update(data)
              .then((response) => {
                if (response.status) {
                  this.$toast.success("La Factura se editó correctamente", "OK");

                  this.invoice = JSON.parse(JSON.stringify(this.defaultInvoice));
                  this.comprobante_asociado = JSON.parse(JSON.stringify(this.comprobante_asociado_default));
                  this.$emit("reload");
                  this.close();
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    if (Object.prototype.hasOwnProperty.call(response, "message")) {
                      this.$toast.error(response.message, "Error");
                    } else {
                      this.$toast.error(response.msg, "Error");
                    }
                  }
                }
              })
              .catch((response) => {
                this.$toast.error(response.msg, "Error");
              })
              .finally(() => {
                this.loading = false;
              });
          } else {
            vouchersService
              .create(data)
              .then((response) => {
                if (response.status) {
                  this.$toast.success("La Factura se generó correctamente", "OK");
                  if (generate_remito) {
                    console.log("response.result", response.result);
                    this.generateRemito(response.result);
                  }
                  this.invoice = JSON.parse(JSON.stringify(this.defaultInvoice));
                  this.comprobante_asociado = JSON.parse(JSON.stringify(this.comprobante_asociado_default));
                  this.$emit("reload");
                  this.close();
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    if (Object.prototype.hasOwnProperty.call(response, "message")) {
                      this.$toast.error(response.message, "Error");
                    } else {
                      this.$toast.error(response.msg, "Error");
                    }
                  }
                }
              })
              .catch((response) => {
                this.$toast.error(response.msg, "Error");
              })
              .finally(() => {
                this.loading = false;
              });
          }
        } else {
          this.$toast.error("Complete los campos requeridos", "Error");
        }
      });
    },
    getInvoiceParams() {
      let data = {
        warehouse_id: this.isProductCompany() ? this.invoice.warehouse.id : null,

        id: this.invoice.id,
        emitter_id: this.invoice.emitter.id,
        receiver_id: this.invoice.receiver.id,
        cbte_tipo: this.invoice.cbte_tipo.Id,
        pto_vta: this.invoice.pto_vta,
        cbte_fch: this.invoice.cbte_fch,
        cbte_fch_serv_desde: this.invoice.cbte_fch_serv_desde,
        cbte_fch_serv_hasta: this.invoice.cbte_fch_serv_hasta,
        fch_vto_pago: this.invoice.fch_vto_pago,
        concepto: this.invoice.concepto.Id,
        mon_id: this.invoice.moneda.Id,
        mon_cotiz: this.invoice.moneda.Id == "PES" ? 1 : this.invoice.mon_cotiz,
        dolar_cotiz: this.invoice.dolar_cotiz,
        observation: this.invoice.observation,
        shipping_address: this.invoice.shipping_address,
        client_shipping_address: this.invoice.client_shipping_address,
        shipping_type: this.invoice.shipping_type != null ? this.invoice.shipping_type.id : null,
        shipping_carrier: this.invoice.shipping_carrier != null ? this.invoice.shipping_carrier.id : null,
        sub_cost_center_id: this.invoice.sub_cost_center != null ? this.invoice.sub_cost_center.id : null,

        imp_total: parseFloat(this.imp_total).toFixed(2),//Para enviar el mismo
        imp_neto: parseFloat(this.imp_neto).toFixed(2),//Para enviar el mismo
        imp_tot_conc: parseFloat(this.imp_tot_conc).toFixed(2),//Para enviar el mismo
        imp_iva: parseFloat(this.imp_iva).toFixed(2),//Para enviar el mismo
        imp_op_ex: parseFloat(this.imp_op_ex).toFixed(2),//Para enviar el mismo
        imp_trib: parseFloat(this.imp_trib).toFixed(2),//Para enviar el mismo
        imp_discount: parseFloat(this.imp_discount).toFixed(2),//Para enviar el mismo
        nro_orden_compra: this.invoice.nro_orden_compra,        
        motivo: this.invoice.motivo,

        items_custom_arr: [],
        tributes_arr: [],
        cbte_asoc: this.invoice.cbte_asoc != null ? this.invoice.cbte_asoc.id : null,
        anulacion: this.comprobante_asociado != null && this.comprobante_asociado.anulacion != null ? this.comprobante_asociado.anulacion.id : null,
      };

      if (this.remitos != null) {
        data.remitos_ids = this.remitos.map((a) => a.id);
      }

      this.invoice.items_custom.forEach((item) => {
        const item_data = this.calculateCustomeItemTotals(item);
        data.items_custom_arr.push({
          desc: item.descripcion,
          iva_id: item.iva != null ? item.iva.Id : null,
          iva_desc: item.iva != null ? item.iva.Desc : "",
          imp_neto: item_data.imp_neto,
          imp_iva: item_data.imp_iva,
          price: item.price,
          product_presentation: item.product_presentation,
          quantity: item.quantity,
          discount_per: parseFloat(item.discount_per),
          discount_imp: item.discount_imp,
        });
      });

      this.invoice.tributes.forEach((tribute_item) => {
        data.tributes_arr.push({
          desc: tribute_item.descripcion,
          tribute_id: tribute_item.tribute != null ? tribute_item.tribute.Id : null,
          tribute_desc: tribute_item.tribute != null ? tribute_item.tribute.Desc : "",
          alic: tribute_item.alic,
          base_imp: tribute_item.base_imp,
        });
      });

      if (this.invoice_to_generate != null) {
        //Viene de una integracion
        //Agrego indicadores de la integracion
        data.purchase_origin = this.invoice_to_generate.purchase_origin;
        data.external_reference = this.invoice_to_generate.external_reference;

        data.money_account_id = this.invoice_to_generate.money_account_id;
        data.charge_cbte_fch = this.invoice_to_generate.charge_cbte_fch;
        data.spent_cbte_fch = this.invoice_to_generate.spent_cbte_fch;
        data.spent_imp_total = this.invoice_to_generate.spent_imp_total;
      }

      return data;
    },
    calculateCustomeItemTotals(item) {
      if (item.price == null || item.quantity == null)
        return {
          imp_neto: 0,
          imp_iva: 0,
        };

      let imp_neto = formatUtils.formatPriceNumber(item.quantity * item.price - item.discount_imp);
      const iva_id = item.iva != null ? item.iva.Id : null;
      let imp_iva =
        vouchersService.NoIVAList().includes(iva_id) || iva_id == null ? 0.0 : (formatUtils.formatPriceNumber(imp_neto) * formatUtils.formatPriceNumber(item.iva.Desc.replace("%", ""))) / 100;
      return {
        imp_neto: imp_neto,
        imp_iva: formatUtils.formatPriceNumber(imp_iva),
        discount_imp: item.discount_imp,
        total: formatUtils.formatPriceNumber(imp_neto) + formatUtils.formatPriceNumber(imp_iva),
      };
    },

    calculateTotal() {
      this.imp_total = 0.0;
      this.imp_neto = 0.0;
      this.imp_tot_conc = 0.0;
      this.imp_iva = 0.0;
      this.imp_trib = 0.0;
      this.imp_op_ex = 0.0;
      this.imp_discount = 0.0;

      this.invoice.items_custom.forEach((item) => {
        //Update discount_imp
        if (item.price != null && item.quantity != null && item.discount_per != null) item.discount_imp = (item.price * item.quantity * item.discount_per) / 100;
        else item.discount_imp = 0;

        let item_totales_obj = this.calculateCustomeItemTotals(item);
        if (this.invoice.emitter != null && this.invoice.emitter.iva_condition.id != emmiterService.CONDITION_IVA_MONO) {
          item_totales_obj["iva_id"] = item.iva == null ? null : item.iva.Id;

          let imp_neto = billingUtils.getTotalFor("imp_neto", item_totales_obj);
          let imp_tot_conc = billingUtils.getTotalFor("imp_tot_conc", item_totales_obj);
          let imp_op_ex = billingUtils.getTotalFor("imp_op_ex", item_totales_obj);
          let imp_iva = item_totales_obj.imp_iva;

          this.imp_tot_conc += imp_tot_conc;
          this.imp_iva += imp_iva;
          this.imp_op_ex += imp_op_ex;
          this.imp_neto += imp_neto;
          this.imp_total += imp_neto + imp_tot_conc + imp_op_ex + imp_iva;
        } else {
          this.imp_neto += item_totales_obj.total;
          this.imp_total += item_totales_obj.total;
        }
      });

      this.invoice.tributes.forEach((tribute) => {
        if (this.invoice.emitter.iva_condition.id != emmiterService.CONDITION_IVA_MONO) {
          const tributo_amount = (tribute.alic / 100.0) * tribute.base_imp;
          this.imp_trib += parseFloat(tributo_amount.toFixed(2));
          this.imp_total += parseFloat(tributo_amount.toFixed(2));
        }
      });
    },
    searchCbteAsociado() {
      this.invoice.cbte_asoc = null; //Reset Asoc
      this.error_search_cbte_asociado = false;

      if (this.comprobante_asociado.voucher_number != null && this.comprobante_asociado.pto_vta != null && this.comprobante_asociado.cbte_tipo != null && this.comprobante_asociado.cbte_tipo.Id > 0) {
        if (!this.loading_voucher_asociado) {
          this.loading_voucher_asociado = true;

          vouchersService
            .getVoucherAsoc(this.invoice.emitter.id, this.comprobante_asociado.voucher_number, this.comprobante_asociado.pto_vta, this.comprobante_asociado.cbte_tipo.Id, this.invoice.receiver.id)
            .then((response) => {
              if (response.status) {
                this.invoice.cbte_asoc = response.result;
              } else {
                this.error_search_cbte_asociado = true;
              }
            })
            .finally(() => {
              this.loading_voucher_asociado = false;
            });
        }
      }
    },
    generateInvoiceAndRemito() {
      this.generateInvoice(null, true);
    },
    generateRemito(voucher) {
      this.$emit("openModalRemito", voucher);
    },
  },
};
</script>
