import { wsService } from "./wsService";

const section = "remitos";
const STATE_UNBILLED = 0;
const STATE_BILLED = 1;
const STATE_CANCELED = 2;

function filter(param) {
  return wsService.make_post_request(section + "/list", param);
}

function create(param = {}) {
  return wsService.make_post_request(section + "/create", param);
}

function update(param = {}) {
  return wsService.make_post_request(section + "/update", param);
}

function remove(param = {}) {
  return wsService.make_delete_request(section + "/delete", param);
}

function get_states(param = {}) {
  return wsService.make_get_request(section + "/states", param);
}

function getStateHtml(state) {
  switch (parseInt(state.id)) {
    case STATE_UNBILLED:
      return '<p class="state_accounting_unbilled">' + state.name + "</p>";
    case STATE_BILLED:
      return '<p class="state_accounting_billed">' + state.name + "</p>";
    case STATE_CANCELED:
      return '<p class="state_accounting_canceled">' + state.name + "</p>";
    default:
      return "-";
  }
}

function get_next_number(emitter_id, pto_vta) {
  return wsService.make_post_request(section + "/get_next_number", { emitter_id: emitter_id, pto_vta: pto_vta });
}

function print_syscore(remito_id) {
  return wsService.make_post_request(
    section + "/print_syscore/" + remito_id,
    {},
    {
      responseType: "arraybuffer",
      headers: {
        Accept: "application/pdf",
      },
    }
  );
}

function print_syscore_of_invoice(remito_id) {
  return wsService.make_post_request(
    section + "/print_syscore_of_invoice/" + remito_id,
    {},
    {
      responseType: "arraybuffer",
      headers: {
        Accept: "application/pdf",
      },
    }
  );
}

function print_custom_of_invoice(remito_id) {
  return wsService.make_post_request(
    section + "/print_custom_of_invoice/" + remito_id,
    {},
    {
      responseType: "arraybuffer",
      headers: {
        Accept: "application/pdf",
      },
    }
  );
}

function print_custom(remito_id) {
  return wsService.make_post_request(
    section + "/print_custom/" + remito_id,
    {},
    {
      responseType: "arraybuffer",
      headers: {
        Accept: "application/pdf",
      },
    }
  );
}

function getVouchers(param = {}) {
  return wsService.make_post_request(section + "/get_vouchers", param);
}

function setVoucher(param = {}) {
  return wsService.make_post_request(section + "/set_voucher", param);
}

export const remitoService = {
  section,
  get_states,
  getStateHtml,
  filter,
  remove,
  create,
  update,
  get_next_number,
  print_syscore,
  print_syscore_of_invoice,
  print_custom_of_invoice,
  print_custom,
  getVouchers,
  setVoucher,
  //Constants
  STATE_UNBILLED,
  STATE_BILLED,
};
