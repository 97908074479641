const EMPLOYEE = "E";
const COMPANY = "C";
const SUPERUSER = "S";


export default { 
    EMPLOYEE: EMPLOYEE,
    COMPANY: COMPANY,
    SUPERUSER: SUPERUSER,
};
