<template>
  <v-layout>
    <v-dialog v-bind:value="dialog" @click:outside="$emit('updateDialog', false)" max-width="500px">
      <template v-slot:activator="{ on }">
        <v-btn color="primary" class="mb-5" dark v-on="on" @click="$emit('updateDialog', true)">{{ button_name }}</v-btn>
      </template>

      <v-card>
        <v-form v-on:submit.prevent="createItem">
          <v-card-title>
            <span class="headline">{{ modal_title }}</span>
          </v-card-title>

          <v-card-text>
            <!-- START FORM DIALOG -->
            <div class="row">
              <div class="col-md-12">
                <v-text-field data-vv-as="code" label="Código de sucursal" name="code" v-model="item.code" v-validate="'max:60'" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("code") }}</span>

                <v-text-field data-vv-as="nombre" label="Nombre*" name="name" v-model="item.name" v-validate="'required'" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("name") }}</span>

                <v-text-field data-vv-as="gln_code" label="Código GLN" name="gln_code" v-model="item.gln_code" v-validate="'max:60'" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("gln_code") }}</span>

                <v-text-field
                  autocomplete="off"
                  data-vv-as="cuit"
                  label="CUIT"
                  name="cuit"
                  v-validate="'integer|min:11|max:11'"
                  v-model="item.cuit"
                  :disabled="isViewModal()"
                ></v-text-field>
                <span class="red--text">{{ errors.first("cuit") }}</span>

                <v-text-field data-vv-as="direccion" label="Direccion*" name="shipping_address" v-model="item.shipping_address" v-validate="'required'" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("shipping_address") }}</span>
              </div>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('updateDialog', false)">Cerrar</v-btn>
            <v-btn color="success" text type="submit" v-if="!isViewModal()" :loading="loading">{{ formBtn }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import ModalGenericMixin from "@/mixins/ModalGenericMixin.vue";
import { clientsService } from "@/libs/ws/clientsService";
export default {
  mixins: [ModalGenericMixin],
  props: {
    parent: Object,
    service: Object,
    modal_title: String,
    dialog: Boolean,
    item: Object,
    button_name: String,
    formBtn: String,
    client: String,
  },
  data() {
    return {
      show: false,
    };
  },
  watch: {
    item() {
      this.$emit("updateItem", this.item);
    },
    dialog() {
      this.errors.clear();
    },
  },
  mounted() {
    this.loadClients();
  },
  methods: {
    loadClients() {
      clientsService
        .filter()
        .then((response) => {
          this.clients = response.result;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    isViewModal() {
      return this.formBtn == "";
    },
    createItem(e) {
      if (this.loading) return;
      this.$validator.validate().then((valid) => {
        if (valid) {
          e.preventDefault();

          this.loading = true;
          //Se crea data para reemplazar criteria_price co nel id
          const data = {
            id: this.item.id,
            name: this.item.name,
            cuit: this.item.cuit,
            code: this.item.code,
            gln_code: this.item.gln_code,
            shipping_address: this.item.shipping_address,
            client: { id: this.client },
          };

          if (this.item.id > 0) {
            this.service
              .update(data)
              .then((response) => {
                if (response.status == true) {
                  this.$toast.success("El " + this.modal_title + " se modifico correctamente.", "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    this.$toast.error(response.msg, "Error");
                  }
                }
              })
              .catch((response) => {
                this.$toast.error(response.msg, "Error");
              })
              .finally(() => (this.loading = false));
          } else {
            this.service
              .create(data)
              .then((response) => {
                console.log(response);
                if (response.status) {
                  this.$toast.success("El " + this.modal_title + " se creo correctamente", "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    this.$toast.error(response.msg, "Error");
                  }
                }
              })
              .catch((response) => {
                console.log(response);
                this.$toast.error(response.msg, "Error");
              })
              .finally(() => (this.loading = false));
          }
        }
      });
    },
  },
};
</script>
