<template>
  <v-layout>
    <v-dialog v-bind:value="dialog" @click:outside="$emit('updateDialog', false)" max-width="750px">
      <template v-slot:activator="{ on }">
        <v-btn color="primary" class="mb-5" dark v-on="on" @click="$emit('updateDialog', true)">{{ button_name }}</v-btn>
      </template>

      <v-card>
        <v-form v-on:submit.prevent="createItem">
          <v-card-title>
            <span class="headline">{{ modal_title }}</span>
          </v-card-title>

          <v-card-text>
            <!-- START FORM DIALOG -->
            <div class="row">
              <div class="col-md-12">
                <v-text-field data-vv-as="nombre" label="Nombre*" name="name" v-model="item.name" v-validate="'required'" :disabled="isViewModal()" />
                <span class="red--text">{{ errors.first("name") }}</span>

                <v-text-field data-vv-as="email" label="Email" name="email" v-model="item.email" :disabled="isViewModal()" />
                <span class="red--text">{{ errors.first("email") }}</span>

                <v-text-field data-vv-as="telefono" label="Telefono" name="telephone" v-model="item.telephone" :disabled="isViewModal()" />
                <span class="red--text">{{ errors.first("telephone") }}</span>

                <v-combobox
                  v-model="item.type"
                  data-vv-as="tipo de comision"
                  name="type"
                  item-text="name"
                  item-value="id"
                  :items="types"
                  label="Tipo de comisión*"
                  autocomplete="off"
                  v-validate="'required'"
                  :disabled="isViewModal()"
                ></v-combobox>
                <span class="red--text">{{ errors.first("type") }}</span>

                <v-text-field
                  class="mt-3 mb-0"
                  data-vv-as="Comision"
                  label="Comisión*"
                  name="Comision"
                  prepend-icon="mdi-percent"
                  filled
                  v-model="item.commission"
                  v-validate="'required'"
                  :disabled="isViewModal()"
                />
                <span class="red--text">{{ errors.first("Comision") }}</span>

                <v-checkbox label="Incluir el IVA al calculo de las comisiones" :disabled="isViewModal()" v-model="item.tax"></v-checkbox>

                <v-checkbox label="Restar las retenciones/percepciones al calcular las comisiones" :disabled="isViewModal()" v-model="item.tribute"></v-checkbox>

                <v-checkbox v-if="item.type != null && item.type.id == 2" label="Incluir cobros en efectivo" :disabled="isViewModal()" v-model="item.include_cash_payments"></v-checkbox>

                <v-checkbox label="Habilitado" :disabled="isViewModal()" v-model="item.enabled"></v-checkbox>
              </div>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('updateDialog', false)">Cerrar</v-btn>
            <v-btn color="success" text type="submit" v-if="!isViewModal()" :loading="loading">{{ formBtn }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import ModalGenericMixin from "@/mixins/ModalGenericMixin.vue";

export default {
  mixins: [ModalGenericMixin],
  props: {
    parent: Object,
    service: Object,
    modal_title: String,
    page_title: String,
    dialog: Boolean,
    item: Object,
    button_name: String,
    formBtn: String,
  },
  data() {
    return {
      show: false,
      types: [],
    };
  },
  watch: {
    item() {
      this.$emit("updateItem", this.item);
    },
    dialog() {
      this.errors.clear();
    },
  },
  mounted() {
    this.loadTypes();
  },
  methods: {
    isViewModal() {
      return this.formBtn == "";
    },
    loadTypes() {
      this.service
        .getArrTypes()
        .then((response) => {
          this.types = response.result;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    createItem(e) {
      this.$validator.validate().then((valid) => {
        if (valid) {
          e.preventDefault();
          this.loading = true;

          const data = this.item;
          data["type"] = this.item.type.id;
          data["tax"] = this.item.tax ? 1 : 0;
          data["tribute"] = this.item.tribute ? 1 : 0;
          data["enabled"] = this.item.enabled ? 1 : 0;

          if (this.item.type != null && this.item.type.id == 2) {
            data["include_cash_payments"] = this.item.include_cash_payments ? 1 : 0;
          }

          if (this.item.id > 0) {
            this.service
              .update(data)
              .then((response) => {
                if (response.status == true) {
                  this.$toast.success("El " + this.modal_title + " se modifico correctamente.", "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    this.$toast.error(response.msg, "Error");
                  }
                }
              })
              .catch((response) => {
                this.$toast.error(response.msg, "Error");
              })
              .finally(() => (this.loading = false));
          } else {
            this.service
              .create(data)
              .then((response) => {
                console.log(response);
                if (response.status) {
                  this.$toast.success("El " + this.modal_title + " se creo correctamente", "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    this.$toast.error(response.msg, "Error");
                  }
                }
              })
              .catch((response) => {
                this.$toast.error(response.msg, "Error");
              })
              .finally(() => (this.loading = false));
          }
        }
      });
    },
  },
};
</script>
