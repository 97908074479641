import { wsService } from "./wsService";
import sections from "@/constants/sections";

const section = sections.SECTION_HISTORIAL_STOCKS;

function filter(param = { enable: true }) {
  return wsService.make_post_request(section + "/list", param);
}

function actions(param = {}) {
  return wsService.make_post_request(section + "/actions", param);
}

function stock_types(param = {}) {
  return wsService.make_post_request(section + "/stock_types", param);
}

export const historialStockService = {
  section,
  filter,
  actions,
  stock_types,
};
