import { wsService } from "./wsService";
import sections from "@/constants/sections";

const section = sections.SECTION_CHARGES;

function filter(param = { enable: true }) {
  return wsService.make_post_request(section + "/list", param);
}

function create(params) {
  return wsService.make_post_request(section + "/create", params);
}

function update(params) {
  return wsService.make_post_request(section + "/update", params);
}

function assignVoucher(params) {
  return wsService.make_post_request(section + "/assign-voucher", params);
}

function remove(param = {}) {
  return wsService.make_delete_request(section + "/delete", param);
}

function get(id) {  
  return wsService.make_get_request(section + "/get/" + id);
}

function print(id) {
  return wsService.make_post_request(
    section + "/print/" + id,
    {},
    {
      responseType: "arraybuffer",
      headers: {
        Accept: "application/pdf",
      },
    }
  );
}

export const chargeService = {
  assignVoucher,
  filter,
  print,
  create,
  update,
  remove,
  get
};
