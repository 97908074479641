import { wsService } from "./wsService";
import sections from "@/constants/sections";


function list_doc_types() {  
  return wsService.make_get_request(sections.SECTION_LIST_DOC_TYPES);
}

function list_iva_conditions() {  
  return wsService.make_get_request(sections.SECTION_LIST_IVA_CONDITIONS);
}

function list_iva_conditions_emitter() {  
  return wsService.make_get_request(sections.SECTION_LIST_IVA_CONDITIONS_EMITTER);
}

function list_states() {  
  return wsService.make_get_request(sections.SECTION_LIST_STATES);
}

function cbteTiposInvoice(emitter_id, voucher_types = 'all' ) {
  return wsService.make_post_request(sections.SECTION_CBTE_TIPOS, {emitter: emitter_id, voucher_types: voucher_types});
}

export const billingGeneralService = { 
  list_iva_conditions,
  list_iva_conditions_emitter,
  list_doc_types,
  list_states,
  cbteTiposInvoice
};