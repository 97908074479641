import { vouchersService } from "@/libs/ws/vouchersService";

const getTotalFor = (concept, item) => {
  switch (concept) {
    case "imp_op_ex": {
      //Importe exento de IVA
      if (item.iva_id == vouchersService.IVA_E) return parseFloat(item.imp_neto);
      break;
    }
    case "imp_neto": {
      //Importe neto gravado
      if (![vouchersService.IVA_E, vouchersService.IVA_NG].includes(item.iva_id)) return parseFloat(item.imp_neto);
      break;
    }
    case "imp_tot_conc": {
      //Importe neto no gravado
      if (item.iva_id == vouchersService.IVA_NG) return parseFloat(item.imp_neto);
      break;
    }
  }
  return 0;
};

export const billingUtils = {
  getTotalFor,
};
