<template>
  <div>
    <Title :pages="[{ icon: 'cogs', page: page_title.toUpperCase() }]"></Title>

    <v-container fluid>
      <v-row v-if="list_access">
        <v-col cols="4" md="4" sm="12">
          <v-card class="mx-auto" tile>
            <v-list>
              <v-subheader class="mt-3" style="font-size: 16px">Configuración</v-subheader>
              <v-divider></v-divider>
              <v-list-item-group v-model="selectedItem" color="primary" mandatory>
                <v-list-item v-for="(item, i) in items" :key="i">
                  <v-list-item-icon>
                    <font-awesome-icon :icon="item.icon" />
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.text"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list> </v-card
        ></v-col>
        <v-col cols="8" md="8" sm="12">
          <v-card class="mx-auto" tile>
            <MiEmpresa v-if="selectedItem == 0" :mainItem="mainItem" @createItem="createItem" :loading="loading_submit" />
            <Facturacion v-if="selectedItem == 1" :mainItem="mainItem" @createItem="createItem" :loading="loading_submit" />
            <Email v-if="selectedItem == 2" :mainItem="mainItem" @createItem="createItem" :loading="loading_submit" :service="service" />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import GenericMixin from "@/mixins/GenericMixin.vue";
import SettingsMixin from "@/mixins/SettingsMixin.vue";
import { settingsService } from "@/libs/ws/settingsService";
import Title from "@/components/Title.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import MiEmpresa from "./MiEmpresa.vue";
import Facturacion from "./Facturacion.vue";
import Email from "./Email.vue";
import { NonFullScreenPageMode } from "pdf-lib";
export default {
  components: { Title, MiEmpresa, Facturacion ,Email},
  mixins: [GenericMixin, RolMixin, SettingsMixin],
  data() {
    return {
      page_title: "Configuración",
      selectedItem: 0,
      service: settingsService,
      items: [
        { text: "Mi empresa", icon: "user" },
        { text: "Facturacíon", icon: "cash-register" },
        { text: "Email", icon: "envelope" },
      ],
      mainItem: {
        id: 0,
        logo_url: null,
        image_upload: null,
        business_name: "",
        email_sender: "",
        email_sender_name: "",
        preload_last_voucher: true,
        business_type: 1,
        email_host: "",
        email_port: "",
        email_username: "",
        email_password: "",
        email_encryption: null,
      },
      defaultItem: null,
    };
  },
  mounted() {
    this.defaultItem = JSON.parse(JSON.stringify(this.mainItem));
    this.loadList(this.service);
  },
  methods: {
    loadList(service, param = null) {
      this.loading = true;

      if (param == null) {
        param = this.getFilterParams();
      }

      this.open_filter = false;
      service
        .filter(param)
        .then((response) => {
          this.mainItem = response.result;
          localStorage.setItem("settings", JSON.stringify(response.result));

          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
        });
    },
    createItem(e) {
      this.$validator.validate().then((valid) => {
        if (valid) {
          e.preventDefault();

          let formData = new FormData();
          if (this.mainItem.image_upload != null) formData.append("logo", this.mainItem.image_upload);

          formData.append("id", this.mainItem.id);
          formData.append("business_name", this.mainItem.business_name);
          formData.append("email_sender", this.mainItem.email_sender);
          formData.append("business_type", this.mainItem.business_type);
          formData.append("email_sender_name", this.mainItem.email_sender_name);
          if(this.mainItem.email_host != null)
            formData.append("email_host", this.mainItem.email_host);
          if(this.mainItem.email_port != null)
            formData.append("email_port", this.mainItem.email_port);
          if(this.mainItem.email_username != null)
            formData.append("email_username", this.mainItem.email_username);
          if(this.mainItem.email_password != null)
            formData.append("email_password", this.mainItem.email_password);
          if(this.mainItem.email_password != null)
            formData.append("email_encryption", this.mainItem.email_encryption.name);

          formData.append("preload_last_voucher", this.mainItem.preload_last_voucher ? 1 : 0);

          this.loading_submit = true;

          this.service
            .update(formData)
            .then((response) => {
              if (response.status == true) {
                this.$toast.success("La configuración se guardo correctamente.", "OK");
                this.updateSettings(true);
                this.$emit("loadList");
              } else {
                if (Object.prototype.hasOwnProperty.call(response, "message")) {
                  this.$toast.error(response.message, "Error");
                } else {
                  this.$toast.error(response.msg, "Error");
                }
              }
              this.loading_submit = false;
            })
            .catch((response) => {
              this.$toast.error(response.msg, "Error");
              this.loading_submit = false;
            });
        } else {
          let msg = "";
          this.errors.items.forEach((e) => {
            msg += e.msg + "\n";
          });
          this.$toast.error(msg, "Error");
        }
      });
    },
  },
};
</script>
