import { wsService } from "./wsService";
import sections from "@/constants/sections";

const section = sections.SECTION_SPENTS;

function filter(param = { enable: true }) {
  return wsService.make_post_request(section + "/list", param);
}

function create(params) {
  return wsService.make_post_request(section + "/create", params);
}

function update(params) {
  return wsService.make_post_request(section + "/update", params);
}

function remove(param = {}) {
  return wsService.make_delete_request(section + "/delete", param);
}

function get(id) {  
  return wsService.make_get_request(section + "/get/" + id);
}

function cbteTiposInvoice(iva_condition, voucher_types) {
  return wsService.make_post_request(section + "/cbte_tipos", { iva_condition: iva_condition, voucher_types: voucher_types });
}

function getPendingSpents(params) {
  return wsService.make_post_request(section + "/get_pending_spents", params);
}

function getSpentAsoc(supplier, voucher_number, cbte_tipo, receiver) {
  return wsService.make_post_request(section + "/get_voucher_asoc", { supplier: supplier, voucher_number: voucher_number, cbte_tipo: cbte_tipo, receiver: receiver });
}

function export_filter(params) {
  return wsService.make_post_request(section + "/export", params, {
    responseType: "arraybuffer",
    headers: {
      Accept: "application/vnd.ms-excel",
    },
  });
}

function export_libro_iva_filter(params) {
  return wsService.make_post_request(section + "/export_libro_iva", params, {
    responseType: "arraybuffer",
    headers: {
      Accept: "application/vnd.ms-excel",
    },
  });
}

function getMonedas() {
  return [
    { Id: "PES", Desc: "ARS" },
    { Id: "USD", Desc: "USD" },
    { Id: "EUR", Desc: "EUR" },
  ];
}

function ivas() {
  return [
    { Id: null, Desc: "N/A" },
    { Id: 1, Desc: "No gravado" },
    { Id: 2, Desc: "Excento" },
    { Id: 3, Desc: "0%" },
    { Id: 9, Desc: "2.5%" },
    { Id: 8, Desc: "5%" },
    { Id: 4, Desc: "10.5%" },
    { Id: 5, Desc: "21%" },
    { Id: 6, Desc: "27%" },
  ];
}

function tributes() {
  return [
    { Id: 1, Desc: "Impuestos nacionales" },
    { Id: 2, Desc: "Impuestos provinciales" },
    { Id: 3, Desc: "Impuestos municipales" },
    { Id: 4, Desc: "Impuestos internos" },
    { Id: 99, Desc: "Impuestos otros" },
    { Id: 5, Desc: "Impuestos IIBB" },
    { Id: 6, Desc: "Percepcion IVA" },
    { Id: 7, Desc: "Percepcion IIBB" },
    { Id: 10, Desc: "Percepcion Ganancias" },
    { Id: 8, Desc: "Percepcion Impuestos municipales" },
    { Id: 9, Desc: "Percepcion otras" },
    { Id: 13, Desc: "Percepcion IVA No categorizado" },
  ];
}

export const spentService = {
  filter,

  create,
  update,
  remove,
  getPendingSpents,
  getSpentAsoc,
  get,
  getMonedas,
  cbteTiposInvoice,
  ivas,
  tributes,
  export_filter,
  export_libro_iva_filter,
};
