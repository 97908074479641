<template>
  <v-col md="6">
    <v-card elevation="1" class="mb-4">
      <v-card-text style="min-height: 238px">
        <h6 class="text-h6 black--text">Total</h6>
        <p class="text-body-2">Detalle de totales que componen el pago</p>

        <v-row>
          <v-col md="12" class="mt-10">
            <v-chip class="float-right" outlined color="primary">Total: {{ formatUtils.formatPrice(total) }} </v-chip>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-col>
</template>
<script>
import { formatUtils } from "@/libs/utils/formatUtils";
export default {
  components: {},
  props: { total: Number },
  data: () => ({
    formatUtils: formatUtils,
  }),
  watch: {},
  mounted() {},
  methods: {},
};
</script>
