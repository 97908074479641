import { wsService } from "./wsService";

import sections from "@/constants/sections";

const section = sections.SECTION_SALESMAN;

const TYPE_SALES = 1;
const TYPE_CHARGE = 2;

function filter(param = { enable: true }) {
  return wsService.make_post_request(section + "/list", param);
}

function create(param = {}) {
  return wsService.make_post_request(section + "/create", param);
}

function update(param = {}) {
  return wsService.make_put_request(section + "/update", param);
}

function remove(param = {}) {
  return wsService.make_delete_request(section + "/delete", param);
}

function getArrTypes(param = {}) {
  return wsService.make_get_request(section + "/types", param);
}

export const salesmanService = {
  section,
  filter,
  remove,
  create,
  update,
  getArrTypes,
  TYPE_SALES,
  TYPE_CHARGE,
};
