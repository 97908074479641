<template>
  <v-card elevation="1" class="mb-4">
    <v-card-text>
      <h6 class="text-h6 black--text">Observaciones</h6>
      <p class="text-body-2">{{ subtitle }}</p>

      <v-row class="mt-0">
        <v-col md="12">
          <v-textarea outlined autocomplete="off" data-vv-as="observaciones" label="Observacion" name="observation" persistent-hint v-model="item.observation" :disabled="isViewModal" />
          <span class="red--text">{{ errors.first("observation") }}</span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  components: {},
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    item: Object,
    isViewModal: {
      type: Boolean,
      default: false,
    },
    minHeight: {
      type: String,
      default: "332px",
    },
    subtitle: {
      type: String,
      default: "Ingrese una observación que podrá visualizarse en el remito"
    }
  },
  data: () => ({}),
  watch: {
    dialog(val) {
      if (val) {
        this.loadComboData();
      }
    },
  },
  mounted() {},
  methods: {},
};
</script>
