import { wsService } from "./wsService";
import sections from "@/constants/sections";

const section = sections.SECTION_REPORTS;

function pendingVoucherClient(param = { enable: true }) {
  return wsService.make_post_request(section + "/client/pending-vouchers", param);
}

function pendingVoucherSupplier(param = { enable: true }) {
  return wsService.make_post_request(section + "/supplier/pending-vouchers", param);
}

function getSalesmanCommission(param = { enable: true }) {
  return wsService.make_post_request(section + "/sales/salesman-commissions", param);
}

function getRetentions(param = { enable: true }) {
  return wsService.make_post_request(section + "/retentions", param);
}

function getPerceptions(param = { enable: true }) {
  return wsService.make_post_request(section + "/perceptions", param);
}

export const reportService = {
  pendingVoucherClient,
  pendingVoucherSupplier,
  getSalesmanCommission,
  getRetentions,
  getPerceptions,
};
